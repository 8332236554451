import React from 'react';
import { abbreviateNumber } from '../../libs/utils-ts';
import { StatData } from '../../types';

type StatsWidgetProps = {
  stats: StatData[];
};

export const StatsWidget = ({ stats }: StatsWidgetProps) => {
  return (
    <div className="c-stats-widget">
      {stats.map((stat) => (
        <div id={stat.title} key={stat.title}>
          <h2 className="c-stats-widget__title">{stat.title}</h2>
          <div className="l-grid-row-report">
            <span className="c-stats-widget__stat">
              {abbreviateNumber(stat.stat)}
            </span>
            <div>
              <section className="c-stats-widget__section">
                <label className="c-stats-widget__label">
                  {stat.timeFrame}
                </label>
                {stat.details.map((detail) => (
                  <div
                    className="c-stats-widget__content"
                    id={detail.label}
                    key={detail.label}
                  >
                    <span className="c-stats-widget__key">{detail.label}</span>
                    <span className="c-stats-widget__value">
                      {detail.value}
                    </span>
                  </div>
                ))}
              </section>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
