import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { Header } from '../components/Header';
import { Loading } from '../components/Loading';
import MyReactTable from '../components/ReactTable';
import { TextOnly, Text } from '../components/Text';
import { getToolsResponse, getTools } from '../libs/db-lib';
import { Tool } from '../types';

export interface ToolTableView {
  toolManufacturerName: string;
  toolManufacturerUUID: string;
  toolModelName: string;
  toolModelUUID: string;
}

export const ViewTools = () => {
  const [search, setSearch] = useState<string>('');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const getToolList = (data: getToolsResponse) => {
    const toolList = data?.toolList?.map((tool: Tool) => {
      return {
        toolManufacturerName: tool.toolManufacturerName,
        toolManufacturerUUID: tool.toolManufacturerUUID,
        toolModelName: tool.toolModelName,
        toolModelUUID: tool.toolModelUUID,
      };
    });
    return toolList;
  };

  // #region 1 queries
  const { data: toolsData, isLoading: isLoadingTools } = useQuery(
    'getTools',
    getTools
  );

  const tools = getToolList(toolsData || { toolList: [] });

  // #endregion 1

  // #region 3 columns
  const columns = [
    {
      Header: 'Tool Manufacturer',
      accessor: 'toolManufacturerName',
    },
    {
      Header: 'Tool Manufacturer UUID',
      accessor: 'toolManufacturerUUID',
    },
    {
      Header: 'Tool Model',
      accessor: 'toolModelName',
    },
    {
      Header: 'Tool Model UUID',
      accessor: 'toolModelUUID',
    },
  ];

  // #endregion 3

  if (isLoadingTools) {
    return <Loading />;
  }

  return (
    <>
      <Header title={TextOnly('viewTools')} />
      <div className="l-container">
        <div className="l-flex-wrap">
          <div className="l-flex-wrap"></div>
          <div className="l-flex-between">
            <div className="c-field u-margin-bottom-none">
              <label htmlFor="search" className="c-field__label u-is-vishidden">
                <Text tid="search" />
              </label>
              <input
                type="text"
                id="search"
                maxLength={50}
                className="c-input"
                placeholder={TextOnly('search')}
                value={search}
                onChange={handleChange}
              />
              <i className="c-field__input-icon fal fa-search" />
            </div>
          </div>
        </div>

        <div className="u-margin-top-large">
          <MyReactTable
            columns={columns}
            data={tools}
            globalFilter={search}
            tableName="viewTools"
          />
        </div>
      </div>
    </>
  );
};
