export default {
  MAX_ATTACHMENT_SIZE: 5000000,
  apiGateway: {
    REGION: "us-west-2",
    URL: "https://295nkt5dl7.execute-api.us-west-2.amazonaws.com/dev"
  },
  cognito: {
    REGION: "us-west-2",
    USER_POOL_ID: "us-west-2_PH11SXAAN",
    APP_CLIENT_ID: "7htoip158ma77eei2ofeb1oulk",
    IDENTITY_POOL_ID: "us-west-2:6286ad49-1120-4afa-9bd5-04f8a8dd7dab"
  }
};
