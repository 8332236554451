import { Dialog } from '@reach/dialog';
import moment from 'moment';
import { useFormik } from 'formik';
import React from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import DatePicker from 'react-datepicker';
import MaskedInput from 'react-text-mask';
import * as Yup from 'yup';
import LoaderButton from '../../components/LoaderButton';
import { TextOnly, Text, LangDictKey } from '../../components/Text';
import { AgreementFormik, Vendor } from '../../types';
import { useMutation } from 'react-query';
import {
  CreateAgreementStateParams,
  siteAdminCreateAgreement,
  adminUploadDocumentUrl,
  UploadAgreementDocumentUrlParams,
} from '../../libs/db-lib';
import { toast } from 'react-toastify';
import { DOC_TYPE, REGIONS, REGIONS_LIST } from '../../CONSTANTS';
import { UploadDoc } from '../../components/UploadDoc/UploadDoc';
import { useConfirm } from '../../context/Confirm';

interface AddAgreementSidebarProps {
  isOpen: boolean;
  toggleDialog: () => void;
  oemVendors: Vendor[];
  toolVendors: Vendor[];
  finishMutation: () => void;
}

export const AddAgreementSidebar = ({
  isOpen,
  toggleDialog,
  oemVendors,
  toolVendors,
  finishMutation,
}: AddAgreementSidebarProps) => {
  const { handleSetConfirmProps } = useConfirm();

  const dateRegexPattern = [
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    '/',
    /\d/,
    /\d/,
    '/',
    /\d/,
    /\d/,
  ];

  const year = new Date().getFullYear();
  const yearsList = Array.from(new Array(year - 2019 + 2), (v, i) =>
    (2019 + i).toString()
  );

  const siteAdminCreateAgreementMutation = useMutation(
    (agreementFields: CreateAgreementStateParams) =>
      siteAdminCreateAgreement(agreementFields),
    {
      onSuccess: (data: any) => {
        if (data?.error) {
          toast.error(data.error, {
            autoClose: false,
            containerId: 'standard',
          });
          return;
        }
        toast.success(TextOnly('createAgreementSuccess'), {
          containerId: 'standard',
        });
        formik.resetForm();
      },
      onError: (error: Error) => {
        toast.error(error.message, {
          autoClose: false,
          containerId: 'standard',
        });
      },
    }
  );

  const siteAdminUploadDocumentMutation = useMutation(
    (documentFields: UploadAgreementDocumentUrlParams) =>
      adminUploadDocumentUrl(documentFields),
    {
      onSuccess: (data: any) => {
        if (data?.error) {
          console.log('upload error', data?.error);
          toast.error(data.error, {
            autoClose: false,
            containerId: 'standard',
          });
          return;
        }
      },
      onError: (error: Error) => {
        console.log('upload error', error);
        toast.error(error.message, {
          autoClose: false,
          containerId: 'standard',
        });
      },
    }
  );

  const createAgreementValidation = Yup.object().shape({
    agreementName: Yup.string(),
    oemVendorID: Yup.string().required(
      TextOnly('requiredField', { field: TextOnly('oemVendor') })
    ),
    toolVendorID: Yup.string().required(
      TextOnly('requiredField', { field: TextOnly('toolVendor') })
    ),
    agreementRegion: Yup.string().required(
      TextOnly('requiredField', { field: TextOnly('region') })
    ),
    agreementNotes: Yup.string(),
    annualFees: Yup.object().shape({
      isApplicable: Yup.boolean(),
      feeHistory: Yup.array().of(
        Yup.object().shape({
          invoiceDate: Yup.string(),
          receivedDate: Yup.string(),
          paidDate: Yup.string(),
          paidToOem: Yup.string(),
          modelYear: Yup.array().of(Yup.string()),
          document: Yup.object().shape({
            documentData: Yup.string(),
            documentName: Yup.string(),
            documentLastUpdated: Yup.string(),
          }),
        })
      ),
    }),
    licenseAgreement: Yup.object().shape({
      receivedDate: Yup.string(),
      signedDate: Yup.string(),
      sentDate: Yup.string(),
      document: Yup.object().shape({
        documentData: Yup.string(),
        documentName: Yup.string(),
        documentLastUpdated: Yup.string(),
      }),
    }),
    comprehensiveFees: Yup.object().shape({
      isApplicable: Yup.boolean(),
      invoiceDate: Yup.string(),
      paidDate: Yup.string(),
      paidToOem: Yup.string(),
      receivedDate: Yup.string(),
      document: Yup.object().shape({
        documentData: Yup.string(),
        documentName: Yup.string(),
        documentLastUpdated: Yup.string(),
      }),
    }),
    otherFees: Yup.object().shape({
      isApplicable: Yup.boolean(),
      invoiceDate: Yup.string(),
      paidDate: Yup.string(),
      paidToOem: Yup.string(),
      receivedDate: Yup.string(),
      document: Yup.object().shape({
        documentData: Yup.string(),
        documentName: Yup.string(),
        documentLastUpdated: Yup.string(),
      }),
    }),
    insuranceDocs: Yup.object().shape({
      isApplicable: Yup.boolean(),
      receivedDate: Yup.string(),
      expirationDate: Yup.string(),
      effectiveDate: Yup.string(),
      document: Yup.object().shape({
        documentData: Yup.string(),
        documentName: Yup.string(),
        documentLastUpdated: Yup.string(),
      }),
    }),
    supportDocs: Yup.object().shape({
      isApplicable: Yup.boolean(),
      receivedDate: Yup.string(),
      expirationDate: Yup.string(),
      document: Yup.object().shape({
        documentData: Yup.string(),
        documentName: Yup.string(),
        documentLastUpdated: Yup.string(),
      }),
    }),
  });

  const initialValues: AgreementFormik = {
    agreementName: '',
    oemVendorID: '',
    toolVendorID: '',
    agreementRegion: '',
    agreementNotes: '',
    annualFees: {
      isApplicable: false,
      feeHistory: [
        {
          invoiceDate: 'none',
          modelYear: [],
          receivedDate: 'none',
          paidDate: 'none',
          paidToOem: 'none',
          document: undefined,
          adDocExists: false,
          adDocName: '',
        },
      ],
    },
    licenseAgreement: {
      receivedDate: 'none',
      signedDate: 'none',
      sentDate: 'none',
      document: undefined,
      ldDocExists: false,
      ldDocName: '',
    },
    comprehensiveFees: {
      isApplicable: false,
      invoiceDate: 'none',
      paidDate: 'none',
      paidToOem: 'none',
      receivedDate: 'none',
      document: undefined,
      cdDocExists: false,
      cdDocName: '',
    },
    otherFees: {
      isApplicable: false,
      invoiceDate: 'none',
      paidDate: 'none',
      paidToOem: 'none',
      receivedDate: 'none',
      document: undefined,
      odDocExists: false,
      odDocName: '',
    },
    insuranceDocs: {
      isApplicable: false,
      receivedDate: 'none',
      expirationDate: 'none',
      effectiveDate: 'none',
      document: undefined,
      idDocExists: false,
      idDocName: '',
    },
    supportDocs: {
      receivedDate: 'none',
      isApplicable: false,
      expirationDate: 'none',
      document: undefined,
      sdDocExists: false,
      sdDocName: '',
    },
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: createAgreementValidation,
    onSubmit: async (values) => {
      const newAgreement = await siteAdminCreateAgreementMutation.mutateAsync({
        oemVendorID: values.oemVendorID,
        toolVendorID: values.toolVendorID,
        agreementName: values.agreementName,
        agreementRegion: values.agreementRegion,
        agreementNotes: values.agreementNotes,
        annualFees: {
          isApplicable: values.annualFees.isApplicable,
          feeHistory: values.annualFees.feeHistory.map((fee) => ({
            invoiceDate:
              fee.invoiceDate && fee.invoiceDate !== 'none'
                ? moment(fee.invoiceDate).format()
                : 'none',
            modelYear:
              fee.modelYear && fee.modelYear.length > 0
                ? fee.modelYear.join(',')
                : '',
            receivedDate:
              fee.receivedDate && fee.receivedDate !== 'none'
                ? moment(fee.receivedDate).format()
                : 'none',
            paidDate:
              fee.paidDate && fee.paidDate !== 'none'
                ? moment(fee.paidDate).format()
                : 'none',
            paidToOem:
              fee.paidToOem && fee.paidToOem !== 'none'
                ? moment(fee.paidToOem).format()
                : 'none',
          })),
        },
        licenseAgreement: {
          signedDate:
            values?.licenseAgreement.signedDate &&
            values.licenseAgreement.signedDate !== 'none'
              ? moment(values.licenseAgreement.signedDate).format()
              : 'none',
          receivedDate:
            values?.licenseAgreement.receivedDate &&
            values.licenseAgreement.receivedDate !== 'none'
              ? moment(values.licenseAgreement.receivedDate).format()
              : 'none',
          sentDate:
            values?.licenseAgreement.sentDate &&
            values.licenseAgreement.sentDate !== 'none'
              ? moment(values.licenseAgreement.sentDate).format()
              : 'none',
        },
        insuranceDocs: {
          receivedDate:
            values?.insuranceDocs.receivedDate &&
            values.insuranceDocs.receivedDate !== 'none'
              ? moment(values.insuranceDocs.receivedDate).format()
              : 'none',
          isApplicable: values.insuranceDocs.isApplicable,
          expirationDate:
            values?.insuranceDocs.expirationDate &&
            values.insuranceDocs.expirationDate !== 'none'
              ? moment(values.insuranceDocs.expirationDate).format()
              : 'none',
          effectiveDate:
            values?.insuranceDocs.effectiveDate &&
            values.insuranceDocs.effectiveDate !== 'none'
              ? moment(values.insuranceDocs.effectiveDate).format()
              : 'none',
        },
        supportDocs: {
          receivedDate:
            values?.supportDocs.receivedDate &&
            values.supportDocs.receivedDate !== 'none'
              ? moment(values.supportDocs.receivedDate).format()
              : 'none',
          isApplicable: values.supportDocs.isApplicable,
          expirationDate:
            values?.supportDocs.expirationDate &&
            values.supportDocs.expirationDate !== 'none'
              ? moment(values.supportDocs.expirationDate).format()
              : 'none',
        },
        comprehensiveFees: {
          receivedDate:
            values?.comprehensiveFees.receivedDate &&
            values.comprehensiveFees.receivedDate !== 'none'
              ? moment(values.comprehensiveFees.receivedDate).format()
              : 'none',
          isApplicable: values.comprehensiveFees.isApplicable,
          invoiceDate:
            values?.comprehensiveFees.invoiceDate &&
            values.comprehensiveFees.invoiceDate !== 'none'
              ? moment(values.comprehensiveFees.invoiceDate).format()
              : 'none',
          paidDate:
            values?.comprehensiveFees.paidDate &&
            values.comprehensiveFees.paidDate !== 'none'
              ? moment(values.comprehensiveFees.paidDate).format()
              : 'none',
          paidToOem:
            values?.comprehensiveFees.paidToOem &&
            values.comprehensiveFees.paidToOem !== 'none'
              ? moment(values.comprehensiveFees.paidToOem).format()
              : 'none',
        },
        otherFees: {
          receivedDate:
            values?.otherFees.receivedDate &&
            values.otherFees.receivedDate !== 'none'
              ? moment(values.otherFees.receivedDate).format()
              : 'none',
          isApplicable: values.otherFees.isApplicable,
          invoiceDate:
            values?.otherFees.invoiceDate &&
            values.otherFees.invoiceDate !== 'none'
              ? moment(values.otherFees.invoiceDate).format()
              : 'none',
          paidDate:
            values?.otherFees.paidDate && values.otherFees.paidDate !== 'none'
              ? moment(values.otherFees.paidDate).format()
              : 'none',
          paidToOem:
            values?.otherFees.paidToOem && values.otherFees.paidToOem !== 'none'
              ? moment(values.otherFees.paidToOem).format()
              : 'none',
        },
      });

      if (values.licenseAgreement.document?.file) {
        await siteAdminUploadDocumentMutation.mutateAsync({
          roleType: 'SITE_ADMIN',
          agreementID: newAgreement.agreementID,
          documentType: DOC_TYPE.LD,
          documentName: values.licenseAgreement.document.documentName,
          file: values.licenseAgreement.document.file,
        });
      }

      if (values.comprehensiveFees.document?.file) {
        await siteAdminUploadDocumentMutation.mutateAsync({
          roleType: 'SITE_ADMIN',
          agreementID: newAgreement.agreementID,
          documentType: DOC_TYPE.CD,
          documentName: values.comprehensiveFees.document.documentName,
          file: values.comprehensiveFees.document.file,
        });
      }

      if (values.otherFees.document?.file) {
        await siteAdminUploadDocumentMutation.mutateAsync({
          roleType: 'SITE_ADMIN',
          agreementID: newAgreement.agreementID,
          documentType: DOC_TYPE.OD,
          documentName: values.otherFees.document.documentName,
          file: values.otherFees.document.file,
        });
      }

      if (values.insuranceDocs.document?.file) {
        await siteAdminUploadDocumentMutation.mutateAsync({
          roleType: 'SITE_ADMIN',
          agreementID: newAgreement.agreementID,
          documentType: DOC_TYPE.ID,
          documentName: values.insuranceDocs.document.documentName,
          file: values.insuranceDocs.document.file,
        });
      }

      if (values.supportDocs.document?.file) {
        await siteAdminUploadDocumentMutation.mutateAsync({
          roleType: 'SITE_ADMIN',
          agreementID: newAgreement.agreementID,
          documentType: DOC_TYPE.SD,
          documentName: values.supportDocs.document.documentName,
          file: values.supportDocs.document.file,
        });
      }

      if (values.annualFees.feeHistory?.length) {
        values.annualFees.feeHistory.forEach(async (entry) => {
          if (entry.document && entry.document?.file) {
            await siteAdminUploadDocumentMutation.mutateAsync({
              roleType: 'SITE_ADMIN',
              agreementID: newAgreement.agreementID,
              documentType: DOC_TYPE.AD,
              documentName: entry.document.documentName,
              file: entry.document.file,
              modelYear: entry.modelYear.join(','),
            });
          }
        });
      }

      finishMutation();
    },
  });

  const confirmDeleteDoc = (deleteProps: { formikField: string }) => {
    handleSetConfirmProps({
      title: TextOnly('confirmDeleteDoc'),
      message: TextOnly('confirmDeleteDocMessage'),
      handleConfirm: () => {
        formik.setFieldValue(deleteProps.formikField, undefined);
      },
    });
  };

  return (
    <Dialog
      isOpen={isOpen}
      onDismiss={toggleDialog}
      className="c-modal-slider c-modal-slider--wide"
      aria-label={TextOnly('addAgreement')}
    >
      <button
        className="c-btn-icon c-modal-slider__close"
        onClick={toggleDialog}
      >
        <div className="c-btn__inner">
          <i className="c-btn__icon fa fa-times" />
        </div>
      </button>
      <h1 className="c-modal__heading">
        <Text tid="addAgreement" />
      </h1>

      <div className="c-modal__body">
        <form onSubmit={formik.handleSubmit}>
          <div className="c-agreement-box">
            <div className="c-agreement-box__grid-layout-header">
              <div className="c-agreement-box__select no-padding">
                <p className="c-agreement-box__title no-top-margin">
                  <Text tid="oemMfr" />
                </p>
                <Typeahead
                  id="oemVendorFilter"
                  className="c-agreement-box__typeahead"
                  placeholder="Select OEM..."
                  selected={
                    formik.values.oemVendorID
                      ? [
                          {
                            label:
                              oemVendors?.find(
                                (oem) =>
                                  oem.vendorID === formik.values.oemVendorID
                              )?.vendorName || '',
                            value: formik.values.oemVendorID,
                          },
                        ]
                      : []
                  }
                  options={oemVendors?.map((oem) => ({
                    label: oem.vendorName,
                    value: oem.vendorID,
                  }))}
                  onChange={(selected) => {
                    if (selected.length > 0) {
                      formik.setFieldValue('oemVendorID', selected?.[0]?.value);
                    }
                  }}
                  positionFixed
                />
              </div>
              <div className="c-agreement-box__select no-padding">
                <p className="c-agreement-box__title no-top-margin">
                  <Text tid="toolMfr" />
                </p>
                <Typeahead
                  id="toolVendorFilter"
                  className="c-agreement-box__typeahead"
                  placeholder="Select tool..."
                  selected={
                    formik.values.toolVendorID
                      ? [
                          {
                            label:
                              toolVendors?.find(
                                (tool) =>
                                  tool.vendorID === formik.values.toolVendorID
                              )?.vendorName || '',
                            value: formik.values.toolVendorID,
                          },
                        ]
                      : []
                  }
                  options={toolVendors?.map((tool) => ({
                    label: tool.vendorName,
                    value: tool.vendorID,
                  }))}
                  onChange={(selected) => {
                    if (selected.length > 0) {
                      formik.setFieldValue(
                        'toolVendorID',
                        selected?.[0]?.value
                      );
                    }
                  }}
                  positionFixed
                />
              </div>
              <div className="c-agreement-box__select no-padding">
                <p className="c-agreement-box__title no-top-margin">
                  <Text tid="region" />
                </p>
                <Typeahead
                  id="regionFilter"
                  className="c-agreement-box__typeahead"
                  placeholder="Select region..."
                  options={REGIONS_LIST.map((region) => ({
                    label: TextOnly(region as LangDictKey),
                    value: region,
                  }))}
                  selected={
                    formik.values.agreementRegion
                      ? [
                          {
                            label: TextOnly(
                              formik.values.agreementRegion as REGIONS
                            ),
                            value: formik.values.agreementRegion,
                          },
                        ]
                      : []
                  }
                  onChange={(selected) => {
                    if (selected.length > 0) {
                      formik.setFieldValue(
                        'agreementRegion',
                        selected?.[0].value
                      );
                    }
                  }}
                  positionFixed
                />
              </div>
              <div />
              <div>
                <p className="c-agreement-box__title no-top-margin">
                  <Text tid="created" />
                </p>
                <p>{moment(new Date()).format('YYYY-MM-DD')}</p>
              </div>
            </div>
          </div>

          {/* LICENSE AGREEMENT */}

          <div className="c-agreement-box">
            <div className="c-agreement-box__grid-layout-license">
              <div className="c-agreement-box__align-top">
                <p className="c-agreement-box__title">
                  <Text tid="licenseAgreement" />
                </p>
              </div>
              <div className="c-agreement-box__date">
                <label htmlFor="active">
                  <Text tid="received" />
                </label>
                <div className="c-choose-date">
                  <DatePicker
                    placeholderText="choose date..."
                    className="c-date-picker"
                    dateFormat="yyyy/MM/dd"
                    selected={
                      formik.values.licenseAgreement.receivedDate !== 'none'
                        ? new Date(formik.values.licenseAgreement.receivedDate)
                        : null
                    }
                    customInput={
                      <MaskedInput
                        mask={dateRegexPattern}
                        keepCharPositions={true}
                        guide={true}
                      />
                    }
                    onChange={(date) => {
                      formik.setFieldValue(
                        'licenseAgreement.receivedDate',
                        date === null ? 'none' : date
                      );
                    }}
                  />
                </div>
              </div>
              <div className="c-agreement-box__date">
                <label htmlFor="active">
                  <Text tid="sent" />
                </label>
                <div className="c-choose-date">
                  <DatePicker
                    placeholderText="choose date..."
                    className="c-date-picker"
                    dateFormat="yyyy/MM/dd"
                    selected={
                      formik.values.licenseAgreement.sentDate !== 'none'
                        ? new Date(formik.values.licenseAgreement.sentDate)
                        : null
                    }
                    customInput={
                      <MaskedInput
                        mask={dateRegexPattern}
                        keepCharPositions={true}
                        guide={true}
                      />
                    }
                    onChange={(date) => {
                      formik.setFieldValue(
                        'licenseAgreement.sentDate',
                        date === null ? 'none' : date
                      );
                    }}
                  />
                </div>
              </div>
              <div className="c-agreement-box__date">
                <label htmlFor="active">
                  <Text tid="signed" />
                </label>

                <div className="c-choose-date">
                  <DatePicker
                    placeholderText="choose date..."
                    className="c-date-picker"
                    dateFormat="yyyy/MM/dd"
                    selected={
                      formik.values.licenseAgreement.signedDate !== 'none'
                        ? new Date(formik.values.licenseAgreement.signedDate)
                        : null
                    }
                    customInput={
                      <MaskedInput
                        mask={dateRegexPattern}
                        keepCharPositions={true}
                        guide={true}
                      />
                    }
                    onChange={(date) => {
                      formik.setFieldValue(
                        'licenseAgreement.signedDate',
                        date === null ? 'none' : date
                      );
                    }}
                  />
                </div>
              </div>
              <div className="c-agreement-box__align-bottom l-flex-between c-agreement-box__doc-box">
                {formik.values.licenseAgreement.document ? (
                  <>
                    <span className="c-btn-link-text c-agreement-box__doc-link">
                      {formik.values.licenseAgreement.document.documentName}
                    </span>
                    <button
                      className="c-btn-icon"
                      type="button"
                      onClick={() =>
                        confirmDeleteDoc({
                          formikField: 'licenseAgreement.document',
                        })
                      }
                    >
                      <i className="fal fa-trash-alt c-agreement-box__delete-btn"></i>
                    </button>
                  </>
                ) : (
                  <UploadDoc
                    handleSetFile={({
                      documentName,
                      file,
                    }: {
                      documentName: string;
                      file: any;
                    }) => {
                      formik.setFieldValue('licenseAgreement.document', {
                        documentName,
                        file,
                      });
                    }}
                  />
                )}
              </div>
            </div>
            <div className="c-agreement-box__textbox-grid-layout">
              <textarea
                className="c-textarea"
                placeholder="Enter text here..."
                name="agreementNotes"
                rows={3}
                value={formik.values.agreementNotes}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>
          </div>

          {/* COMP FEES */}

          <div className="c-agreement-box">
            <div className="c-agreement-box__grid-layout">
              <div className="c-agreement-box__align-top">
                <p className="c-agreement-box__title">
                  <Text tid="compFees" />
                </p>
                <div className="l-flex-start">
                  <input
                    type="checkbox"
                    className="c-agreement-box__applicable-checkbox"
                    name="comprehensiveFees.isApplicable"
                    value={formik.values.comprehensiveFees.isApplicable.toString()}
                    checked={formik.values.comprehensiveFees.isApplicable}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <p className="c-agreement-box__applicable-text">
                    <Text tid="applicable" />
                  </p>
                </div>
              </div>

              <div className="c-agreement-box__date">
                <label
                  htmlFor="active"
                  className={`${
                    !formik.values.comprehensiveFees.isApplicable
                      ? 'c-choose-date--disabled-label'
                      : ''
                  }`}
                >
                  <Text tid="invoiced" />
                </label>
                <div className="c-choose-date">
                  <DatePicker
                    placeholderText="choose date..."
                    className="c-date-picker"
                    dateFormat="yyyy/MM/dd"
                    disabled={!formik.values.comprehensiveFees.isApplicable}
                    selected={
                      formik.values.comprehensiveFees.invoiceDate !== 'none'
                        ? new Date(formik.values.comprehensiveFees.invoiceDate)
                        : null
                    }
                    customInput={
                      <MaskedInput
                        mask={dateRegexPattern}
                        keepCharPositions={true}
                        guide={true}
                      />
                    }
                    onChange={(date) => {
                      formik.setFieldValue(
                        'comprehensiveFees.invoiceDate',
                        date === null ? 'none' : date
                      );
                    }}
                  />
                </div>
              </div>
              <div className="c-agreement-box__date">
                <label
                  htmlFor="active"
                  className={`${
                    !formik.values.comprehensiveFees.isApplicable
                      ? 'c-choose-date--disabled-label'
                      : ''
                  }`}
                >
                  <Text tid="poRecResent" />
                </label>
                <div className="c-choose-date">
                  <DatePicker
                    placeholderText="choose date..."
                    className="c-date-picker"
                    dateFormat="yyyy/MM/dd"
                    disabled={!formik.values.comprehensiveFees.isApplicable}
                    selected={
                      formik.values.comprehensiveFees.receivedDate !== 'none'
                        ? new Date(formik.values.comprehensiveFees.receivedDate)
                        : null
                    }
                    customInput={
                      <MaskedInput
                        mask={dateRegexPattern}
                        keepCharPositions={true}
                        guide={true}
                      />
                    }
                    onChange={(date) => {
                      formik.setFieldValue(
                        'comprehensiveFees.receivedDate',
                        date === null ? 'none' : date
                      );
                    }}
                  />
                </div>
              </div>
              <div className="c-agreement-box__date">
                <label
                  htmlFor="active"
                  className={`${
                    !formik.values.comprehensiveFees.isApplicable
                      ? 'c-choose-date--disabled-label'
                      : ''
                  }`}
                >
                  <Text tid="paidPending" />
                </label>
                <div className="c-choose-date">
                  <DatePicker
                    placeholderText="choose date..."
                    className="c-date-picker"
                    dateFormat="yyyy/MM/dd"
                    disabled={!formik.values.comprehensiveFees.isApplicable}
                    selected={
                      formik.values.comprehensiveFees.paidDate !== 'none'
                        ? new Date(formik.values.comprehensiveFees.paidDate)
                        : null
                    }
                    customInput={
                      <MaskedInput
                        mask={dateRegexPattern}
                        keepCharPositions={true}
                        guide={true}
                      />
                    }
                    onChange={(date) => {
                      formik.setFieldValue(
                        'comprehensiveFees.paidDate',
                        date === null ? 'none' : date
                      );
                    }}
                  />
                </div>
              </div>
              <div className="c-agreement-box__date">
                <label
                  htmlFor="active"
                  className={`${
                    !formik.values.comprehensiveFees.isApplicable
                      ? 'c-choose-date--disabled-label'
                      : ''
                  }`}
                >
                  <Text tid="paidToOem" />
                </label>
                <div className="c-choose-date">
                  <DatePicker
                    placeholderText="choose date..."
                    className="c-date-picker"
                    dateFormat="yyyy/MM/dd"
                    disabled={!formik.values.comprehensiveFees.isApplicable}
                    selected={
                      formik.values.comprehensiveFees.paidToOem !== 'none'
                        ? new Date(formik.values.comprehensiveFees.paidToOem)
                        : null
                    }
                    customInput={
                      <MaskedInput
                        mask={dateRegexPattern}
                        keepCharPositions={true}
                        guide={true}
                      />
                    }
                    onChange={(date) => {
                      formik.setFieldValue(
                        'comprehensiveFees.paidToOem',
                        date === null ? 'none' : date
                      );
                    }}
                  />
                </div>
              </div>
              <div className="c-agreement-box__align-bottom l-flex-between c-agreement-box__doc-box">
                {formik.values.comprehensiveFees.document ? (
                  <>
                    <span className="c-btn-link-text c-agreement-box__doc-link">
                      {formik.values.comprehensiveFees.document.documentName}
                    </span>
                    <button
                      className="c-btn-icon"
                      type="button"
                      onClick={() =>
                        confirmDeleteDoc({
                          formikField: 'comprehensiveFees.document',
                        })
                      }
                    >
                      <i className="fal fa-trash-alt c-agreement-box__delete-btn"></i>
                    </button>
                  </>
                ) : (
                  <UploadDoc
                    handleSetFile={({
                      documentName,
                      file,
                    }: {
                      documentName: string;
                      file: any;
                    }) => {
                      formik.setFieldValue('comprehensiveFees.document', {
                        documentName,
                        file,
                      });
                    }}
                  />
                )}
              </div>
            </div>
          </div>

          {/* OTHER FEES */}

          <div className="c-agreement-box">
            <div className="c-agreement-box__grid-layout">
              <div className="c-agreement-box__align-top">
                <p className="c-agreement-box__title">
                  <Text tid="otherFees" />
                </p>
                <div className="l-flex-start">
                  <input
                    type="checkbox"
                    className="c-agreement-box__applicable-checkbox"
                    name="otherFees.isApplicable"
                    value={formik.values.otherFees.isApplicable.toString()}
                    checked={formik.values.otherFees.isApplicable}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <p className="c-agreement-box__applicable-text">
                    <Text tid="applicable" />
                  </p>
                </div>
              </div>

              <div className="c-agreement-box__date">
                <label
                  htmlFor="active"
                  className={`${
                    !formik.values.otherFees.isApplicable
                      ? 'c-choose-date--disabled-label'
                      : ''
                  }`}
                >
                  <Text tid="invoiced" />
                </label>
                <div className="c-choose-date">
                  <DatePicker
                    placeholderText="choose date..."
                    className="c-date-picker"
                    dateFormat="yyyy/MM/dd"
                    disabled={!formik.values.otherFees.isApplicable}
                    selected={
                      formik.values.otherFees.invoiceDate !== 'none'
                        ? new Date(formik.values.otherFees.invoiceDate)
                        : null
                    }
                    customInput={
                      <MaskedInput
                        mask={dateRegexPattern}
                        keepCharPositions={true}
                        guide={true}
                      />
                    }
                    onChange={(date) => {
                      formik.setFieldValue(
                        'otherFees.invoiceDate',
                        date === null ? 'none' : date
                      );
                    }}
                  />
                </div>
              </div>
              <div className="c-agreement-box__date">
                <label
                  htmlFor="active"
                  className={`${
                    !formik.values.otherFees.isApplicable
                      ? 'c-choose-date--disabled-label'
                      : ''
                  }`}
                >
                  <Text tid="poRecResent" />
                </label>
                <div className="c-choose-date">
                  <DatePicker
                    placeholderText="choose date..."
                    className="c-date-picker"
                    dateFormat="yyyy/MM/dd"
                    disabled={!formik.values.otherFees.isApplicable}
                    selected={
                      formik.values.otherFees.receivedDate !== 'none'
                        ? new Date(formik.values.otherFees.receivedDate)
                        : null
                    }
                    customInput={
                      <MaskedInput
                        mask={dateRegexPattern}
                        keepCharPositions={true}
                        guide={true}
                      />
                    }
                    onChange={(date) => {
                      formik.setFieldValue(
                        'otherFees.receivedDate',
                        date === null ? 'none' : date
                      );
                    }}
                  />
                </div>
              </div>
              <div className="c-agreement-box__date">
                <label
                  htmlFor="active"
                  className={`${
                    !formik.values.otherFees.isApplicable
                      ? 'c-choose-date--disabled-label'
                      : ''
                  }`}
                >
                  <Text tid="paidPending" />
                </label>
                <div className="c-choose-date">
                  <DatePicker
                    placeholderText="choose date..."
                    className="c-date-picker"
                    dateFormat="yyyy/MM/dd"
                    disabled={!formik.values.otherFees.isApplicable}
                    selected={
                      formik.values.otherFees.paidDate !== 'none'
                        ? new Date(formik.values.otherFees.paidDate)
                        : null
                    }
                    customInput={
                      <MaskedInput
                        mask={dateRegexPattern}
                        keepCharPositions={true}
                        guide={true}
                      />
                    }
                    onChange={(date) => {
                      formik.setFieldValue(
                        'otherFees.paidDate',
                        date === null ? 'none' : date
                      );
                    }}
                  />
                </div>
              </div>
              <div className="c-agreement-box__date">
                <label
                  htmlFor="active"
                  className={`${
                    !formik.values.otherFees.isApplicable
                      ? 'c-choose-date--disabled-label'
                      : ''
                  }`}
                >
                  <Text tid="paidToOem" />
                </label>
                <div className="c-choose-date">
                  <DatePicker
                    placeholderText="choose date..."
                    className="c-date-picker"
                    dateFormat="yyyy/MM/dd"
                    disabled={!formik.values.otherFees.isApplicable}
                    selected={
                      formik.values.otherFees.paidToOem !== 'none'
                        ? new Date(formik.values.otherFees.paidToOem)
                        : null
                    }
                    customInput={
                      <MaskedInput
                        mask={dateRegexPattern}
                        keepCharPositions={true}
                        guide={true}
                      />
                    }
                    onChange={(date) => {
                      formik.setFieldValue(
                        'otherFees.paidToOem',
                        date === null ? 'none' : date
                      );
                    }}
                  />
                </div>
              </div>
              <div className="c-agreement-box__align-bottom l-flex-between c-agreement-box__doc-box">
                {formik.values.otherFees.document ? (
                  <>
                    <span className="c-btn-link-text c-agreement-box__doc-link">
                      {formik.values.otherFees.document.documentName}
                    </span>
                    <button
                      className="c-btn-icon"
                      type="button"
                      onClick={() =>
                        confirmDeleteDoc({
                          formikField: 'otherFees.document',
                        })
                      }
                    >
                      <i className="fal fa-trash-alt c-agreement-box__delete-btn"></i>
                    </button>
                  </>
                ) : (
                  <UploadDoc
                    handleSetFile={({
                      documentName,
                      file,
                    }: {
                      documentName: string;
                      file: any;
                    }) => {
                      formik.setFieldValue('otherFees.document', {
                        documentName,
                        file,
                      });
                    }}
                  />
                )}
              </div>
            </div>
          </div>

          {/* SUPPORT DOCS */}

          <div className="c-agreement-box">
            <div className="c-agreement-box__grid-layout-one-date">
              <div className="c-agreement-box__align-top">
                <p className="c-agreement-box__title">
                  <Text tid="supportDocs" />
                </p>
                <div className="l-flex-start">
                  <input
                    type="checkbox"
                    className="c-agreement-box__applicable-checkbox"
                    name="supportDocs.isApplicable"
                    value={formik.values.supportDocs.isApplicable.toString()}
                    checked={formik.values.supportDocs.isApplicable}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <p className="c-agreement-box__applicable-text">
                    <Text tid="applicable" />
                  </p>
                </div>
              </div>

              <div className="c-agreement-box__date">
                <label
                  htmlFor="active"
                  className={`${
                    !formik.values.supportDocs.isApplicable
                      ? 'c-choose-date--disabled-label'
                      : ''
                  }`}
                >
                  <Text tid="received" />
                </label>
                <div className="c-choose-date">
                  <DatePicker
                    placeholderText="choose date..."
                    className="c-date-picker"
                    dateFormat="yyyy/MM/dd"
                    disabled={!formik.values.supportDocs.isApplicable}
                    selected={
                      formik.values.supportDocs.receivedDate !== 'none'
                        ? new Date(formik.values.supportDocs.receivedDate)
                        : null
                    }
                    customInput={
                      <MaskedInput
                        mask={dateRegexPattern}
                        keepCharPositions={true}
                        guide={true}
                      />
                    }
                    onChange={(date) => {
                      formik.setFieldValue(
                        'supportDocs.receivedDate',
                        date === null ? 'none' : date
                      );
                    }}
                  />
                </div>
              </div>
              <div className="c-agreement-box__align-bottom l-flex-between c-agreement-box__doc-box">
                {formik.values.supportDocs.document ? (
                  <>
                    <span className="c-btn-link-text c-agreement-box__doc-link">
                      {formik.values.supportDocs.document.documentName}
                    </span>
                    <button
                      className="c-btn-icon"
                      type="button"
                      onClick={() =>
                        confirmDeleteDoc({
                          formikField: 'supportDocs.document',
                        })
                      }
                    >
                      <i className="fal fa-trash-alt c-agreement-box__delete-btn"></i>
                    </button>
                  </>
                ) : (
                  <UploadDoc
                    handleSetFile={({
                      documentName,
                      file,
                    }: {
                      documentName: string;
                      file: any;
                    }) => {
                      formik.setFieldValue('supportDocs.document', {
                        documentName,
                        file,
                      });
                    }}
                  />
                )}
              </div>
            </div>
          </div>

          {/* INSURANCE DOCS */}

          <div className="c-agreement-box">
            <div className="c-agreement-box__grid-layout-license">
              <div className="c-agreement-box__align-top">
                <p className="c-agreement-box__title">
                  <Text tid="insurance" />
                </p>
                <div className="l-flex-start">
                  <input
                    type="checkbox"
                    className="c-agreement-box__applicable-checkbox"
                    name="insuranceDocs.isApplicable"
                    value={formik.values.insuranceDocs.isApplicable.toString()}
                    checked={formik.values.insuranceDocs.isApplicable}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <p className="c-agreement-box__applicable-text">
                    <Text tid="applicable" />
                  </p>
                </div>
              </div>

              <div className="c-agreement-box__date">
                <label
                  htmlFor="active"
                  className={`${
                    !formik.values.insuranceDocs.isApplicable
                      ? 'c-choose-date--disabled-label'
                      : ''
                  }`}
                >
                  <Text tid="received" />
                </label>
                <div className="c-choose-date">
                  <DatePicker
                    placeholderText="choose date..."
                    className="c-date-picker"
                    dateFormat="yyyy/MM/dd"
                    disabled={!formik.values.insuranceDocs.isApplicable}
                    selected={
                      formik.values.insuranceDocs.receivedDate !== 'none'
                        ? new Date(formik.values.insuranceDocs.receivedDate)
                        : null
                    }
                    customInput={
                      <MaskedInput
                        mask={dateRegexPattern}
                        keepCharPositions={true}
                        guide={true}
                      />
                    }
                    onChange={(date) => {
                      formik.setFieldValue(
                        'insuranceDocs.receivedDate',
                        date === null ? 'none' : date
                      );
                    }}
                  />
                </div>
              </div>
              <div className="c-agreement-box__date">
                <label
                  htmlFor="active"
                  className={`${
                    !formik.values.insuranceDocs.isApplicable
                      ? 'c-choose-date--disabled-label'
                      : ''
                  }`}
                >
                  <Text tid="effective" />
                </label>
                <div className="c-choose-date">
                  <DatePicker
                    placeholderText="choose date..."
                    className="c-date-picker"
                    dateFormat="yyyy/MM/dd"
                    disabled={!formik.values.insuranceDocs.isApplicable}
                    selected={
                      formik.values.insuranceDocs.effectiveDate !== 'none'
                        ? new Date(formik.values.insuranceDocs.effectiveDate)
                        : null
                    }
                    customInput={
                      <MaskedInput
                        mask={dateRegexPattern}
                        keepCharPositions={true}
                        guide={true}
                      />
                    }
                    onChange={(date) => {
                      formik.setFieldValue(
                        'insuranceDocs.effectiveDate',
                        date === null ? 'none' : date
                      );
                    }}
                  />
                </div>
              </div>
              <div className="c-agreement-box__date">
                <label
                  htmlFor="active"
                  className={`${
                    !formik.values.insuranceDocs.isApplicable
                      ? 'c-choose-date--disabled-label'
                      : ''
                  }`}
                >
                  <Text tid="expires" />
                </label>
                <div className="c-choose-date">
                  <DatePicker
                    placeholderText="choose date..."
                    className="c-date-picker"
                    dateFormat="yyyy/MM/dd"
                    disabled={!formik.values.insuranceDocs.isApplicable}
                    selected={
                      formik.values.insuranceDocs.expirationDate !== 'none'
                        ? new Date(formik.values.insuranceDocs.expirationDate)
                        : null
                    }
                    customInput={
                      <MaskedInput
                        mask={dateRegexPattern}
                        keepCharPositions={true}
                        guide={true}
                      />
                    }
                    onChange={(date) => {
                      formik.setFieldValue(
                        'insuranceDocs.expirationDate',
                        date === null ? 'none' : date
                      );
                    }}
                  />
                </div>
              </div>
              <div className="c-agreement-box__align-bottom c-agreement-box__doc-box l-flex-between">
                {formik.values.insuranceDocs.document ? (
                  <>
                    <span className="c-btn-link-text c-agreement-box__doc-link">
                      {formik.values.insuranceDocs.document.documentName}
                    </span>
                    <button
                      className="c-btn-icon"
                      type="button"
                      onClick={() =>
                        confirmDeleteDoc({
                          formikField: 'insuranceDocs.document',
                        })
                      }
                    >
                      <i className="fal fa-trash-alt c-agreement-box__delete-btn"></i>
                    </button>
                  </>
                ) : (
                  <UploadDoc
                    handleSetFile={({
                      documentName,
                      file,
                    }: {
                      documentName: string;
                      file: any;
                    }) => {
                      formik.setFieldValue('insuranceDocs.document', {
                        documentName,
                        file,
                      });
                    }}
                  />
                )}
              </div>
            </div>
          </div>

          {/* ANNUAL FEES */}

          <div className="c-agreement-box">
            <div className="c-agreement-box__grid-layout-annual">
              <label className="c-agreement-box__title">
                <Text tid="annualFees" />
              </label>

              {/* Titles Row */}

              <label
                htmlFor="year"
                className={`${
                  !formik.values.annualFees.isApplicable
                    ? 'c-choose-date--disabled-label'
                    : ''
                }`}
              >
                <Text tid="modelYear" />
              </label>
              <label
                htmlFor="active"
                className={`${
                  !formik.values.annualFees.isApplicable
                    ? 'c-choose-date--disabled-label'
                    : ''
                }`}
              >
                <Text tid="invoiced" />
              </label>
              <label
                htmlFor="active"
                className={`${
                  !formik.values.annualFees.isApplicable
                    ? 'c-choose-date--disabled-label'
                    : ''
                }`}
              >
                <Text tid="poRecResent" />
              </label>
              <label
                htmlFor="active"
                className={`${
                  !formik.values.annualFees.isApplicable
                    ? 'c-choose-date--disabled-label'
                    : ''
                }`}
              >
                <Text tid="paidPending" />
              </label>
              <label
                htmlFor="active"
                className={`${
                  !formik.values.annualFees.isApplicable
                    ? 'c-choose-date--disabled-label'
                    : ''
                }`}
              >
                <Text tid="paidToOem" />
              </label>
              <div>{/* empty div above Upload Doc */}</div>

              {formik.values.annualFees.feeHistory.map((entry, i) => {
                return (
                  <>
                    <div className="c-agreement-box__align-top c-agreement-box__title-box">
                      {i === 0 ? (
                        <div className="l-flex-start">
                          <input
                            type="checkbox"
                            className="c-agreement-box__applicable-checkbox"
                            name="annualFees.isApplicable"
                            value={formik.values.annualFees.isApplicable.toString()}
                            checked={formik.values.annualFees.isApplicable}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          <p className="c-agreement-box__applicable-text">
                            <Text tid="applicable" />
                          </p>
                        </div>
                      ) : (
                        <div className="l-flex-start"></div>
                      )}
                    </div>
                    <div className="c-agreement-box__date justify-center padding-bottom">
                      <Typeahead
                        id="modelYear"
                        options={yearsList}
                        disabled={!formik.values.annualFees.isApplicable}
                        selected={entry.modelYear}
                        onChange={(selected) => {
                          formik.setFieldValue(
                            `annualFees.feeHistory[${i}].modelYear`,
                            selected
                          );
                        }}
                        positionFixed
                        placeholder={TextOnly('selectAYear')}
                      />
                    </div>
                    <div className="c-agreement-box__date justify-center">
                      <div className="c-choose-date">
                        <DatePicker
                          placeholderText="choose date..."
                          className="c-date-picker"
                          dateFormat="yyyy/MM/dd"
                          disabled={!formik.values.annualFees.isApplicable}
                          selected={
                            entry.invoiceDate !== 'none'
                              ? new Date(entry.invoiceDate)
                              : null
                          }
                          customInput={
                            <MaskedInput
                              mask={dateRegexPattern}
                              keepCharPositions={true}
                              guide={true}
                            />
                          }
                          onChange={(date) => {
                            formik.setFieldValue(
                              `annualFees.feeHistory[${i}].invoiceDate`,
                              date === null ? 'none' : date
                            );
                          }}
                        />
                      </div>
                    </div>
                    <div className="c-agreement-box__date justify-center">
                      <div className="c-choose-date">
                        <DatePicker
                          placeholderText="choose date..."
                          className="c-date-picker"
                          dateFormat="yyyy/MM/dd"
                          disabled={!formik.values.annualFees.isApplicable}
                          selected={
                            entry.receivedDate !== 'none'
                              ? new Date(entry.receivedDate)
                              : null
                          }
                          customInput={
                            <MaskedInput
                              mask={dateRegexPattern}
                              keepCharPositions={true}
                              guide={true}
                            />
                          }
                          onChange={(date) => {
                            formik.setFieldValue(
                              `annualFees.feeHistory[${i}].receivedDate`,
                              date === null ? 'none' : date
                            );
                          }}
                        />
                      </div>
                    </div>
                    <div className="c-agreement-box__date justify-center">
                      <div className="c-choose-date">
                        <DatePicker
                          placeholderText="choose date..."
                          className="c-date-picker"
                          dateFormat="yyyy/MM/dd"
                          disabled={!formik.values.annualFees.isApplicable}
                          selected={
                            entry.paidDate !== 'none'
                              ? new Date(entry.paidDate)
                              : null
                          }
                          customInput={
                            <MaskedInput
                              mask={dateRegexPattern}
                              keepCharPositions={true}
                              guide={true}
                            />
                          }
                          onChange={(date) => {
                            formik.setFieldValue(
                              `annualFees.feeHistory[${i}].paidDate`,
                              date === null ? 'none' : date
                            );
                          }}
                        />
                      </div>
                    </div>
                    <div className="c-agreement-box__date justify-center">
                      <div className="c-choose-date">
                        <DatePicker
                          placeholderText="choose date..."
                          className="c-date-picker"
                          dateFormat="yyyy/MM/dd"
                          disabled={!formik.values.annualFees.isApplicable}
                          selected={
                            entry.paidToOem !== 'none'
                              ? new Date(entry.paidToOem)
                              : null
                          }
                          customInput={
                            <MaskedInput
                              mask={dateRegexPattern}
                              keepCharPositions={true}
                              guide={true}
                            />
                          }
                          onChange={(date) => {
                            formik.setFieldValue(
                              `annualFees.feeHistory[${i}].paidToOem`,
                              date === null ? 'none' : date
                            );
                          }}
                        />
                      </div>
                    </div>
                    <div className="l-flex-between c-agreement-box__doc-box">
                      {entry.document ? (
                        <>
                          <span className="c-btn-link-text c-agreement-box__doc-link">
                            {entry.document.documentName}
                          </span>
                          <button
                            className="c-btn-icon"
                            type="button"
                            onClick={() =>
                              confirmDeleteDoc({
                                formikField: `annualFees.feeHistory.[${i}].document`,
                              })
                            }
                          >
                            <i className="fal fa-trash-alt c-agreement-box__delete-btn"></i>
                          </button>
                        </>
                      ) : (
                        <UploadDoc
                          handleSetFile={({
                            documentName,
                            file,
                          }: {
                            documentName: string;
                            file: any;
                          }) => {
                            formik.setFieldValue(
                              `annualFees.feeHistory.[${i}].document`,
                              {
                                documentName,
                                file,
                              }
                            );
                          }}
                        />
                      )}
                    </div>
                  </>
                );
              })}
            </div>
          </div>

          <LoaderButton
            type="submit"
            id="submit-button"
            disabled={!formik.isValid}
            isLoading={
              formik.isSubmitting ||
              siteAdminCreateAgreementMutation.isLoading ||
              siteAdminUploadDocumentMutation.isLoading
            }
            text={TextOnly('add')}
            loadingText={TextOnly('adding')}
          />
        </form>
      </div>
    </Dialog>
  );
};
