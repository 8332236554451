import React, { useState } from 'react';
import Map from 'react-map-gl';
import mapboxgl from 'mapbox-gl';
import { Text, TextOnly } from '../components/Text';
import { Marker } from 'react-map-gl';
import { RegionVendor } from '../types';
import { REGION_INFO } from '../CONSTANTS';

// @ts-ignore
// prettier-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

interface AgreementsMapProps {
  closeMap: () => void;
  handleSelectVendorFromMap: (agreementID: string) => void;
  regionStats: RegionVendor[] | undefined;
  initialRegion?: {
    latitude: number;
    longitude: number;
  };
}

export const AgreementsMap = ({
  closeMap,
  handleSelectVendorFromMap,
  regionStats,
  initialRegion,
}: AgreementsMapProps) => {
  const [showMenu, setShowMenu] = useState(false);
  const [selectedRegion, setSelectedRegion] = useState<
    RegionVendor | undefined
  >(undefined);

  const toggleMenu = (region: RegionVendor) => {
    setSelectedRegion(region);
    setShowMenu(true);
  };

  return (
    <div>
      <Map
        initialViewState={{
          latitude: initialRegion?.latitude ?? 47.161,
          longitude: initialRegion?.longitude ?? -92.788,
          zoom: 2.7,
        }}
        style={{
          width: window.innerWidth - 320,
          height: window.innerHeight,
          marginLeft: '-4rem',
        }}
        mapStyle={'mapbox://styles/shdev/cl4fxmqhh000115p9bp5msrys'}
        mapboxAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
        maxZoom={5}
        minZoom={1.8}
      >
        {regionStats?.map((region) => (
          <Marker
            latitude={REGION_INFO[region.label].latitude}
            longitude={REGION_INFO[region.label].longitude}
            key={region.label}
          >
            <button
              className="c-map-marker"
              onClick={() => toggleMenu(region)}
              disabled={!region.activeCount && !region.inactiveCount}
            >
              <p className="c-map-marker__region-name">
                {TextOnly(region.label)}
              </p>
              <div className="c-map-marker__vendor">
                <div className="c-map-marker__vendor__count c-map-marker__vendor__count--active">
                  {region.activeCount}
                </div>
                <div className="c-map-marker__vendor__count c-map-marker__vendor__count--inactive">
                  {region.inactiveCount}
                </div>
              </div>
            </button>
          </Marker>
        ))}
        <div className="map-overlay">
          <div className="map-overlay__title">
            <h1>
              <Text tid="regionalUsage" />
            </h1>
          </div>
          <button
            className="map-overlay__close-button c-btn-icon"
            onClick={closeMap}
          >
            <div className="c-btn__inner">
              <i className="c-btn__icon fa fa-times" />
            </div>
          </button>

          {showMenu && selectedRegion ? (
            <div className="map-overlay__menu">
              <div className="c-map-menu">
                <p className="c-map-menu__title">
                  Tool Partners / {TextOnly(selectedRegion.label)}
                </p>
                <ul className="c-map-menu__list">
                  {selectedRegion.vendors.map((vendor) => (
                    <li key={vendor.agreementID}>
                      <span className="c-status-led">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className={`c-status-led__light c-status-led__light--${
                            vendor.isValid ? 'active' : 'error'
                          }`}
                        >
                          <circle cx="8" cy="8" r="8" />
                        </svg>
                        <button
                          className="c-map-menu__list__vendor c-btn-link-text"
                          onClick={() =>
                            handleSelectVendorFromMap(vendor.agreementID)
                          }
                        >
                          {vendor.name}
                        </button>
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ) : null}
        </div>
      </Map>
    </div>
  );
};
