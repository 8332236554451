import React, { useEffect, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import { Text } from '../Text';

interface UploadDocProps {
  handleSetFile: (file: { documentName: string; file: any }) => void;
}

export function UploadDoc(props: UploadDocProps) {
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isFocused,
    acceptedFiles,
  } = useDropzone({
    noKeyboard: true,
  });

  const style = useMemo(
    () =>
      isDragActive || isFocused ? 'c-upload-box-drag-only__is-dragging ' : '',
    [isDragActive, isFocused]
  );
  useEffect(() => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      props.handleSetFile({
        documentName: file.name,
        file: file,
      });
    }
  }, [acceptedFiles]);

  return (
    <div
      {...getRootProps({ className: `c-upload-box-drag-only__box ${style}` })}
    >
      <input {...getInputProps()} />
      <button
        type="button"
        className="c-btn-link-bare c-agreement-box--upload u-padding-left-none"
      >
        <div className="c-btn__inner">
          <i className="c-btn__icon fal fa-file-upload" />
          {!isDragActive ? (
            <Text tid="uploadDoc" />
          ) : (
            <Text tid="dragAndDrop" />
          )}
        </div>
      </button>
    </div>
  );
}
