import { Dialog } from '@reach/dialog';
import React, { useState } from 'react';
import { Header } from '../../components/Header';
import LoaderButton from '../../components/LoaderButton';
import MyReactTable from '../../components/ReactTable';
import { TableColumnsType } from '../../components/ReactTable/ReactTable';
import { TextOnly, Text } from '../../components/Text';
import { USER_STATE, USER_TYPE } from '../../CONSTANTS';
import { nameValidate, passwordValidate } from '../../libs/utils';
import { Vendor } from '../../types';
import { MANAGE_USERS_DIALOGS, UserTableView } from './ManageUsersContainer';
import { FilterPills, FiltersList } from '../../components/FilterPills';
import { Typeahead } from 'react-bootstrap-typeahead';

interface ManagerUsersProps {
  isSite: boolean;
  users: UserTableView[];
  columns: TableColumnsType[];
  userTypeOptions: USER_TYPE[];
  oemVendors: Vendor[] | undefined;
  toolVendors: Vendor[] | undefined;
  formik: any;
  addUserFormik: any;
  editUserFormik: any;
  userTypeFilter: USER_TYPE[];
  userStateFilter: USER_STATE[];
  vendorFilter: string[];
  vendors: Vendor[];
  filters: FiltersList;
  onFilterClick: (field: string, value: string) => void;
  resetFilters: () => void;
  addUserMutationIsLoading: boolean;
  editUserMutationIsLoading: boolean;
  currentDialog: MANAGE_USERS_DIALOGS | null;
  toggleFilterDialog: () => void;
  toggleAddUserDialog: () => void;
  toggleEditUserDialog: () => void;
}

export const ManageUsers = ({
  isSite,
  users,
  columns,
  userTypeOptions,
  oemVendors,
  toolVendors,
  formik,
  addUserFormik,
  editUserFormik,
  vendorFilter,
  vendors,
  userTypeFilter,
  userStateFilter,
  resetFilters,
  filters,
  onFilterClick,
  addUserMutationIsLoading,
  editUserMutationIsLoading,
  currentDialog,
  toggleFilterDialog,
  toggleAddUserDialog,
  toggleEditUserDialog,
}: ManagerUsersProps) => {
  const [search, setSearch] = useState<string>('');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  return (
    <>
      <Header title={TextOnly('manageUsers')} />
      <div className="l-container">
        <div className="l-flex-wrap">
          <div className="l-flex-wrap">
            <div className="u-margin-right">
              <button className="c-btn" onClick={toggleAddUserDialog}>
                <div className="c-btn__inner">
                  <i className="c-btn__icon fal fa-plus" />
                  <Text tid="addUsers" />
                </div>
              </button>
            </div>
          </div>
          <div className="l-flex-between">
            <button
              className="c-btn-outline u-margin-right"
              onClick={toggleFilterDialog}
            >
              <div className="c-btn__inner">
                <Text tid="filterBy" />
                <i className="c-btn__icon fas fa-caret-down" />
              </div>
            </button>
            <div className="c-field u-margin-bottom-none">
              <label htmlFor="search" className="c-field__label u-is-vishidden">
                <Text tid="search" />
              </label>
              <input
                type="text"
                id="search"
                maxLength={50}
                className="c-input"
                placeholder={TextOnly('search')}
                value={search}
                onChange={handleChange}
              />
              <i className="c-field__input-icon fal fa-search" />
            </div>
          </div>
        </div>

        {/* add filter list */}
        {Object.keys(filters).length ? (
          <div className="l-flex-wrap u-margin-top-large">
            <div className="l-flex-wrap">
              <button
                className="c-btn-link-text u-margin-right"
                onClick={resetFilters}
              >
                <div className="c-btn__inner">
                  <Text tid="resetFilters" />
                </div>
              </button>
              <FilterPills filters={filters} onFilterClick={onFilterClick} />
            </div>
          </div>
        ) : null}

        <div className="u-margin-top-large">
          <MyReactTable
            columns={columns}
            data={users}
            globalFilter={search}
            filter={
              isSite
                ? [
                    { columnId: 'userType', filter: userTypeFilter[0] },
                    { columnId: 'userState', filter: userStateFilter[0] },
                    { columnId: 'vendorName', filter: vendorFilter[0] },
                  ]
                : [
                    { columnId: 'userType', filter: userTypeFilter[0] },
                    { columnId: 'userState', filter: userStateFilter[0] },
                  ]
            }
            tableName="manageUsers"
          />
        </div>
      </div>
      <Dialog
        isOpen={currentDialog === MANAGE_USERS_DIALOGS.FILTER}
        onDismiss={toggleFilterDialog}
        className="c-modal-slider"
        aria-label={TextOnly('filters')}
      >
        <button
          className="c-btn-icon c-modal-slider__close"
          onClick={toggleFilterDialog}
        >
          <div className="c-btn__inner">
            <i className="c-btn__icon fa fa-times" />
          </div>
        </button>
        <h1 className="c-modal__heading">
          <Text tid="filters" />
        </h1>

        <div className="c-modal__body">
          <form onSubmit={formik.handleSubmit}>
            <div className="l-container-sm">
              <div className="c-field">
                <label className="c-field__label">
                  <Text tid="role" />
                </label>
                <Typeahead
                  id="userTypeFilter"
                  placeholder="Select User Type"
                  options={userTypeOptions}
                  selected={formik.values.userTypeFilter}
                  onChange={(selected) => {
                    formik.setFieldValue('userTypeFilter', selected);
                  }}
                  positionFixed
                />
              </div>
              <div className="c-field">
                <label className="c-field__label">
                  <Text tid="status" />
                </label>
                <Typeahead
                  id="userStateFilter"
                  placeholder="Select User State"
                  options={[USER_STATE.ACTIVE, USER_STATE.INACTIVE]}
                  selected={formik.values.userStateFilter}
                  onChange={(selected) => {
                    formik.setFieldValue('userStateFilter', selected);
                  }}
                  positionFixed
                />
              </div>
              {isSite ? (
                <div className="c-field">
                  <label className="c-field__label">
                    <Text tid="vendor" />
                  </label>
                  <div className="c-select">
                    <select
                      id="vendorFilter"
                      name="vendorFilter"
                      value={formik.values.vendorFilter}
                      onChange={formik.handleChange}
                    >
                      <option value="">{TextOnly('selectVendor')}</option>
                      {vendors.map((vendor) => (
                        <option
                          key={vendor.vendorName}
                          value={vendor.vendorName}
                        >
                          {vendor.vendorName}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              ) : null}
              <div className="c-field l-flex-between">
                <button
                  className="c-btn-outline u-margin-right"
                  type="button"
                  onClick={resetFilters}
                >
                  <Text tid="resetFilters" />
                </button>
                <button className="c-btn" type="submit">
                  <Text tid="applyFilters" />
                </button>
              </div>
            </div>
          </form>
        </div>
      </Dialog>
      <Dialog
        isOpen={currentDialog === MANAGE_USERS_DIALOGS.ADD_USER}
        onDismiss={toggleAddUserDialog}
        className="c-modal-slider"
        aria-label={TextOnly('addUser')}
      >
        <button
          className="c-btn-icon c-modal-slider__close"
          onClick={toggleAddUserDialog}
        >
          <div className="c-btn__inner">
            <i className="c-btn__icon fa fa-times" />
          </div>
        </button>
        <h1 className="c-modal__heading">
          <Text tid="addUser" />
        </h1>

        <div className="c-modal__body">
          <form onSubmit={addUserFormik.handleSubmit}>
            <div className="l-container-sm">
              <div className="c-field">
                <label htmlFor="userName" className="c-field__label">
                  <Text tid="userName" />:
                </label>
                <input
                  id="userName"
                  name="userName"
                  type="text"
                  maxLength={50}
                  className={`c-input ${
                    !nameValidate(addUserFormik.values.userName) ? 'error' : ''
                  }`}
                  placeholder={TextOnly('usernamePlaceholder')}
                  value={addUserFormik.values.userName}
                  onChange={addUserFormik.handleChange}
                  onBlur={addUserFormik.handleBlur}
                />
                {addUserFormik.touched.userName &&
                addUserFormik.errors.userName ? (
                  <div className="c-field__error">
                    {addUserFormik.errors.userName}
                  </div>
                ) : null}
              </div>

              <div className="c-field">
                <label htmlFor="firstName" className="c-field__label">
                  <Text tid="firstName" />:
                </label>
                <input
                  id="firstName"
                  name="firstName"
                  type="text"
                  maxLength={50}
                  className={`c-input ${
                    !nameValidate(addUserFormik.values.firstName) ? 'error' : ''
                  }`}
                  placeholder={TextOnly('firstNamePlaceholder')}
                  value={addUserFormik.values.firstName}
                  onChange={addUserFormik.handleChange}
                  onBlur={addUserFormik.handleBlur}
                />
                {addUserFormik.touched.firstName &&
                addUserFormik.errors.firstName ? (
                  <div className="c-field__error">
                    <Text tid="firstNameHint" />
                  </div>
                ) : null}
              </div>

              <div className="c-field">
                <label htmlFor="lastName" className="c-field__label">
                  <Text tid="lastName" />:
                </label>
                <input
                  id="lastName"
                  type="text"
                  maxLength={50}
                  className={`c-input ${
                    !nameValidate(addUserFormik.values.lastName) ? 'error' : ''
                  }`}
                  placeholder={TextOnly('lastNamePlaceholder')}
                  value={addUserFormik.values.lastName}
                  onChange={addUserFormik.handleChange}
                  onBlur={addUserFormik.handleBlur}
                />
                {addUserFormik.touched.lastName &&
                addUserFormik.errors.lastName ? (
                  <div className="c-field__error">
                    <Text tid="lastNameHint" />
                  </div>
                ) : null}
              </div>

              <div className="c-field">
                <label htmlFor="email" className="c-field__label">
                  <Text tid="email" />:
                </label>
                <input
                  id="email"
                  type="text"
                  maxLength={50}
                  className={`c-input ${
                    !nameValidate(addUserFormik.values.email) ? 'error' : ''
                  }`}
                  placeholder={TextOnly('emailPlaceholder')}
                  value={addUserFormik.values.email}
                  onChange={addUserFormik.handleChange}
                  onBlur={addUserFormik.handleBlur}
                />
                {addUserFormik.touched.email && addUserFormik.errors.email ? (
                  <div className="c-field__error">
                    <Text tid="emailHint" />
                  </div>
                ) : null}
              </div>

              <div className="c-field">
                <label htmlFor="role" className="c-field__label">
                  <Text tid="role" />:
                </label>
                <div className="c-select">
                  <select
                    id="userType"
                    name="userType"
                    value={addUserFormik.values.userType}
                    onChange={(e) => {
                      addUserFormik.handleChange(e);
                      if (e.target.value === USER_TYPE.SITE_ADMIN) {
                        addUserFormik.setFieldValue('vendorID', '', false);
                      } else if (addUserFormik.values.vendorID !== '') {
                        addUserFormik.setFieldValue('vendorID', '');
                      }
                    }}
                    onBlur={addUserFormik.handleBlur}
                  >
                    <option value="">{TextOnly('userTypePlaceholder')}</option>
                    {userTypeOptions.map((userType) => (
                      <option key={userType} value={userType}>
                        {TextOnly(userType)}
                      </option>
                    ))}
                  </select>
                </div>
                {addUserFormik.touched.userType &&
                addUserFormik.errors.userType ? (
                  <div className="c-field__error">
                    <Text tid="userTypeHint" />
                  </div>
                ) : null}
              </div>

              {isSite &&
                (addUserFormik.values.userType === USER_TYPE.OEM_ADMIN ||
                  addUserFormik.values.userType === USER_TYPE.OEM_USER) && (
                  <div className="c-field">
                    <label htmlFor="oemVendor" className="c-field__label">
                      <Text tid="oemVendor" />:
                    </label>
                    <div className="c-select">
                      <select
                        id={`vendorID`}
                        name={`vendorID`}
                        value={addUserFormik.values.vendorID}
                        onChange={(e) => {
                          addUserFormik.setFieldValue(
                            'vendorID',
                            e.target.value
                          );
                        }}
                        onBlur={addUserFormik.handleBlur}
                      >
                        <option value="">
                          {TextOnly('oemVendorPlaceholder')}
                        </option>
                        {oemVendors?.map((oem) => (
                          <option key={oem.vendorID} value={oem.vendorID}>
                            {oem.vendorName}
                          </option>
                        ))}
                      </select>
                    </div>
                    {addUserFormik.touched.vendorID &&
                    addUserFormik.errors.vendorID ? (
                      <div className="c-field__error">
                        <Text tid="vendorIDHint" />
                      </div>
                    ) : null}
                  </div>
                )}

              {isSite &&
                (addUserFormik.values.userType === USER_TYPE.TOOL_ADMIN ||
                  addUserFormik.values.userType === USER_TYPE.TOOL_USER) && (
                  <div className="c-field">
                    <label htmlFor="toolVendor" className="c-field__label">
                      <Text tid="toolVendor" />:
                    </label>
                    <div className="c-select">
                      <select
                        id={`vendorID`}
                        name={`vendorID`}
                        value={addUserFormik.values.vendorID}
                        onChange={(e) => {
                          addUserFormik.setFieldValue(
                            'vendorID',
                            e.target.value
                          );
                        }}
                        onBlur={addUserFormik.handleBlur}
                      >
                        <option value="">
                          {TextOnly('toolVendorPlaceholder')}
                        </option>
                        {toolVendors?.map((tool) => (
                          <option key={tool.vendorID} value={tool.vendorID}>
                            {tool.vendorName}
                          </option>
                        ))}
                      </select>
                    </div>
                    {addUserFormik.touched.vendorID &&
                    addUserFormik.errors.vendorID ? (
                      <div className="c-field__error">
                        <Text tid="vendorIDHint" />
                      </div>
                    ) : null}
                  </div>
                )}

              <div className="c-field">
                <label htmlFor="password" className="c-field__label">
                  <Text tid="password" />:
                </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  maxLength={50}
                  className={`c-input ${
                    !passwordValidate(addUserFormik.values.password)
                      ? 'error'
                      : ''
                  }`}
                  placeholder={TextOnly('password')}
                  value={addUserFormik.values.password}
                  onChange={addUserFormik.handleChange}
                  onBlur={addUserFormik.handleBlur}
                />
                {addUserFormik.touched.password &&
                addUserFormik.errors.password ? (
                  <div className="c-field__error">
                    <Text tid="passwordHint" />
                  </div>
                ) : null}
              </div>
              <div className="c-field">
                <label htmlFor="confirmPassword" className="c-field__label">
                  <Text tid="confirmPassword" />:
                </label>
                <input
                  id="confirmPassword"
                  name="confirmPassword"
                  type="password"
                  maxLength={50}
                  className={`c-input ${
                    !passwordValidate(addUserFormik.values.confirmPassword)
                      ? 'error'
                      : ''
                  }`}
                  placeholder={TextOnly('confirmPassword')}
                  value={addUserFormik.values.confirmPassword}
                  onChange={addUserFormik.handleChange}
                  onBlur={addUserFormik.handleBlur}
                />
                {addUserFormik.touched.confirmPassword &&
                addUserFormik.errors.confirmPassword ? (
                  <div className="c-field__error">
                    {addUserFormik.errors.confirmPassword}
                  </div>
                ) : null}
              </div>

              <div className="c-field l-flex-between u-margin-top-xlarge">
                <button
                  className="c-btn-outline u-margin-right"
                  type="button"
                  onClick={toggleAddUserDialog}
                >
                  {TextOnly('cancel')}
                </button>

                <LoaderButton
                  type="submit"
                  id="submit-button"
                  disabled={!addUserFormik.isValid}
                  isLoading={
                    addUserFormik.isSubmitting || addUserMutationIsLoading
                  }
                  text={TextOnly('addUser')}
                  loadingText={TextOnly('adding')}
                />
              </div>
            </div>
          </form>
        </div>
      </Dialog>
      <Dialog
        isOpen={currentDialog === MANAGE_USERS_DIALOGS.EDIT_USER}
        onDismiss={toggleEditUserDialog}
        className="c-modal-slider"
        aria-label={TextOnly('editUser')}
      >
        <button
          className="c-btn-icon c-modal-slider__close"
          onClick={toggleEditUserDialog}
        >
          <div className="c-btn__inner">
            <i className="c-btn__icon fa fa-times" />
          </div>
        </button>
        <h1 className="c-modal__heading">
          <Text tid="editUser" />
        </h1>

        <div className="c-modal__body">
          <form onSubmit={editUserFormik.handleSubmit}>
            <div className="l-container-sm">
              <div className="c-field">
                <label htmlFor="firstName" className="c-field__label">
                  <Text tid="firstName" />:
                </label>
                <input
                  id="firstName"
                  name="firstName"
                  type="text"
                  maxLength={50}
                  className={`c-input ${
                    !nameValidate(editUserFormik.values.firstName)
                      ? 'error'
                      : ''
                  }`}
                  placeholder={TextOnly('firstName')}
                  value={editUserFormik.values.firstName}
                  onChange={editUserFormik.handleChange}
                  onBlur={editUserFormik.handleBlur}
                />
                {editUserFormik.touched.firstName &&
                editUserFormik.errors.firstName ? (
                  <div className="c-field__error">
                    <Text tid="firstNameHint" />
                  </div>
                ) : null}
              </div>

              <div className="c-field">
                <label htmlFor="lastName" className="c-field__label">
                  <Text tid="lastName" />:
                </label>
                <input
                  id="lastName"
                  type="text"
                  maxLength={50}
                  className={`c-input ${
                    !nameValidate(editUserFormik.values.lastName) ? 'error' : ''
                  }`}
                  placeholder={TextOnly('lastName')}
                  value={editUserFormik.values.lastName}
                  onChange={editUserFormik.handleChange}
                  onBlur={editUserFormik.handleBlur}
                />
                {editUserFormik.touched.lastName &&
                editUserFormik.errors.lastName ? (
                  <div className="c-field__error">
                    <Text tid="lastNameHint" />
                  </div>
                ) : null}
              </div>

              <div className="c-field">
                <label htmlFor="email" className="c-field__label">
                  <Text tid="email" />:
                </label>
                <input
                  id="email"
                  type="text"
                  maxLength={50}
                  className={`c-input ${
                    !nameValidate(editUserFormik.values.email) ? 'error' : ''
                  }`}
                  placeholder={TextOnly('email')}
                  value={editUserFormik.values.email}
                  onChange={editUserFormik.handleChange}
                  onBlur={editUserFormik.handleBlur}
                />
                {editUserFormik.touched.email && editUserFormik.errors.email ? (
                  <div className="c-field__error">
                    <Text tid="emailHint" />
                  </div>
                ) : null}
              </div>
              <div className="c-field l-flex-between u-margin-top-xlarge">
                <button
                  className="c-btn-outline u-margin-right"
                  type="button"
                  onClick={toggleEditUserDialog}
                >
                  {TextOnly('cancel')}
                </button>

                <LoaderButton
                  type="submit"
                  id="submit-button"
                  disabled={!editUserFormik.isValid}
                  isLoading={
                    editUserFormik.isSubmitting || editUserMutationIsLoading
                  }
                  text={TextOnly('saveChanges')}
                  loadingText={TextOnly('updating')}
                />
              </div>
            </div>
          </form>
        </div>
      </Dialog>
    </>
  );
};
