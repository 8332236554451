import { useFormik } from 'formik';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Row } from 'react-table';
import { Loading } from '../../components/Loading';
import { TextOnly } from '../../components/Text';
import {
  AGREEMENT_SECTIONS,
  AGREEMENT_STATUS,
  FEE_STATUS,
  FEE_STATUS_LIST,
  INSURANCE_STATUS,
  OEM_FEE_STATUS_LIST,
  REGIONS,
  REGIONS_LIST,
  REGION_INFO,
  SUPPORT_STATUS,
  TOOL_FEE_STATUS_LIST,
  VENDOR_TYPE,
} from '../../CONSTANTS';
import { useMap } from '../../context/Map';
import { useUser } from '../../context/User';
import {
  getAgreements,
  GetAgreementsResponse,
  getVendors,
} from '../../libs/db-lib';
import { formatModelYears } from '../../libs/utils-ts';
import {
  Agreement,
  UpdateAgreement,
  RegionVendor,
  FeeHistory,
} from '../../types';
import { AgreementsMap } from '../../components/AgreementsMap';
import { Agreements } from './Agreements';
import { VendorAgreements } from './VendorAgreements';
import { atomWithHash, RESET } from 'jotai/utils';
import { useAtom } from 'jotai';

export interface AgreementsTableView {
  id: string;
  oemName: string;
  toolName: string;
  agreementRegion: string;
  year: string;
  annualFees: string;
  insuranceDocs: string;
  licenseAgreement: string;
  compFees: string;
  otherFees: string;
  supportDocs: string;
  crud: React.Component | JSX.Element;
}

export interface STATUS_FILTER {
  id: FEE_STATUS | INSURANCE_STATUS | SUPPORT_STATUS | AGREEMENT_STATUS;
  label: string;
}

export enum AGREEMENTS_DIALOGS {
  ADD_AGREEMENT = 'ADD_AGREEMENT',
  EDIT_AGREEMENT = 'EDIT_AGREEMENT',
  FILTER = 'FILTER',
}

const selectedAgreementIdAtom = atomWithHash('selectedAgreementId', '');

const findLatestFeeHistoryIndex = (feeHistory: FeeHistory[]) => {
  let latestFeeHistoryIndex = 0;
  let latestYear = '1999';
  feeHistory.forEach((fh, index: number) => {
    if (fh.modelYear > latestYear) {
      latestYear = fh.modelYear;
      latestFeeHistoryIndex = index;
    }
  });
  return latestFeeHistoryIndex;
};

export const AgreementsContainer = () => {
  const { isSite, isOEM, isTool, vendorID } = useUser();
  const { mapIsShown, toggleMapIsShown } = useMap();
  const today = new Date();
  const [currentDialog, setCurrentDialog] = useState<AGREEMENTS_DIALOGS | null>(
    null
  );
  const [initialRegion, setInitialRegion] = useState<
    | {
        latitude: number;
        longitude: number;
      }
    | undefined
  >(undefined);
  const [oemFilter, setOemFilter] = useState<string[]>([]);
  const [toolFilter, setToolFilter] = useState<string[]>([]);
  const [regionFilter, setRegionFilter] = useState<REGIONS[]>([]);
  const [yearFilter, setYearFilter] = useState<string[]>([]);
  const [feeStatusFilter, setFeeStatusFilter] = useState<STATUS_FILTER[]>([]);
  const [insuranceStatusFilter, setInsuranceStatusFilter] = useState<
    STATUS_FILTER[]
  >([]);
  const [supportStatusFilter, setSupportStatusFilter] = useState<
    STATUS_FILTER[]
  >([]);
  const [agreementStatusFilter, setAgreementStatusFilter] = useState<
    STATUS_FILTER[]
  >([]);

  const [selectedAgreement, setSelectedAgreement] =
    useState<UpdateAgreement | null>(null);

  const [selectedAgreementId, setSelectedAgreementId] = useAtom(
    selectedAgreementIdAtom
  );

  const feeStatusDropdown = isTool
    ? TOOL_FEE_STATUS_LIST
    : isOEM
    ? OEM_FEE_STATUS_LIST
    : FEE_STATUS_LIST;

  const isAgreementValid = (agreement: Agreement) => {
    const invalidSections: AGREEMENT_SECTIONS[] = [];
    if (
      agreement.licenseAgreement.signedDate === 'none' ||
      !agreement.licenseAgreement.signedDate
    ) {
      invalidSections.push(AGREEMENT_SECTIONS.LICENSE_AGREEMENT);
    }
    if (
      agreement.comprehensiveFees.isApplicable &&
      (agreement.comprehensiveFees.paidToOem === 'none' ||
        !agreement.comprehensiveFees.paidToOem)
    ) {
      invalidSections.push(AGREEMENT_SECTIONS.COMPREHENSIVE_FEE);
    }
    if (
      agreement.otherFees.isApplicable &&
      (agreement.otherFees.paidToOem === 'none' ||
        !agreement.otherFees.paidToOem)
    ) {
      invalidSections.push(AGREEMENT_SECTIONS.OTHER_FEE);
    }
    if (
      agreement.insuranceDocs.isApplicable &&
      (agreement.insuranceDocs.expirationDate === 'none' ||
        moment(agreement.insuranceDocs.expirationDate).isBefore(
          moment().add(1, 'day')
        ))
    ) {
      invalidSections.push(AGREEMENT_SECTIONS.INSURANCE_DOC);
    }
    if (
      agreement.annualFees.isApplicable &&
      (agreement.annualFees?.feeHistory?.[0]?.paidToOem === 'none' ||
        !agreement.annualFees?.feeHistory?.[0]?.paidToOem)
    ) {
      invalidSections.push(AGREEMENT_SECTIONS.ANNUAL_FEE);
    }
    return invalidSections;
  };

  const getAgreementsList = (data: GetAgreementsResponse) => {
    const agreementList = data?.agreementList?.map((agreement: Agreement) => {
      const invalidSections = isAgreementValid(agreement);
      const latestFeeHistoryIndex = findLatestFeeHistoryIndex(
        agreement.annualFees?.feeHistory
      );

      return {
        id: agreement.agreementID,
        agreementNotes: agreement.agreementNotes,
        oemName:
          oemVendors?.find(
            (vendor) => vendor.vendorID === agreement.oemVendorID
          )?.vendorName || '',
        toolName:
          toolVendors?.find(
            (vendor) => vendor.vendorID === agreement.toolVendorID
          )?.vendorName || '',
        agreementRegion: agreement.agreementRegion,
        year: agreement.annualFees?.feeHistory[latestFeeHistoryIndex]
          ?.modelYear,
        annualFees:
          !agreement.annualFees?.isApplicable ||
          agreement.annualFees?.feeHistory?.length === 0
            ? FEE_STATUS.NA
            : agreement.annualFees?.feeHistory[latestFeeHistoryIndex]
                ?.paidToOem !== 'none'
            ? FEE_STATUS.PAID_TO_OEM
            : agreement.annualFees?.feeHistory[latestFeeHistoryIndex]
                ?.paidDate !== 'none'
            ? FEE_STATUS.PAID_PENDING
            : agreement.annualFees?.feeHistory[latestFeeHistoryIndex]
                ?.receivedDate !== 'none'
            ? FEE_STATUS.PO_RECEIVED_AND_RESENT
            : agreement.annualFees?.feeHistory[latestFeeHistoryIndex]
                ?.invoiceDate !== 'none'
            ? FEE_STATUS.INVOICED
            : FEE_STATUS.UNPAID,
        insuranceDocs: !agreement?.insuranceDocs.isApplicable
          ? INSURANCE_STATUS.NA
          : moment(today).isAfter(agreement.insuranceDocs?.expirationDate)
          ? INSURANCE_STATUS.EXPIRED
          : moment(agreement.insuranceDocs?.expirationDate)
              .subtract(30, 'days')
              .isBefore(today)
          ? INSURANCE_STATUS.EXPIRING
          : agreement.insuranceDocs?.effectiveDate !== 'none'
          ? INSURANCE_STATUS.ACTIVE
          : agreement.insuranceDocs?.receivedDate !== 'none'
          ? INSURANCE_STATUS.RECEIVED
          : INSURANCE_STATUS.INACTIVE,
        licenseAgreement:
          agreement?.licenseAgreement.signedDate !== 'none'
            ? AGREEMENT_STATUS.SIGNED
            : agreement.licenseAgreement?.sentDate !== 'none'
            ? AGREEMENT_STATUS.SENT
            : agreement.licenseAgreement?.receivedDate !== 'none'
            ? AGREEMENT_STATUS.RECEIVED
            : AGREEMENT_STATUS.UNSIGNED,
        compFees: !agreement.comprehensiveFees?.isApplicable
          ? FEE_STATUS.NA
          : agreement.comprehensiveFees?.paidToOem !== 'none'
          ? FEE_STATUS.PAID_TO_OEM
          : agreement.comprehensiveFees?.paidDate !== 'none'
          ? FEE_STATUS.PAID_PENDING
          : agreement.comprehensiveFees?.receivedDate !== 'none'
          ? FEE_STATUS.PO_RECEIVED_AND_RESENT
          : agreement.comprehensiveFees?.invoiceDate !== 'none'
          ? FEE_STATUS.INVOICED
          : FEE_STATUS.UNPAID,
        otherFees: !agreement.otherFees?.isApplicable
          ? FEE_STATUS.NA
          : agreement.otherFees?.paidToOem !== 'none'
          ? FEE_STATUS.PAID_TO_OEM
          : agreement.otherFees?.paidDate !== 'none'
          ? FEE_STATUS.PAID_PENDING
          : agreement.otherFees?.receivedDate !== 'none'
          ? FEE_STATUS.PO_RECEIVED_AND_RESENT
          : agreement.otherFees?.invoiceDate !== 'none'
          ? FEE_STATUS.INVOICED
          : FEE_STATUS.UNPAID,
        supportDocs: !agreement.supportDocs?.isApplicable
          ? SUPPORT_STATUS.NA
          : agreement.supportDocs?.receivedDate !== 'none'
          ? SUPPORT_STATUS.COMPLETE
          : SUPPORT_STATUS.WAITING,
        crud: (
          <div className="l-flex-end l-flex-auto">
            <button
              className="c-btn-icon"
              onClick={() => {
                setSelectedAgreement({
                  ...agreement,
                  invalidSections,
                });
                setSelectedAgreementId(agreement.agreementID);
                toggleEditAgreementDialog();
              }}
            >
              <div className="c-btn__inner">
                <i className="c-btn__icon fal fa-edit" />
              </div>
            </button>
          </div>
        ),
      };
    });
    return agreementList;
  };

  const getOemAgreementsList = (data: GetAgreementsResponse) => {
    const agreementList = data?.agreementList?.map((agreement: Agreement) => {
      const invalidSections = isAgreementValid(agreement);
      const latestFeeHistoryIndex = findLatestFeeHistoryIndex(
        agreement.annualFees?.feeHistory
      );

      return {
        id: agreement.agreementID,
        agreementNotes: agreement.agreementNotes,
        oemName:
          oemVendors?.find(
            (vendor) => vendor.vendorID === agreement.oemVendorID
          )?.vendorName || '',
        toolName:
          toolVendors?.find(
            (vendor) => vendor.vendorID === agreement.toolVendorID
          )?.vendorName || '',
        agreementRegion: agreement.agreementRegion,
        year: agreement.annualFees?.feeHistory[latestFeeHistoryIndex]
          ?.modelYear,
        annualFees:
          !agreement.annualFees?.isApplicable ||
          agreement.annualFees?.feeHistory?.length === 0
            ? FEE_STATUS.NA
            : agreement.annualFees?.feeHistory[latestFeeHistoryIndex]
                ?.paidDate !== 'none' ||
              agreement.annualFees?.feeHistory[latestFeeHistoryIndex]
                ?.paidToOem !== 'none'
            ? FEE_STATUS.PAID
            : agreement.annualFees?.feeHistory[latestFeeHistoryIndex]
                ?.receivedDate !== 'none'
            ? FEE_STATUS.PO_RECEIVED_AND_RESENT
            : agreement.annualFees?.feeHistory[latestFeeHistoryIndex]
                ?.invoiceDate !== 'none'
            ? FEE_STATUS.INVOICED
            : FEE_STATUS.UNPAID,
        insuranceDocs: !agreement?.insuranceDocs.isApplicable
          ? INSURANCE_STATUS.NA
          : moment(today).isAfter(agreement.insuranceDocs?.expirationDate)
          ? INSURANCE_STATUS.EXPIRED
          : moment(agreement.insuranceDocs?.expirationDate)
              .subtract(30, 'days')
              .isBefore(today)
          ? INSURANCE_STATUS.EXPIRING
          : agreement.insuranceDocs?.effectiveDate !== 'none'
          ? INSURANCE_STATUS.ACTIVE
          : agreement.insuranceDocs?.receivedDate !== 'none'
          ? INSURANCE_STATUS.RECEIVED
          : INSURANCE_STATUS.INACTIVE,
        licenseAgreement:
          agreement?.licenseAgreement.signedDate !== 'none'
            ? AGREEMENT_STATUS.SIGNED
            : agreement.licenseAgreement?.sentDate !== 'none'
            ? AGREEMENT_STATUS.SENT
            : agreement.licenseAgreement?.receivedDate !== 'none'
            ? AGREEMENT_STATUS.RECEIVED
            : AGREEMENT_STATUS.UNSIGNED,
        compFees: !agreement.comprehensiveFees?.isApplicable
          ? FEE_STATUS.NA
          : agreement.comprehensiveFees?.paidDate !== 'none' ||
            agreement.comprehensiveFees?.paidToOem !== 'none'
          ? FEE_STATUS.PAID
          : agreement.comprehensiveFees?.receivedDate !== 'none'
          ? FEE_STATUS.PO_RECEIVED_AND_RESENT
          : agreement.comprehensiveFees?.invoiceDate !== 'none'
          ? FEE_STATUS.INVOICED
          : FEE_STATUS.UNPAID,
        otherFees: !agreement.otherFees?.isApplicable
          ? FEE_STATUS.NA
          : agreement.otherFees.paidDate !== 'none' ||
            agreement.otherFees.paidToOem !== 'none'
          ? FEE_STATUS.PAID
          : agreement.otherFees?.receivedDate !== 'none'
          ? FEE_STATUS.PO_RECEIVED_AND_RESENT
          : agreement.otherFees?.invoiceDate !== 'none'
          ? FEE_STATUS.INVOICED
          : FEE_STATUS.UNPAID,
        supportDocs: !agreement.supportDocs?.isApplicable
          ? SUPPORT_STATUS.NA
          : agreement.supportDocs?.receivedDate !== 'none'
          ? SUPPORT_STATUS.COMPLETE
          : SUPPORT_STATUS.WAITING,
        crud: (
          <div className="l-flex-end l-flex-auto">
            <button
              className="c-btn-icon"
              onClick={() => {
                setSelectedAgreement({
                  ...agreement,
                  invalidSections,
                });
                setSelectedAgreementId(agreement.agreementID);
                toggleEditAgreementDialog();
              }}
            >
              <div className="c-btn__inner">
                <i className="c-btn__icon fal fa-arrow-circle-right" />
              </div>
            </button>
          </div>
        ),
      };
    });
    return agreementList;
  };

  const getToolAgreementsList = (data: GetAgreementsResponse) => {
    const agreementList = data?.agreementList?.map((agreement: Agreement) => {
      const latestFeeHistoryIndex = findLatestFeeHistoryIndex(
        agreement.annualFees?.feeHistory
      );

      return {
        id: agreement.agreementID,
        agreementNotes: agreement.agreementNotes,
        oemName:
          oemVendors?.find(
            (vendor) => vendor.vendorID === agreement.oemVendorID
          )?.vendorName || '',
        toolName:
          toolVendors?.find(
            (vendor) => vendor.vendorID === agreement.toolVendorID
          )?.vendorName || '',
        agreementRegion: agreement.agreementRegion,
        year: agreement.annualFees?.feeHistory[latestFeeHistoryIndex]
          ?.modelYear,
        annualFees:
          !agreement.annualFees?.isApplicable ||
          agreement.annualFees?.feeHistory?.length === 0
            ? FEE_STATUS.NA
            : agreement.annualFees?.feeHistory[latestFeeHistoryIndex]
                ?.paidDate !== 'none' ||
              agreement.annualFees?.feeHistory[latestFeeHistoryIndex]
                ?.paidToOem !== 'none'
            ? FEE_STATUS.PAID
            : agreement.annualFees?.feeHistory[latestFeeHistoryIndex]
                ?.receivedDate !== 'none'
            ? FEE_STATUS.PO_RECEIVED_AND_RESENT
            : agreement.annualFees?.feeHistory[latestFeeHistoryIndex]
                ?.invoiceDate !== 'none'
            ? FEE_STATUS.INVOICED
            : FEE_STATUS.UNPAID,
        insuranceDocs: !agreement?.insuranceDocs.isApplicable
          ? INSURANCE_STATUS.NA
          : moment(today).isAfter(agreement.insuranceDocs?.expirationDate)
          ? INSURANCE_STATUS.EXPIRED
          : moment(agreement.insuranceDocs?.expirationDate)
              .subtract(30, 'days')
              .isBefore(today)
          ? INSURANCE_STATUS.EXPIRING
          : agreement.insuranceDocs?.effectiveDate !== 'none'
          ? INSURANCE_STATUS.ACTIVE
          : agreement.insuranceDocs?.receivedDate !== 'none'
          ? INSURANCE_STATUS.RECEIVED
          : INSURANCE_STATUS.INACTIVE,
        licenseAgreement:
          agreement?.licenseAgreement.signedDate !== 'none'
            ? AGREEMENT_STATUS.SIGNED
            : agreement.licenseAgreement?.sentDate !== 'none'
            ? AGREEMENT_STATUS.SENT
            : agreement.licenseAgreement?.receivedDate !== 'none'
            ? AGREEMENT_STATUS.RECEIVED
            : AGREEMENT_STATUS.UNSIGNED,
        compFees: !agreement.comprehensiveFees?.isApplicable
          ? FEE_STATUS.NA
          : agreement.comprehensiveFees?.paidDate !== 'none' ||
            agreement.comprehensiveFees?.paidToOem !== 'none'
          ? FEE_STATUS.PAID
          : agreement.comprehensiveFees?.receivedDate !== 'none'
          ? FEE_STATUS.PO_RECEIVED_AND_RESENT
          : agreement.comprehensiveFees?.invoiceDate !== 'none'
          ? FEE_STATUS.INVOICED
          : FEE_STATUS.UNPAID,
        otherFees: !agreement.otherFees?.isApplicable
          ? FEE_STATUS.NA
          : agreement.otherFees.paidDate !== 'none' ||
            agreement.otherFees.paidToOem !== 'none'
          ? FEE_STATUS.PAID
          : agreement.otherFees?.receivedDate !== 'none'
          ? FEE_STATUS.PO_RECEIVED_AND_RESENT
          : agreement.otherFees?.invoiceDate !== 'none'
          ? FEE_STATUS.INVOICED
          : FEE_STATUS.UNPAID,
        supportDocs: !agreement.supportDocs?.isApplicable
          ? SUPPORT_STATUS.NA
          : agreement.supportDocs?.receivedDate !== 'none'
          ? SUPPORT_STATUS.COMPLETE
          : SUPPORT_STATUS.WAITING,

        crud: (
          <div className="l-flex-end l-flex-auto">
            <button
              className="c-btn-icon"
              onClick={() => {
                setSelectedAgreement(agreement);
                setSelectedAgreementId(agreement.agreementID);
                toggleEditAgreementDialog();
              }}
            >
              <div className="c-btn__inner">
                <i className="c-btn__icon fal fa-arrow-circle-right" />
              </div>
            </button>
          </div>
        ),
      };
    });
    return agreementList;
  };

  // #region 1 queries
  const { data: vendors, isLoading: isLoadingVendors } = useQuery(
    'getVendors',
    getVendors
  );

  const oemVendors = vendors?.vendorList?.filter(
    (vendor) => vendor.vendorType === VENDOR_TYPE.OEM
  );
  const toolVendors = vendors?.vendorList?.filter(
    (vendor) => vendor.vendorType === VENDOR_TYPE.TOOL
  );

  const {
    data: agreementsData,
    isLoading: isLoadingAgreements,
    refetch: refetchAgreements,
  } = useQuery(
    'getAgreements',
    () =>
      getAgreements({
        oemVendorID: isOEM ? vendorID : undefined,
        toolVendorID: isTool ? vendorID : undefined,
      }),
    {
      enabled: !isLoadingVendors,
    }
  );

  const agreements = isTool
    ? getToolAgreementsList(agreementsData || { agreementList: [] })
    : isOEM
    ? getOemAgreementsList(agreementsData || { agreementList: [] })
    : getAgreementsList(agreementsData || { agreementList: [] });

  // #endregion 1

  // #region 3 columns

  function multiFilter(
    rows: Array<Row>,
    columnId: string,
    filterValue: string
  ): Row[] {
    return rows.filter((row) => {
      return filterValue
        .toLowerCase()
        .includes(row.values[columnId].toLowerCase());
    });
  }

  function multiStatusFilter(
    rows: Array<Row>,
    columnId: string,
    filterValue: string
  ): Row[] {
    const filterValueArray = filterValue.split(',');
    return rows.filter((row) => {
      return filterValueArray.includes(row.values[columnId]);
    });
  }

  function multiYearFilter(
    rows: Array<Row>,
    columnId: string,
    filterValue: string
  ): Row[] {
    const filterValueArray = filterValue.split(',');

    return rows.filter((row) => {
      return filterValueArray.some(
        (r) => row.values[columnId].split(',').indexOf(r) >= 0
      );
    });
  }

  const siteAdminColumns = [
    {
      Header: 'OEM Partner',
      accessor: 'oemName',
      filter: multiFilter,
      minWidth: 110,
    },
    {
      Header: 'Tool Partner',
      accessor: 'toolName',
      filter: multiFilter,
      minWidth: 120,
    },
    {
      Header: 'Region',
      accessor: 'agreementRegion',
      filter: multiFilter,
      minWidth: 100,
      Cell: ({ row: { original } }: any) => (
        <>{TextOnly(original.agreementRegion)}</>
      ),
    },
    {
      Header: 'Year',
      accessor: 'year',
      filter: multiYearFilter,
      minWidth: 90,
      Cell: ({ row: { original } }: any) => (
        <>{formatModelYears(original.year)}</>
      ),
    },
    {
      Header: 'Annual Fees',
      accessor: 'annualFees',
      filter: multiStatusFilter,
      minWidth: 110,
      Cell: ({ row: { original } }: any) =>
        original.annualFees === FEE_STATUS.NA ? (
          <span
            className="c-status-label c-status-label--notapplicable"
            title={TextOnly('NA')}
          >
            {TextOnly(FEE_STATUS.NA)}
          </span>
        ) : original.annualFees === FEE_STATUS.PAID_PENDING ? (
          <span
            className="c-status-label c-status-label--pending"
            title={TextOnly('PAID_PENDING')}
          >
            {TextOnly(FEE_STATUS.PAID_PENDING)}
          </span>
        ) : original.annualFees === FEE_STATUS.PAID_TO_OEM ? (
          <span
            className="c-status-label c-status-label--valid"
            title={TextOnly('PAID_TO_OEM')}
          >
            {TextOnly(FEE_STATUS.PAID_TO_OEM)}
          </span>
        ) : original.annualFees === FEE_STATUS.PO_RECEIVED_AND_RESENT ? (
          <span
            className="c-status-label c-status-label--pending"
            title={TextOnly('PO_RECEIVED_AND_RESENT')}
          >
            {TextOnly(FEE_STATUS.PO_RECEIVED_AND_RESENT)}
          </span>
        ) : original.annualFees === FEE_STATUS.INVOICED ? (
          <span
            className="c-status-label c-status-label--pending"
            title={TextOnly('INVOICED')}
          >
            {TextOnly(FEE_STATUS.INVOICED)}
          </span>
        ) : (
          <span
            className="c-status-label c-status-label--invalid"
            title={TextOnly('UNPAID')}
          >
            {TextOnly(FEE_STATUS.UNPAID)}
          </span>
        ),
    },
    {
      Header: 'Insurance',
      accessor: 'insuranceDocs',
      filter: multiStatusFilter,
      minWidth: 90,
      width: 90,
      Cell: ({ row: { original } }: any) =>
        original.insuranceDocs === INSURANCE_STATUS.NA ? (
          <span
            className="c-status-label-mini c-status-label-mini--notapplicable"
            title={TextOnly('NA')}
          >
            {TextOnly(INSURANCE_STATUS.NA)}
          </span>
        ) : original.insuranceDocs === INSURANCE_STATUS.EXPIRED ? (
          <span
            className="c-status-label-mini c-status-label-mini--expired"
            title={TextOnly('EXPIRED')}
          >
            {TextOnly(INSURANCE_STATUS.EXPIRED)}
          </span>
        ) : original.insuranceDocs === INSURANCE_STATUS.EXPIRING ? (
          <span
            className="c-status-label-mini c-status-label-mini--expiring"
            title={TextOnly('EXPIRING')}
          >
            {TextOnly(INSURANCE_STATUS.EXPIRING)}
          </span>
        ) : original.insuranceDocs === INSURANCE_STATUS.ACTIVE ? (
          <span
            className="c-status-label-mini c-status-label-mini--valid"
            title={TextOnly('ACTIVE')}
          >
            {TextOnly(INSURANCE_STATUS.ACTIVE)}
          </span>
        ) : original.insuranceDocs === INSURANCE_STATUS.RECEIVED ? (
          <span
            className="c-status-label-mini c-status-label-mini--pending"
            title={TextOnly('RECEIVED')}
          >
            {TextOnly(INSURANCE_STATUS.RECEIVED)}
          </span>
        ) : (
          <span
            className="c-status-label-mini c-status-label-mini--invalid"
            title={TextOnly('UNPAID')}
          >
            {TextOnly(INSURANCE_STATUS.INACTIVE)}
          </span>
        ),
    },
    {
      Header: 'Lic. Agreement',
      accessor: 'licenseAgreement',
      filter: multiStatusFilter,
      minWidth: 115,
      width: 115,
      Cell: ({ row: { original } }: any) => (
        <div className="l-flex-align-center">
          {original.licenseAgreement === AGREEMENT_STATUS.SIGNED ? (
            <span
              className="c-status-label-mini c-status-label-mini--valid"
              title={TextOnly('SIGNED')}
            >
              {TextOnly(AGREEMENT_STATUS.SIGNED)}
            </span>
          ) : original.licenseAgreement === AGREEMENT_STATUS.SENT ? (
            <span
              className="c-status-label-mini c-status-label-mini--pending"
              title={TextOnly('SENT')}
            >
              {TextOnly(AGREEMENT_STATUS.SENT)}
            </span>
          ) : original.licenseAgreement === AGREEMENT_STATUS.RECEIVED ? (
            <span
              className="c-status-label-mini c-status-label-mini--pending"
              title={TextOnly('RECEIVED')}
            >
              {TextOnly(AGREEMENT_STATUS.RECEIVED)}
            </span>
          ) : (
            <span
              className="c-status-label-mini c-status-label-mini--invalid"
              title={TextOnly('UNSIGNED')}
            >
              {TextOnly(AGREEMENT_STATUS.UNSIGNED)}
            </span>
          )}
          {original.agreementNotes !== '' ? (
            <button
              className="c-btn-icon c-btn-icon--tooltip"
              title={original.agreementNotes}
            >
              <div className="c-btn__inner">
                <i className="c-btn__icon fal fa-sticky-note" />
              </div>
            </button>
          ) : null}
        </div>
      ),
    },
    {
      Header: 'Comp. Fees',
      accessor: 'compFees',
      filter: multiStatusFilter,
      minWidth: 110,
      Cell: ({ row: { original } }: any) =>
        original.compFees === FEE_STATUS.NA ? (
          <span
            className="c-status-label c-status-label--notapplicable"
            title={TextOnly('NA')}
          >
            {TextOnly(FEE_STATUS.NA)}
          </span>
        ) : original.compFees === FEE_STATUS.PAID_PENDING ? (
          <span
            className="c-status-label c-status-label--pending"
            title={TextOnly('PAID_PENDING')}
          >
            {TextOnly(FEE_STATUS.PAID_PENDING)}
          </span>
        ) : original.compFees === FEE_STATUS.PAID_TO_OEM ? (
          <span
            className="c-status-label c-status-label--valid"
            title={TextOnly('PAID_TO_OEM')}
          >
            {TextOnly(FEE_STATUS.PAID_TO_OEM)}
          </span>
        ) : original.compFees === FEE_STATUS.PO_RECEIVED_AND_RESENT ? (
          <span
            className="c-status-label c-status-label--pending"
            title={TextOnly('PO_RECEIVED_AND_RESENT')}
          >
            {TextOnly(FEE_STATUS.PO_RECEIVED_AND_RESENT)}
          </span>
        ) : original.compFees === FEE_STATUS.INVOICED ? (
          <span
            className="c-status-label c-status-label--pending"
            title={TextOnly('INVOICED')}
          >
            {TextOnly(FEE_STATUS.INVOICED)}
          </span>
        ) : (
          <span
            className="c-status-label c-status-label--invalid"
            title={TextOnly('UNPAID')}
          >
            {TextOnly(FEE_STATUS.UNPAID)}
          </span>
        ),
    },
    {
      Header: 'Other Fees',
      accessor: 'otherFees',
      filter: multiStatusFilter,
      minWidth: 110,
      Cell: ({ row: { original } }: any) =>
        original.otherFees === FEE_STATUS.NA ? (
          <span
            className="c-status-label c-status-label--notapplicable"
            title={TextOnly('NA')}
          >
            {TextOnly(FEE_STATUS.NA)}
          </span>
        ) : original.otherFees === FEE_STATUS.PAID_TO_OEM ? (
          <span
            className="c-status-label c-status-label--valid"
            title={TextOnly('PAID_TO_OEM')}
          >
            {TextOnly(FEE_STATUS.PAID_TO_OEM)}
          </span>
        ) : original.otherFees === FEE_STATUS.PAID_PENDING ? (
          <span
            className="c-status-label c-status-label--pending"
            title={TextOnly('PAID_PENDING')}
          >
            {TextOnly(FEE_STATUS.PAID_PENDING)}
          </span>
        ) : original.otherFees === FEE_STATUS.PO_RECEIVED_AND_RESENT ? (
          <span
            className="c-status-label c-status-label--pending"
            title={TextOnly('PO_RECEIVED_AND_RESENT')}
          >
            {TextOnly(FEE_STATUS.PO_RECEIVED_AND_RESENT)}
          </span>
        ) : original.otherFees === FEE_STATUS.INVOICED ? (
          <span
            className="c-status-label c-status-label--pending"
            title={TextOnly('INVOICED')}
          >
            {TextOnly(FEE_STATUS.INVOICED)}
          </span>
        ) : (
          <span
            className="c-status-label c-status-label--invalid"
            title={TextOnly('UNPAID')}
          >
            {TextOnly(FEE_STATUS.UNPAID)}
          </span>
        ),
    },
    {
      Header: 'Support',
      accessor: 'supportDocs',
      filter: multiStatusFilter,
      minWidth: 85,
      Cell: ({ row: { original } }: any) =>
        original.supportDocs === SUPPORT_STATUS.NA ? (
          <span
            className="c-status-label-mini c-status-label-mini--notapplicable"
            title={TextOnly('NA')}
          >
            {TextOnly(SUPPORT_STATUS.NA)}
          </span>
        ) : original.supportDocs === SUPPORT_STATUS.COMPLETE ? (
          <span
            className="c-status-label-mini c-status-label-mini--valid"
            title={TextOnly('ACTIVE')}
          >
            {TextOnly(SUPPORT_STATUS.COMPLETE)}
          </span>
        ) : (
          <span
            className="c-status-label-mini c-status-label-mini--invalid"
            title={TextOnly('UNPAID')}
          >
            {TextOnly(SUPPORT_STATUS.WAITING)}
          </span>
        ),
    },
    {
      Header: '',
      accessor: 'crud',
      maxWidth: 50,
      disableSortBy: true,
    },
  ];

  const oemAdminColumns = [
    {
      Header: 'Tool Partner',
      accessor: 'toolName',
      filter: multiFilter,
      minWidth: 140,
    },
    {
      Header: 'Region',
      accessor: 'agreementRegion',
      filter: multiFilter,
      minWidth: 140,
      Cell: ({ row: { original } }: any) => (
        <button
          className="c-btn-link-text"
          onClick={() => openMap(original.agreementRegion)}
        >
          {TextOnly(original.agreementRegion)}
        </button>
      ),
    },
    {
      Header: 'Year',
      accessor: 'year',
      filter: multiYearFilter,
      minWidth: 110,
      Cell: ({ row: { original } }: any) => (
        <>{formatModelYears(original.year)}</>
      ),
    },
    {
      Header: 'Annual Fees',
      accessor: 'annualFees',
      filter: multiFilter,
      minWidth: 110,
      Cell: ({ row: { original } }: any) =>
        original.annualFees === FEE_STATUS.NA ? (
          <span
            className="c-status-label c-status-label--notapplicable"
            title={TextOnly('NA')}
          >
            {TextOnly(FEE_STATUS.NA)}
          </span>
        ) : original.annualFees === FEE_STATUS.PAID_PENDING ? (
          <span
            className="c-status-label c-status-label--pending"
            title={TextOnly('PAID_PENDING')}
          >
            {TextOnly(FEE_STATUS.PAID_PENDING)}
          </span>
        ) : original.annualFees === FEE_STATUS.PAID ? (
          <span
            className="c-status-label c-status-label--valid"
            title={TextOnly('PAID')}
          >
            {TextOnly(FEE_STATUS.PAID)}
          </span>
        ) : original.annualFees === FEE_STATUS.PO_RECEIVED_AND_RESENT ? (
          <span
            className="c-status-label c-status-label--pending"
            title={TextOnly('PO_RECEIVED_AND_RESENT')}
          >
            {TextOnly(FEE_STATUS.PO_RECEIVED_AND_RESENT)}
          </span>
        ) : original.annualFees === FEE_STATUS.INVOICED ? (
          <span
            className="c-status-label c-status-label--pending"
            title={TextOnly('INVOICED')}
          >
            {TextOnly(FEE_STATUS.INVOICED)}
          </span>
        ) : (
          <span
            className="c-status-label c-status-label--invalid"
            title={TextOnly('UNPAID')}
          >
            {TextOnly(FEE_STATUS.UNPAID)}
          </span>
        ),
    },
    {
      Header: 'Insurance',
      accessor: 'insuranceDocs',
      filter: multiFilter,
      minWidth: 90,
      width: 90,
      Cell: ({ row: { original } }: any) =>
        original.insuranceDocs === INSURANCE_STATUS.NA ? (
          <span
            className="c-status-label-mini c-status-label-mini--notapplicable"
            title={TextOnly('NA')}
          >
            {TextOnly(INSURANCE_STATUS.NA)}
          </span>
        ) : original.insuranceDocs === INSURANCE_STATUS.EXPIRED ? (
          <span
            className="c-status-label-mini c-status-label-mini--expired"
            title={TextOnly('EXPIRED')}
          >
            {TextOnly(INSURANCE_STATUS.EXPIRED)}
          </span>
        ) : original.insuranceDocs === INSURANCE_STATUS.EXPIRING ? (
          <span
            className="c-status-label-mini c-status-label-mini--expiring"
            title={TextOnly('EXPIRING')}
          >
            {TextOnly(INSURANCE_STATUS.EXPIRING)}
          </span>
        ) : original.insuranceDocs === INSURANCE_STATUS.ACTIVE ? (
          <span
            className="c-status-label-mini c-status-label-mini--valid"
            title={TextOnly('ACTIVE')}
          >
            {TextOnly(INSURANCE_STATUS.ACTIVE)}
          </span>
        ) : original.insuranceDocs === INSURANCE_STATUS.RECEIVED ? (
          <span
            className="c-status-label-mini c-status-label-mini--pending"
            title={TextOnly('RECEIVED')}
          >
            {TextOnly(INSURANCE_STATUS.RECEIVED)}
          </span>
        ) : (
          <span
            className="c-status-label-mini c-status-label-mini--invalid"
            title={TextOnly('UNPAID')}
          >
            {TextOnly(INSURANCE_STATUS.INACTIVE)}
          </span>
        ),
    },
    {
      Header: 'Lic. Agreement',
      accessor: 'licenseAgreement',
      minWidth: 115,
      width: 115,
      filter: multiFilter,
      Cell: ({ row: { original } }: any) => (
        <div className="l-flex-align-center">
          {original.licenseAgreement === AGREEMENT_STATUS.SIGNED ? (
            <span
              className="c-status-label-mini c-status-label-mini--valid"
              title={TextOnly('SIGNED')}
            >
              {TextOnly(AGREEMENT_STATUS.SIGNED)}
            </span>
          ) : original.licenseAgreement === AGREEMENT_STATUS.SENT ? (
            <span
              className="c-status-label-mini c-status-label-mini--pending"
              title={TextOnly('SENT')}
            >
              {TextOnly(AGREEMENT_STATUS.SENT)}
            </span>
          ) : original.licenseAgreement === AGREEMENT_STATUS.RECEIVED ? (
            <span
              className="c-status-label-mini c-status-label-mini--pending"
              title={TextOnly('RECEIVED')}
            >
              {TextOnly(AGREEMENT_STATUS.RECEIVED)}
            </span>
          ) : (
            <span
              className="c-status-label-mini c-status-label-mini--invalid"
              title={TextOnly('UNSIGNED')}
            >
              {TextOnly(AGREEMENT_STATUS.UNSIGNED)}
            </span>
          )}
          {original.agreementNotes !== '' ? (
            <button
              className="c-btn-icon c-btn-icon--tooltip"
              title={original.agreementNotes}
            >
              <div className="c-btn__inner">
                <i className="c-btn__icon fal fa-sticky-note" />
              </div>
            </button>
          ) : null}
        </div>
      ),
    },
    {
      Header: 'Comp. Fees',
      accessor: 'compFees',
      filter: multiFilter,
      minWidth: 110,
      Cell: ({ row: { original } }: any) =>
        original.compFees === FEE_STATUS.NA ? (
          <span
            className="c-status-label c-status-label--notapplicable"
            title={TextOnly('NA')}
          >
            {TextOnly(FEE_STATUS.NA)}
          </span>
        ) : original.compFees === FEE_STATUS.PAID_PENDING ? (
          <span
            className="c-status-label c-status-label--pending"
            title={TextOnly('PAID_PENDING')}
          >
            {TextOnly(FEE_STATUS.PAID_PENDING)}
          </span>
        ) : original.compFees === FEE_STATUS.PAID ? (
          <span
            className="c-status-label c-status-label--valid"
            title={TextOnly('PAID')}
          >
            {TextOnly(FEE_STATUS.PAID)}
          </span>
        ) : original.compFees === FEE_STATUS.PO_RECEIVED_AND_RESENT ? (
          <span
            className="c-status-label c-status-label--pending"
            title={TextOnly('PO_RECEIVED_AND_RESENT')}
          >
            {TextOnly(FEE_STATUS.PO_RECEIVED_AND_RESENT)}
          </span>
        ) : original.compFees === FEE_STATUS.INVOICED ? (
          <span
            className="c-status-label c-status-label--pending"
            title={TextOnly('INVOICED')}
          >
            {TextOnly(FEE_STATUS.INVOICED)}
          </span>
        ) : (
          <span
            className="c-status-label c-status-label--invalid"
            title={TextOnly('UNPAID')}
          >
            {TextOnly(FEE_STATUS.UNPAID)}
          </span>
        ),
    },
    {
      Header: 'Other Fees',
      accessor: 'otherFees',
      filter: multiFilter,
      minWidth: 110,
      Cell: ({ row: { original } }: any) =>
        original.otherFees === FEE_STATUS.NA ? (
          <span
            className="c-status-label c-status-label--notapplicable"
            title={TextOnly('NA')}
          >
            {TextOnly(FEE_STATUS.NA)}
          </span>
        ) : original.otherFees === FEE_STATUS.PAID ? (
          <span
            className="c-status-label c-status-label--valid"
            title={TextOnly('PAID')}
          >
            {TextOnly(FEE_STATUS.PAID)}
          </span>
        ) : original.otherFees === FEE_STATUS.PAID_PENDING ? (
          <span
            className="c-status-label c-status-label--pending"
            title={TextOnly('PAID_PENDING')}
          >
            {TextOnly(FEE_STATUS.PAID_PENDING)}
          </span>
        ) : original.otherFees === FEE_STATUS.PO_RECEIVED_AND_RESENT ? (
          <span
            className="c-status-label c-status-label--pending"
            title={TextOnly('PO_RECEIVED_AND_RESENT')}
          >
            {TextOnly(FEE_STATUS.PO_RECEIVED_AND_RESENT)}
          </span>
        ) : original.otherFees === FEE_STATUS.INVOICED ? (
          <span
            className="c-status-label c-status-label--pending"
            title={TextOnly('INVOICED')}
          >
            {TextOnly(FEE_STATUS.INVOICED)}
          </span>
        ) : (
          <span
            className="c-status-label c-status-label--invalid"
            title={TextOnly('UNPAID')}
          >
            {TextOnly(FEE_STATUS.UNPAID)}
          </span>
        ),
    },
    {
      Header: 'Support',
      accessor: 'supportDocs',
      filter: multiFilter,
      minWidth: 85,
      Cell: ({ row: { original } }: any) =>
        original.supportDocs === SUPPORT_STATUS.NA ? (
          <span
            className="c-status-label-mini c-status-label-mini--notapplicable"
            title={TextOnly('NA')}
          >
            {TextOnly(SUPPORT_STATUS.NA)}
          </span>
        ) : original.supportDocs === SUPPORT_STATUS.COMPLETE ? (
          <span
            className="c-status-label-mini c-status-label-mini--valid"
            title={TextOnly('ACTIVE')}
          >
            {TextOnly(SUPPORT_STATUS.COMPLETE)}
          </span>
        ) : (
          <span
            className="c-status-label-mini c-status-label-mini--invalid"
            title={TextOnly('UNPAID')}
          >
            {TextOnly(SUPPORT_STATUS.WAITING)}
          </span>
        ),
    },
    {
      Header: '',
      accessor: 'crud',
      maxWidth: 50,
      disableSortBy: true,
    },
  ];

  const toolAdminColumns = [
    {
      Header: 'OEM Partner',
      accessor: 'oemName',
      filter: multiFilter,
      minWidth: 140,
    },
    {
      Header: 'Region',
      accessor: 'agreementRegion',
      filter: multiFilter,
      minWidth: 140,
      Cell: ({ row: { original } }: any) => (
        <>{TextOnly(original.agreementRegion)}</>
      ),
    },
    {
      Header: 'Year',
      accessor: 'year',
      filter: multiYearFilter,
      minWidth: 110,
      Cell: ({ row: { original } }: any) => (
        <>{formatModelYears(original.year)}</>
      ),
    },
    {
      Header: 'Annual Fees',
      accessor: 'annualFees',
      filter: multiFilter,
      minWidth: 110,
      Cell: ({ row: { original } }: any) =>
        original.annualFees === FEE_STATUS.NA ? (
          <span
            className="c-status-label c-status-label--notapplicable"
            title={TextOnly('NA')}
          >
            {TextOnly(FEE_STATUS.NA)}
          </span>
        ) : original.annualFees === FEE_STATUS.PAID ? (
          <span
            className="c-status-label c-status-label--valid"
            title={TextOnly('PAID')}
          >
            {TextOnly(FEE_STATUS.PAID)}
          </span>
        ) : original.annualFees === FEE_STATUS.PO_RECEIVED_AND_RESENT ? (
          <span
            className="c-status-label c-status-label--pending"
            title={TextOnly('PO_RECEIVED_AND_RESENT')}
          >
            {TextOnly(FEE_STATUS.PO_RECEIVED_AND_RESENT)}
          </span>
        ) : original.annualFees === FEE_STATUS.INVOICED ? (
          <span
            className="c-status-label c-status-label--pending"
            title={TextOnly('INVOICED')}
          >
            {TextOnly(FEE_STATUS.INVOICED)}
          </span>
        ) : (
          <span
            className="c-status-label c-status-label--invalid"
            title={TextOnly('UNPAID')}
          >
            {TextOnly(FEE_STATUS.UNPAID)}
          </span>
        ),
    },
    {
      Header: 'Insurance',
      accessor: 'insuranceDocs',
      filter: multiFilter,
      minWidth: 90,
      width: 90,
      Cell: ({ row: { original } }: any) =>
        original.insuranceDocs === INSURANCE_STATUS.NA ? (
          <span
            className="c-status-label-mini c-status-label-mini--notapplicable"
            title={TextOnly('NA')}
          >
            {TextOnly(INSURANCE_STATUS.NA)}
          </span>
        ) : original.insuranceDocs === INSURANCE_STATUS.EXPIRED ? (
          <span
            className="c-status-label-mini c-status-label-mini--expired"
            title={TextOnly('EXPIRED')}
          >
            {TextOnly(INSURANCE_STATUS.EXPIRED)}
          </span>
        ) : original.insuranceDocs === INSURANCE_STATUS.EXPIRING ? (
          <span
            className="c-status-label-mini c-status-label-mini--expiring"
            title={TextOnly('EXPIRING')}
          >
            {TextOnly(INSURANCE_STATUS.EXPIRING)}
          </span>
        ) : original.insuranceDocs === INSURANCE_STATUS.ACTIVE ? (
          <span
            className="c-status-label-mini c-status-label-mini--valid"
            title={TextOnly('ACTIVE')}
          >
            {TextOnly(INSURANCE_STATUS.ACTIVE)}
          </span>
        ) : original.insuranceDocs === INSURANCE_STATUS.RECEIVED ? (
          <span
            className="c-status-label-mini c-status-label-mini--pending"
            title={TextOnly('RECEIVED')}
          >
            {TextOnly(INSURANCE_STATUS.RECEIVED)}
          </span>
        ) : (
          <span
            className="c-status-label-mini c-status-label-mini--invalid"
            title={TextOnly('UNPAID')}
          >
            {TextOnly(INSURANCE_STATUS.INACTIVE)}
          </span>
        ),
    },
    {
      Header: 'Lic. Agreement',
      accessor: 'licenseAgreement',
      filter: multiFilter,
      minWidth: 115,
      width: 115,
      Cell: ({ row: { original } }: any) => (
        <div className="l-flex-align-center">
          {original.licenseAgreement === AGREEMENT_STATUS.SIGNED ? (
            <span
              className="c-status-label-mini c-status-label-mini--valid"
              title={TextOnly('SIGNED')}
            >
              {TextOnly(AGREEMENT_STATUS.SIGNED)}
            </span>
          ) : original.licenseAgreement === AGREEMENT_STATUS.SENT ? (
            <span
              className="c-status-label-mini c-status-label-mini--pending"
              title={TextOnly('SENT')}
            >
              {TextOnly(AGREEMENT_STATUS.SENT)}
            </span>
          ) : original.licenseAgreement === AGREEMENT_STATUS.RECEIVED ? (
            <span
              className="c-status-label-mini c-status-label-mini--pending"
              title={TextOnly('RECEIVED')}
            >
              {TextOnly(AGREEMENT_STATUS.RECEIVED)}
            </span>
          ) : (
            <span
              className="c-status-label-mini c-status-label-mini--invalid"
              title={TextOnly('UNSIGNED')}
            >
              {TextOnly(AGREEMENT_STATUS.UNSIGNED)}
            </span>
          )}
          {original.agreementNotes !== '' ? (
            <button
              className="c-btn-icon c-btn-icon--tooltip"
              title={original.agreementNotes}
            >
              <div className="c-btn__inner">
                <i className="c-btn__icon fal fa-sticky-note" />
              </div>
            </button>
          ) : null}
        </div>
      ),
    },
    {
      Header: 'Comp Fees',
      accessor: 'compFees',
      filter: multiFilter,
      minWidth: 110,
      Cell: ({ row: { original } }: any) =>
        original.compFees === FEE_STATUS.NA ? (
          <span
            className="c-status-label c-status-label--notapplicable"
            title={TextOnly('NA')}
          >
            {TextOnly(FEE_STATUS.NA)}
          </span>
        ) : original.compFees === FEE_STATUS.PAID ? (
          <span
            className="c-status-label c-status-label--valid"
            title={TextOnly('PAID')}
          >
            {TextOnly(FEE_STATUS.PAID)}
          </span>
        ) : original.compFees === FEE_STATUS.PO_RECEIVED_AND_RESENT ? (
          <span
            className="c-status-label c-status-label--pending"
            title={TextOnly('PO_RECEIVED_AND_RESENT')}
          >
            {TextOnly(FEE_STATUS.PO_RECEIVED_AND_RESENT)}
          </span>
        ) : original.compFees === FEE_STATUS.INVOICED ? (
          <span
            className="c-status-label c-status-label--pending"
            title={TextOnly('INVOICED')}
          >
            {TextOnly(FEE_STATUS.INVOICED)}
          </span>
        ) : (
          <span
            className="c-status-label c-status-label--invalid"
            title={TextOnly('UNPAID')}
          >
            {TextOnly(FEE_STATUS.UNPAID)}
          </span>
        ),
    },
    {
      Header: 'Other Fees',
      accessor: 'otherFees',
      filter: multiFilter,
      minWidth: 110,
      Cell: ({ row: { original } }: any) =>
        original.otherFees === FEE_STATUS.NA ? (
          <span
            className="c-status-label c-status-label--notapplicable"
            title={TextOnly('NA')}
          >
            {TextOnly(FEE_STATUS.NA)}
          </span>
        ) : original.otherFees === FEE_STATUS.PAID ? (
          <span
            className="c-status-label c-status-label--valid"
            title={TextOnly('PAID')}
          >
            {TextOnly(FEE_STATUS.PAID)}
          </span>
        ) : original.otherFees === FEE_STATUS.PO_RECEIVED_AND_RESENT ? (
          <span
            className="c-status-label c-status-label--pending"
            title={TextOnly('PO_RECEIVED_AND_RESENT')}
          >
            {TextOnly(FEE_STATUS.PO_RECEIVED_AND_RESENT)}
          </span>
        ) : original.otherFees === FEE_STATUS.INVOICED ? (
          <span
            className="c-status-label c-status-label--pending"
            title={TextOnly('INVOICED')}
          >
            {TextOnly(FEE_STATUS.INVOICED)}
          </span>
        ) : (
          <span
            className="c-status-label c-status-label--invalid"
            title={TextOnly('UNPAID')}
          >
            {TextOnly(FEE_STATUS.UNPAID)}
          </span>
        ),
    },
    {
      Header: 'Support',
      accessor: 'supportDocs',
      filter: multiFilter,
      minWidth: 85,
      Cell: ({ row: { original } }: any) =>
        original.supportDocs === SUPPORT_STATUS.NA ? (
          <span
            className="c-status-label-mini c-status-label-mini--notapplicable"
            title={TextOnly('NA')}
          >
            {TextOnly(SUPPORT_STATUS.NA)}
          </span>
        ) : original.supportDocs === SUPPORT_STATUS.COMPLETE ? (
          <span
            className="c-status-label-mini c-status-label-mini--valid"
            title={TextOnly('ACTIVE')}
          >
            {TextOnly(SUPPORT_STATUS.COMPLETE)}
          </span>
        ) : (
          <span
            className="c-status-label-mini c-status-label-mini--invalid"
            title={TextOnly('UNPAID')}
          >
            {TextOnly(SUPPORT_STATUS.WAITING)}
          </span>
        ),
    },
    {
      Header: '',
      accessor: 'crud',
      maxWidth: 50,
      disableSortBy: true,
    },
  ];
  // #endregion 3

  // #region 4 formik
  const formik = useFormik({
    initialValues: {
      oemFilter: oemFilter,
      toolFilter: toolFilter,
      regionFilter: regionFilter,
      yearFilter: yearFilter,
      feeStatusFilter: feeStatusFilter,
      insuranceStatusFilter: insuranceStatusFilter,
      supportStatusFilter: supportStatusFilter,
      agreementStatusFilter: agreementStatusFilter,
    },
    onSubmit: async (values) => {
      setOemFilter(values.oemFilter);
      setToolFilter(values.toolFilter);
      setRegionFilter(values.regionFilter);
      setYearFilter(values.yearFilter);
      setFeeStatusFilter(values.feeStatusFilter);
      setInsuranceStatusFilter(values.insuranceStatusFilter);
      setSupportStatusFilter(values.supportStatusFilter);
      setAgreementStatusFilter(values.agreementStatusFilter);
      toggleFilterDialog();
    },
    enableReinitialize: true,
  });

  // #endregion 4

  // #region 5 funcitons

  useEffect(() => {
    if (selectedAgreementId && agreementsData) {
      const agreement = agreementsData.agreementList.find(
        (agreement) => agreement.agreementID === selectedAgreementId
      );
      if (agreement) {
        const invalidSections = isAgreementValid(agreement);
        setSelectedAgreement({ ...agreement, invalidSections });
        setCurrentDialog(AGREEMENTS_DIALOGS.EDIT_AGREEMENT);
      }
    }
  }, [selectedAgreementId, agreementsData]);

  const handleSelectVendorFromMap = (agreementID: string) => {
    setSelectedAgreementId(agreementID);
    if (mapIsShown) {
      closeMap();
    }
  };

  const toggleFilterDialog = () => {
    currentDialog === AGREEMENTS_DIALOGS.FILTER
      ? setCurrentDialog(null)
      : setCurrentDialog(AGREEMENTS_DIALOGS.FILTER);
  };

  const toggleAddAgreementDialog = () => {
    currentDialog === AGREEMENTS_DIALOGS.ADD_AGREEMENT
      ? setCurrentDialog(null)
      : setCurrentDialog(AGREEMENTS_DIALOGS.ADD_AGREEMENT);
  };

  const toggleEditAgreementDialog = () => {
    if (currentDialog === AGREEMENTS_DIALOGS.EDIT_AGREEMENT) {
      setCurrentDialog(null);
      setSelectedAgreement(null);
      setSelectedAgreementId(RESET);
    } else {
      setCurrentDialog(AGREEMENTS_DIALOGS.EDIT_AGREEMENT);
    }
  };

  const openMap = (region: REGIONS) => {
    setInitialRegion({
      latitude: REGION_INFO[region].latitude,
      longitude: REGION_INFO[region].longitude,
    });
    toggleMapIsShown(true);
  };

  const closeMap = () => {
    toggleMapIsShown(false);
  };

  const finishMutation = () => {
    // setCurrentDialog(null);
    // setSelectedAgreement(null);
    // setSelectedAgreementId(RESET);
    refetchAgreements();
  };

  const resetFilters = () => {
    setOemFilter([]);
    setToolFilter([]);
    setRegionFilter([]);
    setYearFilter([]);
    setFeeStatusFilter([]);
    setInsuranceStatusFilter([]);
    setSupportStatusFilter([]);
    setAgreementStatusFilter([]);
    setCurrentDialog(null);
  };
  function handleFilterDelete(field: string, value: string) {
    if (field === 'oem') {
      setOemFilter(oemFilter.filter((oem) => oem !== value));
    } else if (field === 'tool') {
      setToolFilter(toolFilter.filter((tool) => tool !== value));
    } else if (field === 'region') {
      setRegionFilter(regionFilter.filter((region) => region !== value));
    } else if (field === 'year') {
      setYearFilter(yearFilter.filter((year) => year !== value));
    } else if (field === 'feeStatus') {
      setFeeStatusFilter(
        feeStatusFilter.filter((feeStatus) => feeStatus.id !== value)
      );
    } else if (field === 'insuranceStatus') {
      setInsuranceStatusFilter(
        insuranceStatusFilter.filter(
          (insuranceStatus) => insuranceStatus.id !== value
        )
      );
    } else if (field === 'supportStatus') {
      setSupportStatusFilter(
        supportStatusFilter.filter(
          (supportStatus) => supportStatus.id !== value
        )
      );
    } else if (field === 'agreementStatus') {
      setAgreementStatusFilter(
        agreementStatusFilter.filter(
          (agreementStatus) => agreementStatus.id !== value
        )
      );
    }
  }

  const oemFilterSelections =
    oemFilter.length > 0
      ? {
          oem: oemFilter.map((oem) => ({
            key: oem,
            value: oem,
            label: oem,
            pillLabel: oem,
          })),
        }
      : null;

  const toolFilterSelections =
    toolFilter.length > 0
      ? {
          tool: toolFilter.map((tool) => ({
            key: tool,
            value: tool,
            label: tool,
            pillLabel: tool,
          })),
        }
      : null;

  const regionFilterSelections =
    regionFilter.length > 0
      ? {
          region: regionFilter.map((region) => ({
            key: region,
            value: region,
            label: TextOnly(region),
            pillLabel: TextOnly(region),
          })),
        }
      : null;

  const yearFilterSelections =
    yearFilter.length > 0
      ? {
          year: yearFilter.map((year) => ({
            key: year,
            value: year,
            label: year,
            pillLabel: year,
          })),
        }
      : null;

  const feeStatusFilterSelections =
    feeStatusFilter.length > 0
      ? {
          feeStatus: feeStatusFilter.map((feeStatus) => ({
            key: feeStatus.id,
            value: feeStatus.id,
            label: feeStatus.label,
            pillLabel: feeStatus.label,
          })),
        }
      : null;

  const insuranceStatusFilterSelections =
    insuranceStatusFilter.length > 0
      ? {
          insuranceStatus: insuranceStatusFilter.map((insuranceStatus) => ({
            key: insuranceStatus.id,
            value: insuranceStatus.id,
            label: insuranceStatus.label,
            pillLabel: insuranceStatus.label,
          })),
        }
      : null;

  const supportStatusFilterSelections =
    supportStatusFilter.length > 0
      ? {
          supportStatus: supportStatusFilter.map((supportStatus) => ({
            key: supportStatus.id,
            value: supportStatus.id,
            label: supportStatus.label,
            pillLabel: supportStatus.label,
          })),
        }
      : null;

  const agreementStatusFilterSelections =
    agreementStatusFilter.length > 0
      ? {
          agreementStatus: agreementStatusFilter.map((agreementStatus) => ({
            key: agreementStatus.id,
            value: agreementStatus.id,
            label: agreementStatus.label,
            pillLabel: agreementStatus.label,
          })),
        }
      : null;

  const filters = {
    ...oemFilterSelections,
    ...toolFilterSelections,
    ...regionFilterSelections,
    ...yearFilterSelections,
    ...feeStatusFilterSelections,
    ...insuranceStatusFilterSelections,
    ...supportStatusFilterSelections,
    ...agreementStatusFilterSelections,
  };

  // #endregion 5

  const formatVendors = (
    toolVendorIds: string[] | undefined,
    region: string
  ) => {
    const vendorsList = toolVendorIds
      ?.map((toolVendorId) =>
        agreementsData?.agreementList
          .filter(
            (entry) =>
              entry.agreementRegion === region &&
              entry.toolVendorID === toolVendorId
          )
          .map((agreement) => ({
            agreementID: agreement.agreementID,
            name:
              toolVendors?.find(
                (vendor) => vendor.vendorID === agreement.toolVendorID
              )?.vendorName || '',
            isValid: isAgreementValid(agreement).length === 0,
          }))
      )
      .flat();

    var uniqueVendorsList: Array<{
      agreementID: string;
      name: string;
      isValid: boolean;
    }> = [];

    vendorsList?.forEach(function (item) {
      var existing = uniqueVendorsList.filter(function (v, i) {
        return v.name === item?.name;
      });

      if (existing.length) {
        var existingIndex = uniqueVendorsList.indexOf(existing[0]);
        if (!item?.isValid) {
          uniqueVendorsList[existingIndex].isValid = false;
        }
      } else {
        if (item) uniqueVendorsList.push(item);
      }
    });

    return uniqueVendorsList;
  };

  const formateAgreementsForMap = () => {
    const toolVendorIds = agreementsData?.agreementList
      .map(({ toolVendorID }) => toolVendorID)
      .filter((toolVendorID, i, arr) => arr.indexOf(toolVendorID) === i);

    const vendorsByRegion = REGIONS_LIST.map((region) => {
      const formattedVendors = formatVendors(toolVendorIds, region);

      return {
        label: region as REGIONS,
        activeCount: formattedVendors?.filter((vendor) => vendor.isValid)
          .length,
        inactiveCount: formattedVendors?.filter((vendor) => !vendor.isValid)
          .length,
        vendors: formattedVendors,
      };
    });

    return vendorsByRegion;
  };

  const regionStats = formateAgreementsForMap();

  if (isLoadingAgreements || (isSite && isLoadingVendors)) {
    return <Loading />;
  }

  if (isOEM && mapIsShown) {
    return (
      <AgreementsMap
        closeMap={closeMap}
        regionStats={regionStats as RegionVendor[]}
        initialRegion={initialRegion}
        handleSelectVendorFromMap={handleSelectVendorFromMap}
      />
    );
  }

  if (isOEM) {
    return (
      <>
        <VendorAgreements
          agreements={agreements}
          columns={oemAdminColumns}
          formik={formik}
          resetFilters={resetFilters}
          filters={filters}
          feeStatusDropdown={feeStatusDropdown as FEE_STATUS[]}
          tableFilters={[
            {
              columnId: 'toolName',
              filter: toolFilter.length > 0 ? toolFilter.join('') : undefined,
            },
            {
              columnId: 'agreementRegion',
              filter:
                regionFilter.length > 0 ? regionFilter.join('') : undefined,
            },
            {
              columnId: 'year',
              filter: yearFilter.length > 0 ? yearFilter.join('') : undefined,
            },
            {
              columnId: 'annualFees',
              filter:
                feeStatusFilter.length > 0
                  ? feeStatusFilter.map((fee) => fee.id).join(',')
                  : undefined,
            },
            {
              columnId: 'insuranceDocs',
              filter:
                insuranceStatusFilter.length > 0
                  ? insuranceStatusFilter
                      .map((insurance) => insurance.id)
                      .join(',')
                  : undefined,
            },
            {
              columnId: 'supportDocs',
              filter:
                supportStatusFilter.length > 0
                  ? supportStatusFilter.map((support) => support.id).join(',')
                  : undefined,
            },
            {
              columnId: 'licenseAgreement',
              filter:
                agreementStatusFilter.length > 0
                  ? agreementStatusFilter.map((license) => license.id).join(',')
                  : undefined,
            },
          ]}
          onFilterClick={handleFilterDelete}
          currentDialog={currentDialog}
          toggleFilterDialog={toggleFilterDialog}
          toggleEditAgreementDialog={toggleEditAgreementDialog}
          oemVendors={[]}
          toolVendors={toolVendors || []}
          selectedAgreement={selectedAgreement}
          setSelectedAgreement={setSelectedAgreement}
        />
      </>
    );
  }

  if (isTool) {
    return (
      <>
        <VendorAgreements
          agreements={agreements}
          columns={toolAdminColumns}
          formik={formik}
          resetFilters={resetFilters}
          filters={filters}
          feeStatusDropdown={feeStatusDropdown as FEE_STATUS[]}
          tableFilters={[
            {
              columnId: 'oemName',
              filter: oemFilter.length > 0 ? oemFilter.join('') : undefined,
            },
            {
              columnId: 'agreementRegion',
              filter:
                regionFilter.length > 0 ? regionFilter.join('') : undefined,
            },
            {
              columnId: 'year',
              filter: yearFilter.length > 0 ? yearFilter.join('') : undefined,
            },
            {
              columnId: 'annualFees',
              filter:
                feeStatusFilter.length > 0
                  ? feeStatusFilter.map((fee) => fee.id).join(',')
                  : undefined,
            },
            {
              columnId: 'insuranceDocs',
              filter:
                insuranceStatusFilter.length > 0
                  ? insuranceStatusFilter
                      .map((insurance) => insurance.id)
                      .join(',')
                  : undefined,
            },
            {
              columnId: 'supportDocs',
              filter:
                supportStatusFilter.length > 0
                  ? supportStatusFilter.map((support) => support.id).join(',')
                  : undefined,
            },
            {
              columnId: 'licenseAgreement',
              filter:
                agreementStatusFilter.length > 0
                  ? agreementStatusFilter.map((license) => license.id).join(',')
                  : undefined,
            },
          ]}
          onFilterClick={handleFilterDelete}
          currentDialog={currentDialog}
          toggleFilterDialog={toggleFilterDialog}
          toggleEditAgreementDialog={toggleEditAgreementDialog}
          oemVendors={oemVendors || []}
          toolVendors={[]}
          selectedAgreement={selectedAgreement}
          setSelectedAgreement={setSelectedAgreement}
        />
      </>
    );
  }

  return (
    <>
      <Agreements
        agreements={agreements}
        columns={siteAdminColumns}
        formik={formik}
        resetFilters={resetFilters}
        filters={filters}
        feeStatusDropdown={feeStatusDropdown as FEE_STATUS[]}
        isSite={isSite}
        tableFilters={[
          {
            columnId: 'oemName',
            filter: oemFilter.length > 0 ? oemFilter.join('') : undefined,
          },
          {
            columnId: 'toolName',
            filter: toolFilter.length > 0 ? toolFilter.join('') : undefined,
          },
          {
            columnId: 'agreementRegion',
            filter: regionFilter.length > 0 ? regionFilter.join('') : undefined,
          },
          {
            columnId: 'year',
            filter: yearFilter.length > 0 ? yearFilter.join(',') : undefined,
          },
          {
            columnId: 'annualFees',
            filter:
              feeStatusFilter.length > 0
                ? feeStatusFilter.map((fee) => fee.id).join(',')
                : undefined,
          },
          {
            columnId: 'insuranceDocs',
            filter:
              insuranceStatusFilter.length > 0
                ? insuranceStatusFilter
                    .map((insurance) => insurance.id)
                    .join(',')
                : undefined,
          },
          {
            columnId: 'supportDocs',
            filter:
              supportStatusFilter.length > 0
                ? supportStatusFilter.map((support) => support.id).join(',')
                : undefined,
          },
          {
            columnId: 'licenseAgreement',
            filter:
              agreementStatusFilter.length > 0
                ? agreementStatusFilter.map((license) => license.id).join(',')
                : undefined,
          },
        ]}
        onFilterClick={handleFilterDelete}
        currentDialog={currentDialog}
        toggleFilterDialog={toggleFilterDialog}
        toggleAddAgreementDialog={toggleAddAgreementDialog}
        toggleEditAgreementDialog={toggleEditAgreementDialog}
        oemVendors={oemVendors || []}
        toolVendors={toolVendors || []}
        selectedAgreement={selectedAgreement}
        setSelectedAgreement={setSelectedAgreement}
        finishMutation={finishMutation}
      />
    </>
  );
};
