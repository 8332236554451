import { useFormik } from 'formik';
import React from 'react';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { Header } from '../components/Header';
import LoaderButton from '../components/LoaderButton';
import { Loading } from '../components/Loading';
import { TextOnly, Text } from '../components/Text';
import { useUser } from '../context/User';
import {
  getVendorInfo,
  updateVendorInfo,
  UpdateVendorInfoParams,
} from '../libs/db-lib';

export const CompanyProfile = () => {
  const { vendorID, isAdmin } = useUser();

  const { data: vendorInfo, isLoading: isLoadingVendorInfo } = useQuery(
    'getVendorInfo',
    () => getVendorInfo({ vendorID }),
    {
      enabled: !!vendorID,
    }
  );

  const updateVendorInfoMutation = useMutation<
    { error?: string },
    Error,
    UpdateVendorInfoParams
  >((vendorFields) => updateVendorInfo(vendorFields), {
    onSuccess: (data) => {
      if (data?.error) {
        toast.error(data.error, {
          autoClose: false,
          containerId: 'standard',
        });
        return;
      }
      toast.success(TextOnly('updateSuccess'), {
        containerId: 'standard',
      });
      formik.setSubmitting(false);
    },
    onError: (error) => {
      toast.error(error.message, {
        autoClose: false,
        containerId: 'standard',
      });
    },
  });

  const formik = useFormik({
    initialValues: {
      vendorName: vendorInfo?.vendorName || '',
      vendorContactStreet: vendorInfo?.vendorContactStreet || '',
      vendorContactCity: vendorInfo?.vendorContactCity || '',
      vendorContactState: vendorInfo?.vendorContactState || '',
      vendorContactZip: vendorInfo?.vendorContactZip || '',
      vendorContactPhone: vendorInfo?.vendorContactPhone || '',
    },
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      updateVendorInfoMutation.mutate({
        vendorName: values.vendorName,
        vendorContactStreet: values.vendorContactStreet,
        vendorContactCity: values.vendorContactCity,
        vendorContactState: values.vendorContactState,
        vendorContactZip: values.vendorContactZip,
        vendorContactPhone: values.vendorContactPhone,
      });
    },
    enableReinitialize: true,
  });

  if (isLoadingVendorInfo) {
    return <Loading />;
  }

  return (
    <>
      <Header title={TextOnly('userProfile')} />
      <div className="l-view-layout--user-profile">
        <div className="user-profile-form-column">
          <form onSubmit={formik.handleSubmit}>
            <div>
              <h2>
                <Text tid="companyInfo" />
              </h2>
            </div>
            <div className="c-field">
              <label htmlFor="vendorName" className="c-field__label">
                <Text tid="vendorName" />:
              </label>
              <input
                id="vendorName"
                name="vendorName"
                type="text"
                maxLength={50}
                className={`c-input`}
                placeholder={TextOnly('vendorName')}
                value={formik.values.vendorName}
                onChange={formik.handleChange}
                disabled={!isAdmin}
              />
              {formik.errors.vendorName ? (
                <div className="c-field__error">
                  <Text tid="vendorNameHint" />
                </div>
              ) : null}
            </div>

            <div className="c-field">
              <label htmlFor="vendorContactStreet" className="c-field__label">
                <Text tid="vendorContactStreet" />:
              </label>
              <input
                id="vendorContactStreet"
                name="vendorContactStreet"
                type="text"
                maxLength={50}
                className={`c-input`}
                placeholder={TextOnly('vendorContactStreet')}
                value={formik.values.vendorContactStreet}
                onChange={formik.handleChange}
                disabled={!isAdmin}
              />
              {formik.errors.vendorContactStreet ? (
                <div className="c-field__error">
                  <Text tid="vendorContactStreetHint" />
                </div>
              ) : null}
            </div>

            <div className="c-field">
              <label htmlFor="vendorContactCity" className="c-field__label">
                <Text tid="vendorContactCity" />:
              </label>
              <input
                id="vendorContactCity"
                name="vendorContactCity"
                type="text"
                maxLength={50}
                className={`c-input`}
                placeholder={TextOnly('vendorContactCity')}
                value={formik.values.vendorContactCity}
                onChange={formik.handleChange}
                disabled={!isAdmin}
              />
              {formik.errors.vendorContactCity ? (
                <div className="c-field__error">
                  <Text tid="vendorContactCityHint" />
                </div>
              ) : null}
            </div>

            <div className="l-flex-wrap l-flex-gap">
              <div className={`c-field l-container-xs`}>
                <label htmlFor="vendorContactState" className="c-field__label">
                  <Text tid="vendorContactState" />:
                </label>
                <input
                  id="vendorContactState"
                  name="vendorContactState"
                  type="text"
                  maxLength={50}
                  className={`u-input-side-by-side c-input`}
                  placeholder={TextOnly('vendorContactState')}
                  value={formik.values.vendorContactState}
                  onChange={formik.handleChange}
                  disabled={!isAdmin}
                />
                {formik.errors.vendorContactState ? (
                  <div className="c-field__error">
                    <Text tid="vendorContactStateHint" />
                  </div>
                ) : null}
              </div>

              <div className={`c-field l-container-xs`}>
                <label htmlFor="vendorContactZip" className="c-field__label">
                  <Text tid="vendorContactZip" />:
                </label>
                <input
                  id="vendorContactZip"
                  name="vendorContactZip"
                  type="text"
                  maxLength={50}
                  className={`u-input-side-by-side c-input`}
                  placeholder={TextOnly('vendorContactZip')}
                  value={formik.values.vendorContactZip}
                  onChange={formik.handleChange}
                  disabled={!isAdmin}
                />
                {formik.errors.vendorContactZip ? (
                  <div className="c-field__error">
                    <Text tid="vendorContactZipHint" />
                  </div>
                ) : null}
              </div>
            </div>

            <div className="c-field">
              <label htmlFor="vendorContactPhone" className="c-field__label">
                <Text tid="vendorContactPhone" />:
              </label>
              <input
                id="vendorContactPhone"
                name="vendorContactPhone"
                type="text"
                maxLength={50}
                className={`c-input`}
                placeholder={TextOnly('vendorContactPhone')}
                value={formik.values.vendorContactPhone}
                onChange={formik.handleChange}
                disabled={!isAdmin}
              />
              {formik.errors.vendorContactPhone ? (
                <div className="c-field__error">
                  <Text tid="vendorContactPhoneHint" />
                </div>
              ) : null}
            </div>
            {isAdmin ? (
              <LoaderButton
                type="submit"
                id="submit-button"
                disabled={!formik.isValid}
                isLoading={formik.isSubmitting}
                text={TextOnly('update')}
                loadingText={TextOnly('updating')}
              />
            ) : null}
          </form>
        </div>
      </div>
    </>
  );
};
