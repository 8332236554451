export const CORS_ERROR_MESSAGE = 'Failed to fetch';

export enum VENDOR_TYPE {
  OEM = 'OEM',
  TOOL = 'TOOL',
}

export enum USER_TYPE {
  SITE_ADMIN = 'SITE_ADMIN',
  OEM_ADMIN = 'OEM_ADMIN',
  TOOL_ADMIN = 'TOOL_ADMIN',
  OEM_USER = 'OEM_USER',
  TOOL_USER = 'TOOL_USER',
}

export const USER_TYPE_LIST = Object.keys(USER_TYPE).map(
  (key) => key as USER_TYPE
);

export const VENDOR_TYPE_LIST = Object.keys(VENDOR_TYPE).map(
  (key) => key as VENDOR_TYPE
);

export enum USER_STATE {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum VENDOR_STATE {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum PAYMENT_STATES {
  PAST_DUE = 'PAST_DUE',
  TERMINATED = 'TERMINATED',
}

export enum SHOP_STATES {
  SUSPENDED = 'SUSPENDED',
  CHARGEBACKRECEIVED = 'CHARGEBACKRECEIVED',
}

export enum STAT_TYPE {
  NUMBER = 'NUMBER',
  BAR = 'BAR',
}

export enum TIME_GRANULARITY {
  DAY = 'DAY',
  MONTH = 'MONTH',
  YEAR = 'YEAR',
}

export enum AGREEMENT_TYPE {
  LICENSE_APPLICATION = 'LICENSE_APPLICATION',
  LICENSE_FEE = 'LICENSE_FEE',
  INSURANCE_DOCS = 'INSURANCE_DOCS',
  SUPPORT_DOCS = 'SUPPORT_DOCS',
}

export const AGREEMENT_TYPE_LIST = Object.keys(AGREEMENT_TYPE).map(
  (key) => key as AGREEMENT_TYPE
);

export enum FEE_STATUS {
  NA = 'NA',
  PAID = 'PAID',
  UNPAID = 'UNPAID',
  PAID_TO_OEM = 'PAID_TO_OEM',
  PO_RECEIVED_AND_RESENT = 'PO_RECEIVED_AND_RESENT',
  PAID_PENDING = 'PAID_PENDING',
  INVOICED = 'INVOICED',
}
export enum DOC_TYPE {
  // license agreement
  LD = 'LD',
  // annual fees
  AD = 'AD',
  // comprehensive fees
  CD = 'CD',
  // other fees
  OD = 'OD',
  // insurance doc
  ID = 'ID',
  // support doc
  SD = 'SD',
}

export const FEE_STATUS_LIST = Object.keys(FEE_STATUS).map(
  (key) => key as FEE_STATUS
);

export const OEM_FEE_STATUS_LIST = [
  FEE_STATUS.NA,
  FEE_STATUS.PAID,
  FEE_STATUS.UNPAID,
  FEE_STATUS.PO_RECEIVED_AND_RESENT,
  FEE_STATUS.PAID_PENDING,
  FEE_STATUS.INVOICED,
];

export const TOOL_FEE_STATUS_LIST = [
  FEE_STATUS.NA,
  FEE_STATUS.PAID,
  FEE_STATUS.UNPAID,
  FEE_STATUS.PO_RECEIVED_AND_RESENT,
  FEE_STATUS.INVOICED,
];

export enum INSURANCE_STATUS {
  NA = 'NA',
  EXPIRED = 'EXPIRED',
  EXPIRING = 'EXPIRING',
  ACTIVE = 'ACTIVE',
  RECEIVED = 'RECEIVED',
  INACTIVE = 'INACTIVE',
}

export const INSURANCE_STATUS_LIST = Object.keys(INSURANCE_STATUS).map(
  (key) => key as INSURANCE_STATUS
);

export enum AGREEMENT_STATUS {
  SIGNED = 'SIGNED',
  SENT = 'SENT',
  RECEIVED = 'RECEIVED',
  UNSIGNED = 'UNSIGNED',
}

export const AGREEMENT_STATUS_LIST = Object.keys(AGREEMENT_STATUS).map(
  (key) => key as AGREEMENT_STATUS
);

export enum SUPPORT_STATUS {
  NA = 'NA',
  COMPLETE = 'COMPLETE',
  WAITING = 'WAITING',
}

export const SUPPORT_STATUS_LIST = Object.keys(SUPPORT_STATUS).map(
  (key) => key as SUPPORT_STATUS
);

export enum AGREEMENT_SECTIONS {
  LICENSE_AGREEMENT = 'LICENSE_AGREEMENT',
  ANNUAL_FEE = 'ANNUAL_FEE',
  COMPREHENSIVE_FEE = 'COMPREHENSIVE_FEE',
  OTHER_FEE = 'OTHER_FEE',
  INSURANCE_DOC = 'INSURANCE_DOC',
  SUPPORT_DOC = 'SUPPORT_DOC',
}

export enum REGIONS {
  NORTHAMERICA = 'NORTHAMERICA',
  EUROPE = 'EUROPE',
  SOUTHAMERICA = 'SOUTHAMERICA',
  CHINA = 'CHINA',
  JAPAN = 'JAPAN',
  OCEANIA = 'OCEANIA',
  INDIA = 'INDIA',
  AFRICA = 'AFRICA',
}

export const REGIONS_LIST = Object.keys(REGIONS).map((key) => key as REGIONS);

export const REGION_INFO = {
  [REGIONS.NORTHAMERICA]: {
    name: 'North America',
    latitude: 42,
    longitude: -100,
  },
  [REGIONS.EUROPE]: {
    name: 'Europe',
    latitude: 53,
    longitude: 19,
  },
  [REGIONS.SOUTHAMERICA]: {
    name: 'South America',
    latitude: -10.5,
    longitude: -59.5,
  },
  [REGIONS.CHINA]: {
    name: 'China',
    latitude: 35.86166,
    longitude: 104.195397,
  },
  [REGIONS.JAPAN]: {
    name: 'Japan',
    latitude: 37,
    longitude: 150.7,
  },
  [REGIONS.OCEANIA]: {
    name: 'Oceania',
    latitude: -14,
    longitude: 139.5,
  },
  [REGIONS.INDIA]: {
    name: 'India',
    latitude: 20.593684,
    longitude: 78.96288,
  },
  [REGIONS.AFRICA]: {
    name: 'Africa',
    latitude: 16,
    longitude: 17,
  },
};
