import React from 'react';
import { NavLink } from 'react-router-dom';
import logo from '../assets/images/aa.svg';
import { Text, TextOnly } from '../components/Text';
import { USER_TYPE } from '../CONSTANTS';

// nav icons
import { ReactComponent as ManageUsersIcon } from '../assets/icons/icon-nav-oem-manageusers.svg';
import { ReactComponent as ManageVendorsIcon } from '../assets/icons/icon-nav-siteadmin-managevendors.svg';
import { ReactComponent as ViewAgreementsIcon } from '../assets/icons/icon-nav-oem-agreements.svg';
import { ReactComponent as ViewReportsIcon } from '../assets/icons/nav-view-reports.svg';
import { ReactComponent as ViewToolsIcon } from '../assets/icons/icon-nav-shop-profile.svg';
import { ReactComponent as UserProfileIcon } from '../assets/icons/nav-user-profile.svg';
import { ReactComponent as SiteAdminIcon } from '../assets/icons/icon-nav-siteadmin-userprofile.svg';
import { useUser } from '../context/User';
import { useQuery } from 'react-query';
import { getVendorInfo } from '../libs/db-lib';
import { Loading } from '../components/Loading';
import { useMap } from '../context/Map';

type SidebarProps = {
  isAuthenticated: boolean;
  logout: () => void;
  toggleShowMobileNav: () => void;
  userName: string;
  shopName: string;
  userType: USER_TYPE;
};

export function Sidebar({
  isAuthenticated,
  logout,
  toggleShowMobileNav,
  userType,
  userName,
  shopName,
}: SidebarProps) {
  const { isAdmin, isSite, isOEM, isTool, vendorID } = useUser();
  const { mapIsShown } = useMap();

  const { data: vendorInfo, isLoading: isLoadingVendorInfo } = useQuery(
    'getVendorInfo',
    () => getVendorInfo({ vendorID: vendorID }),
    {
      enabled: !!vendorID,
    }
  );

  const vendorName = vendorInfo?.vendorName;

  if (isLoadingVendorInfo) {
    return <Loading />;
  }

  if (!isAuthenticated || !userName) {
    return (
      <div className={`c-sidebar ${mapIsShown ? 'c-sidebar--light' : ''}`}>
        <div className="c-sidebar__header c-sidebar__header--empty">
          <NavLink
            className={({ isActive }) =>
              'c-primary-nav__link' + (isActive ? ' is-active' : '')
            }
            to="/"
            onClick={toggleShowMobileNav}
          >
            <div className="c-logo">
              <img src={logo} className="c-logo__image" alt="logo" />
            </div>
          </NavLink>
          <p className="u-font-weight-500">
            <Text tid="tagLine" />
          </p>
        </div>
      </div>
    );
  }

  if (!userType) {
    return (
      <div className={`c-sidebar ${mapIsShown ? 'c-sidebar--light' : ''}`}>
        <button
          className="c-btn-icon c-btn-mobile-close"
          onClick={toggleShowMobileNav}
        >
          <div className="c-btn__inner">
            <i className="c-btn__icon fa fa-times" />
          </div>
        </button>
        <div className="c-sidebar__header">
          <NavLink
            className={({ isActive }) =>
              'c-primary-nav__link' + (isActive ? ' is-active' : '')
            }
            to="/myActivity"
            onClick={toggleShowMobileNav}
          >
            <div className="c-logo">
              <img src={logo} className="c-logo__image" alt="logo" />
            </div>
          </NavLink>
        </div>
        <div className="c-sidebar__main">
          <div>
            <nav className="c-primary-nav" role="navigation">
              <ul className="c-primary-nav__list">
                <li className="c-primary-nav__item">
                  <NavLink
                    className={({ isActive }) =>
                      'c-primary-nav__link' + (isActive ? ' is-active' : '')
                    }
                    to="/userProfile"
                    onClick={toggleShowMobileNav}
                  >
                    <div className="c-btn__inner">
                      <UserProfileIcon
                        className="c-primary-nav__icon"
                        title={TextOnly('userProfile')}
                      />
                      {userName}
                    </div>
                  </NavLink>
                </li>
              </ul>
            </nav>
          </div>

          <ul className="c-sidebar__links">
            <li>
              <a
                className="c-sidebar__link c-btn-light"
                href="https://info.autoauth.com/contact/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="c-btn__inner">
                  <i className="c-btn__icon fal fa-question-circle" />
                  <Text tid="support" />
                </div>
              </a>
            </li>
            <li>
              <button className="c-sidebar__link c-btn-light" onClick={logout}>
                <div className="c-btn__inner">
                  <i className="c-btn__icon fal fa-sign-out" />
                  <Text tid="logout" />
                </div>
              </button>
            </li>
          </ul>
        </div>
      </div>
    );
  }

  if (userType)
    return (
      <div className={`c-sidebar ${mapIsShown ? 'c-sidebar--light' : ''}`}>
        <div className="c-sidebar__header">
          <button
            className="c-btn-icon c-btn-mobile-close"
            onClick={toggleShowMobileNav}
          >
            <div className="c-btn__inner">
              <i className="c-btn__icon fa fa-times" />
            </div>
          </button>
          <NavLink
            className={({ isActive }) =>
              'c-primary-nav__link' + (isActive ? ' is-active' : '')
            }
            to="/myActivity"
            onClick={toggleShowMobileNav}
          >
            <div className="c-logo">
              <img src={logo} className="c-logo__image" alt="logo" />
            </div>
          </NavLink>
        </div>
        <div className="c-sidebar__main">
          <div>
            {isSite ? (
              <nav className="c-primary-nav" role="navigation">
                <ul className="c-primary-nav__list">
                  <li className="c-primary-nav__item">
                    <NavLink
                      className={({ isActive }) =>
                        'c-primary-nav__link' + (isActive ? ' is-active' : '')
                      }
                      to="/manageUsers"
                      onClick={toggleShowMobileNav}
                    >
                      <div className="c-btn__inner">
                        <ManageUsersIcon
                          className="c-primary-nav__icon"
                          title={TextOnly('manageUsers')}
                        />
                        <Text tid="manageUsers" />
                      </div>
                    </NavLink>
                  </li>

                  <li className="c-primary-nav__item">
                    <NavLink
                      className={({ isActive }) =>
                        'c-primary-nav__link' + (isActive ? ' is-active' : '')
                      }
                      to="/manageVendors"
                      onClick={toggleShowMobileNav}
                    >
                      <div className="c-btn__inner">
                        <ManageVendorsIcon
                          className="c-primary-nav__icon"
                          title={TextOnly('manageVendors')}
                        />
                        <Text tid="manageVendors" />
                      </div>
                    </NavLink>
                  </li>

                  <li className="c-primary-nav__item">
                    <NavLink
                      className={({ isActive }) =>
                        'c-primary-nav__link' + (isActive ? ' is-active' : '')
                      }
                      to="/viewAgreements"
                      onClick={toggleShowMobileNav}
                    >
                      <div className="c-btn__inner">
                        <ViewAgreementsIcon
                          className="c-primary-nav__icon"
                          title={TextOnly('viewAgreements')}
                        />
                        <Text tid="viewAgreements" />
                      </div>
                    </NavLink>
                  </li>

                  <li className="c-primary-nav__item">
                    <NavLink
                      className={({ isActive }) =>
                        'c-primary-nav__link' + (isActive ? ' is-active' : '')
                      }
                      to={'/statsAndTrends'}
                      onClick={toggleShowMobileNav}
                    >
                      <div className="c-btn__inner">
                        <ViewReportsIcon
                          className="c-primary-nav__icon"
                          title={TextOnly('statsAndTrends')}
                        />
                        <Text tid="statsAndTrends" />
                      </div>
                    </NavLink>
                  </li>

                  <li className="c-primary-nav__item u-text-transform-initial">
                    <NavLink
                      className={({ isActive }) =>
                        'c-primary-nav__link' + (isActive ? ' is-active' : '')
                      }
                      to="/userProfile"
                      onClick={toggleShowMobileNav}
                    >
                      <div className="c-btn__inner">
                        <SiteAdminIcon
                          className="c-primary-nav__icon"
                          title={TextOnly('userProfile')}
                        />
                        {userName}
                      </div>
                    </NavLink>
                  </li>
                </ul>
              </nav>
            ) : isOEM && isAdmin ? (
              <nav className="c-primary-nav" role="navigation">
                <ul className="c-primary-nav__list">
                  <li className="c-primary-nav__item">
                    <NavLink
                      className={({ isActive }) =>
                        'c-primary-nav__link' + (isActive ? ' is-active' : '')
                      }
                      to="/manageUsers"
                      onClick={toggleShowMobileNav}
                    >
                      <div className="c-btn__inner">
                        <ManageUsersIcon
                          className="c-primary-nav__icon"
                          title={TextOnly('manageUsers')}
                        />
                        <Text tid="manageUsers" />
                      </div>
                    </NavLink>
                  </li>

                  <li className="c-primary-nav__item">
                    <NavLink
                      className={({ isActive }) =>
                        'c-primary-nav__link' + (isActive ? ' is-active' : '')
                      }
                      to="/viewAgreements"
                      onClick={toggleShowMobileNav}
                    >
                      <div className="c-btn__inner">
                        <ViewAgreementsIcon
                          className="c-primary-nav__icon"
                          title={TextOnly('viewAgreements')}
                        />
                        <Text tid="viewAgreements" />
                      </div>
                    </NavLink>
                  </li>

                  <li className="c-primary-nav__item">
                    <NavLink
                      className={({ isActive }) =>
                        'c-primary-nav__link' + (isActive ? ' is-active' : '')
                      }
                      to={'/statsAndTrends'}
                      onClick={toggleShowMobileNav}
                    >
                      <div className="c-btn__inner">
                        <ViewReportsIcon
                          className="c-primary-nav__icon"
                          title={TextOnly('statsAndTrends')}
                        />
                        <Text tid="statsAndTrends" />
                      </div>
                    </NavLink>
                  </li>

                  <li className="c-primary-nav__item">
                    <NavLink
                      className={({ isActive }) =>
                        'c-primary-nav__link' + (isActive ? ' is-active' : '')
                      }
                      to="/companyProfile"
                      onClick={toggleShowMobileNav}
                    >
                      <div className="c-btn__inner">
                        <ManageUsersIcon
                          className="c-primary-nav__icon"
                          title={vendorName}
                        />
                        {vendorName}
                      </div>
                    </NavLink>
                  </li>

                  <li className="c-primary-nav__item u-text-transform-initial">
                    <NavLink
                      className={({ isActive }) =>
                        'c-primary-nav__link' + (isActive ? ' is-active' : '')
                      }
                      to="/userProfile"
                      onClick={toggleShowMobileNav}
                    >
                      <div className="c-btn__inner">
                        <UserProfileIcon
                          className="c-primary-nav__icon"
                          title={TextOnly('userProfile')}
                        />
                        {userName}
                      </div>
                    </NavLink>
                  </li>
                </ul>
              </nav>
            ) : isOEM ? (
              <nav className="c-primary-nav" role="navigation">
                <ul className="c-primary-nav__list">
                  <li className="c-primary-nav__item">
                    <NavLink
                      className={({ isActive }) =>
                        'c-primary-nav__link' + (isActive ? 'is-active' : '')
                      }
                      to="/viewAgreements"
                      onClick={toggleShowMobileNav}
                    >
                      <div className="c-btn__inner">
                        <ManageUsersIcon
                          className="c-primary-nav__icon"
                          title={TextOnly('viewAgreements')}
                        />
                        <Text tid="viewAgreements" />
                      </div>
                    </NavLink>
                  </li>

                  <li className="c-primary-nav__item">
                    <NavLink
                      className={({ isActive }) =>
                        'c-primary-nav__link' + (isActive ? 'is-active' : '')
                      }
                      to={'/statsAndTrends'}
                      onClick={toggleShowMobileNav}
                    >
                      <div className="c-btn__inner">
                        <ViewReportsIcon
                          className="c-primary-nav__icon"
                          title={TextOnly('statsAndTrends')}
                        />
                        <Text tid="statsAndTrends" />
                      </div>
                    </NavLink>
                  </li>

                  <li className="c-primary-nav__item">
                    <NavLink
                      className={({ isActive }) =>
                        'c-primary-nav__link' + (isActive ? ' is-active' : '')
                      }
                      to="/companyProfile"
                      onClick={toggleShowMobileNav}
                    >
                      <div className="c-btn__inner">
                        <ManageUsersIcon
                          className="c-primary-nav__icon"
                          title={vendorName}
                        />
                        {vendorName}
                      </div>
                    </NavLink>
                  </li>
                  <li className="c-primary-nav__item u-text-transform-initial">
                    <NavLink
                      className={({ isActive }) =>
                        'c-primary-nav__link' + (isActive ? 'is-active' : '')
                      }
                      to="/userProfile"
                      onClick={toggleShowMobileNav}
                    >
                      <div className="c-btn__inner">
                        <UserProfileIcon
                          className="c-primary-nav__icon"
                          title={TextOnly('userProfile')}
                        />
                        {userName}
                      </div>
                    </NavLink>
                  </li>
                </ul>
              </nav>
            ) : isTool && isAdmin ? (
              <nav className="c-primary-nav" role="navigation">
                <ul className="c-primary-nav__list">
                  <li className="c-primary-nav__item">
                    <NavLink
                      className={({ isActive }) =>
                        'c-primary-nav__link' + (isActive ? ' is-active' : '')
                      }
                      to="/manageUsers"
                      onClick={toggleShowMobileNav}
                    >
                      <div className="c-btn__inner">
                        <ManageUsersIcon
                          className="c-primary-nav__icon"
                          title={TextOnly('manageUsers')}
                        />
                        <Text tid="manageUsers" />
                      </div>
                    </NavLink>
                  </li>

                  <li className="c-primary-nav__item">
                    <NavLink
                      className={({ isActive }) =>
                        'c-primary-nav__link' + (isActive ? ' is-active' : '')
                      }
                      to="/viewTools"
                      onClick={toggleShowMobileNav}
                    >
                      <div className="c-btn__inner">
                        <ViewToolsIcon
                          className="c-primary-nav__icon"
                          title={TextOnly('viewTools')}
                        />
                        <Text tid="viewTools" />
                      </div>
                    </NavLink>
                  </li>

                  <li className="c-primary-nav__item">
                    <NavLink
                      className={({ isActive }) =>
                        'c-primary-nav__link' + (isActive ? ' is-active' : '')
                      }
                      to="/viewAgreements"
                      onClick={toggleShowMobileNav}
                    >
                      <div className="c-btn__inner">
                        <ViewAgreementsIcon
                          className="c-primary-nav__icon"
                          title={TextOnly('viewAgreements')}
                        />
                        <Text tid="viewAgreements" />
                      </div>
                    </NavLink>
                  </li>

                  <li className="c-primary-nav__item">
                    <NavLink
                      className={({ isActive }) =>
                        'c-primary-nav__link' + (isActive ? ' is-active' : '')
                      }
                      to={'/statsAndTrends'}
                      onClick={toggleShowMobileNav}
                    >
                      <div className="c-btn__inner">
                        <ViewReportsIcon
                          className="c-primary-nav__icon"
                          title={TextOnly('statsAndTrends')}
                        />
                        <Text tid="statsAndTrends" />
                      </div>
                    </NavLink>
                  </li>

                  <li className="c-primary-nav__item">
                    <NavLink
                      className={({ isActive }) =>
                        'c-primary-nav__link' + (isActive ? ' is-active' : '')
                      }
                      to="/companyProfile"
                      onClick={toggleShowMobileNav}
                    >
                      <div className="c-btn__inner">
                        <ManageUsersIcon
                          className="c-primary-nav__icon"
                          title={vendorName}
                        />
                        {vendorName}
                      </div>
                    </NavLink>
                  </li>

                  <li className="c-primary-nav__item u-text-transform-initial">
                    <NavLink
                      className={({ isActive }) =>
                        'c-primary-nav__link' + (isActive ? ' is-active' : '')
                      }
                      to="/userProfile"
                      onClick={toggleShowMobileNav}
                    >
                      <div className="c-btn__inner">
                        <UserProfileIcon
                          className="c-primary-nav__icon"
                          title={TextOnly('userProfile')}
                        />
                        {userName}
                      </div>
                    </NavLink>
                  </li>
                </ul>
              </nav>
            ) : (
              <nav className="c-primary-nav" role="navigation">
                <ul className="c-primary-nav__list">
                  <li className="c-primary-nav__item">
                    <NavLink
                      className={({ isActive }) =>
                        'c-primary-nav__link' + (isActive ? 'is-active' : '')
                      }
                      to="/viewAgreements"
                      onClick={toggleShowMobileNav}
                    >
                      <div className="c-btn__inner">
                        <ManageUsersIcon
                          className="c-primary-nav__icon"
                          title={TextOnly('viewAgreements')}
                        />
                        <Text tid="viewAgreements" />
                      </div>
                    </NavLink>
                  </li>

                  <li className="c-primary-nav__item">
                    <NavLink
                      className={({ isActive }) =>
                        'c-primary-nav__link' + (isActive ? 'is-active' : '')
                      }
                      to={'/statsAndTrends'}
                      onClick={toggleShowMobileNav}
                    >
                      <div className="c-btn__inner">
                        <ViewReportsIcon
                          className="c-primary-nav__icon"
                          title={TextOnly('statsAndTrends')}
                        />
                        <Text tid="statsAndTrends" />
                      </div>
                    </NavLink>
                  </li>

                  <li className="c-primary-nav__item">
                    <NavLink
                      className={({ isActive }) =>
                        'c-primary-nav__link' + (isActive ? ' is-active' : '')
                      }
                      to="/companyProfile"
                      onClick={toggleShowMobileNav}
                    >
                      <div className="c-btn__inner">
                        <ManageUsersIcon
                          className="c-primary-nav__icon"
                          title={vendorName}
                        />
                        {vendorName}
                      </div>
                    </NavLink>
                  </li>
                  <li className="c-primary-nav__item u-text-transform-initial">
                    <NavLink
                      className={({ isActive }) =>
                        'c-primary-nav__link' + (isActive ? 'is-active' : '')
                      }
                      to="/userProfile"
                      onClick={toggleShowMobileNav}
                    >
                      <div className="c-btn__inner">
                        <UserProfileIcon
                          className="c-primary-nav__icon"
                          title={TextOnly('userProfile')}
                        />
                        {userName}
                      </div>
                    </NavLink>
                  </li>
                </ul>
              </nav>
            )}
          </div>
          <ul className="c-sidebar__links">
            <li>
              <a
                className="c-sidebar__link c-btn-light"
                href="https://info.autoauth.com/contact/"
                target="_blank"
                rel="noopener noreferrer"
                role="button"
              >
                <div className="c-btn__inner">
                  <i className="c-btn__icon fal fa-question-circle" />
                  <Text tid="support" />
                </div>
              </a>
            </li>
            <li>
              <button className="c-sidebar__link c-btn-light" onClick={logout}>
                <div className="c-btn__inner">
                  <i className="c-btn__icon fal fa-sign-out" />
                  <Text tid="logout" />
                </div>
              </button>
            </li>
          </ul>
        </div>
      </div>
    );

  return <div className="c-sidebar">{/* empty sidebar */}</div>;
}
