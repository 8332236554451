import PapaParse from 'papaparse';
import _ from 'underscore';
import { languageOptions } from '../libs/languages.js';
import { TextOnly } from '../components/Text';

// IE Polyfill
if (typeof Object.assign != 'function') {
  Object.assign = function (target) {
    if (target == null) {
      throw new TypeError('Cannot convert undefined or null to object');
    }

    target = Object(target);
    for (var index = 1; index < arguments.length; index++) {
      var source = arguments[index];
      if (source != null) {
        for (var key in source) {
          if (Object.prototype.hasOwnProperty.call(source, key)) {
            target[key] = source[key];
          }
        }
      }
    }
    return target;
  };
}

export function fieldChangeCase(
  self,
  eventTarget,
  targetCase,
  whitespace = true
) {
  let field = document.getElementById(eventTarget.id);
  let cursorLocation = eventTarget.selectionStart;
  let currentValue = field.value;
  let newValue;
  if (targetCase === 'lower') {
    newValue = currentValue.toLowerCase();
  } else {
    newValue = currentValue.toUpperCase();
  }
  if (!whitespace) {
    const reg = new RegExp(/^(\S*)$/);
    if (reg.test(newValue) === true) {
      self.setState({
        [eventTarget.id]: newValue,
      });
      field.value = newValue;
    }
  } else {
    self.setState({
      [eventTarget.id]: newValue,
    });
    field.value = newValue;
  }

  field.setSelectionRange(cursorLocation, cursorLocation);
}

export function formatUsers(users) {
  return users ? users.toString() : '';
}

export function strip(str) {
  return str.replace(/^\s+|\s+$/g, '');
}

export function idValidate(id) {
  let idRegex = new RegExp('^([a-zA-Z0-9]{0,40})$');
  const trimmedId = id.trim();
  return typeof trimmedId === 'string' && idRegex.test(trimmedId);
}

export function passwordValidate(password) {
  let strongRegex = new RegExp(
    '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[-~_`|!@#$%^&*+.,?/(){}=:;"\'<>[\\]\\\\])(?=.{8,})'
  );
  const trimmedPass = password.trim();
  return (
    typeof trimmedPass === 'string' &&
    trimmedPass.length <= 50 &&
    strongRegex.test(trimmedPass)
  );
}

export function usernameValidate(username) {
  let usernameRegex = new RegExp('^([a-zA-Z]{1})([a-zA-Z0-9.]{7,})$');
  const trimmedUsername = username.trim();
  return (
    typeof trimmedUsername === 'string' &&
    usernameRegex.test(trimmedUsername) &&
    trimmedUsername.length <= 50
  );
}

export function nameValidate(name) {
  let nameRegex = new RegExp("^([a-zA-Z0-9- .'`‘’]+)$");
  const trimmedName = name.trim();
  return (
    typeof trimmedName === 'string' &&
    nameRegex.test(trimmedName) &&
    trimmedName.length <= 50
  );
}

export function isValidEmail(email) {
  // the internet told me this matches 99% of email addresses
  let re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  let lowerEmail = email.toLowerCase();
  const trimmedEmail = lowerEmail.trim();
  return (
    typeof trimmedEmail === 'string' &&
    re.test(trimmedEmail) &&
    trimmedEmail.length <= 100
  );
}

export function isValidLanguage(lang) {
  const languages = Object.keys(languageOptions);
  return languages.includes(lang);
}

export function isValidRole(role) {
  // the internet told me this matches 99% of email addresses

  return ['USER', 'MANAGER', 'ADMIN'].includes(role);
}

export function toolSerialNoValidate(serialNo) {
  let toolSerialNoRegex = new RegExp('^[a-zA-Z0-9-_/.]+$');
  const trimmedSerialNo = serialNo.trim();
  return (
    typeof trimmedSerialNo === 'string' &&
    toolSerialNoRegex.test(trimmedSerialNo) &&
    trimmedSerialNo.length <= 100
  );
}

// Convert string from csv file to array of objects
export function arrToObject(csvStr) {
  let data = PapaParse.parse(csvStr);
  let columns = _.first(data.data);
  let lines = _.rest(data.data);
  let returnArr = [];
  lines.forEach((line) => {
    let nextRec = {};
    for (let i = 0; i < line.length; i++) {
      nextRec[columns[i]] = line[i];
    }
    returnArr.push(nextRec);
  });
  return returnArr;
}

// Detect Windows OS
function detectWindowsOS(osStr) {
  switch (osStr) {
    case 'Windows 95':
    case 'Win95':
    case 'Windows_95':
      return 'Windows 95';

    case 'Windows 98':
    case 'Win98':
      return 'Windows 98';

    case 'Windows NT 5.0':
    case 'Windows 2000':
      return 'Windows 2000';

    case 'Windows NT 5.1':
    case 'Windows XP':
      return 'Windows XP';

    case 'Windows NT 5.2':
      return 'Windows Server 2003';

    case 'Windows NT 6.0':
      return 'Windows Vista';

    case 'Windows NT 6.1':
      return 'Windows 7';

    case 'Windows NT 6.2':
    case 'Windows NT 6.3':
    case 'WOW64':
      return 'Windows 8';

    case 'Windows 10.0':
    case 'Windows NT 10.0':
      return 'Windows 10';

    case 'Windows NT 4.0':
    case 'WinNT4.0':
    case 'WinNT':
    case 'Windows NT':
      return 'Windows NT 4.0';

    case 'Windows ME':
      return 'Windows ME';

    case 'OpenBSD':
      return 'Open BSD';

    case 'SunOS':
      return 'Sun OS';

    case 'Linux':
    case 'X11':
      return 'Linux';

    default:
      return osStr;
  }
}

/**
 * detect IE
 * returns version of IE or false, if browser is not Internet Explorer
 */
function detectIE() {
  let ua = window.navigator.userAgent;

  // Test values; Uncomment to check result …

  // IE 10
  // ua = 'Mozilla/5.0 (compatible; MSIE 10.0; Windows NT 6.2; Trident/6.0)';

  // IE 11
  // ua = 'Mozilla/5.0 (Windows NT 6.3; Trident/7.0; rv:11.0) like Gecko';

  // Edge 12 (Spartan)
  // ua = 'Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/39.0.2171.71 Safari/537.36 Edge/12.0';

  // Edge 13
  // ua = 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/46.0.2486.0 Safari/537.36 Edge/13.10586';

  let msie = ua.indexOf('MSIE ');
  if (msie > 0) {
    // IE 10 or older => return version number
    return {
      name: 'Internet Explorer',
      version: parseFloat(ua.substring(msie + 5)),
    };
  }

  let trident = ua.indexOf('Trident/');
  if (trident > 0) {
    // IE 11 => return version number
    let rv = ua.indexOf('rv:');
    return {
      name: 'Internet Explorer',
      version: parseFloat(ua.substring(rv + 3)),
    };
  }

  let edge = ua.indexOf('Edge/');
  if (edge > 0) {
    // Edge (IE 12+) => return version number
    return {
      name: 'Edge',
      version: parseFloat(ua.substring(edge + 5)),
    };
  }

  // other browser
  return false;
}

export function systemInfo() {
  let objappVersion = navigator.appVersion;
  let objAgent = navigator.userAgent;
  let objbrowserName = navigator.appName;
  let objfullVersion = '' + parseFloat(objappVersion);
  let objBrMajorVersion = parseInt(objappVersion, 10);
  let objOffsetName, objOffsetVersion, ix;
  let hostOSStart = objAgent.indexOf('(') + 1;
  let hostOSEnd = objAgent.indexOf(')');
  let tmpOSStr = objAgent.substring(hostOSStart, hostOSEnd);

  let objHostOS;
  if (tmpOSStr.indexOf('Mac OS') > 0) {
    objHostOS = tmpOSStr
      .substring(tmpOSStr.indexOf(';') + 1)
      .replace(/_/g, '.');
  } else {
    objHostOS = detectWindowsOS(tmpOSStr.substring(0, tmpOSStr.indexOf(';')));
  }

  let browser = detectIE();

  if (browser) {
    objbrowserName = browser.name;
    objfullVersion = browser.version;
  } else {
    // In Chrome
    if ((objOffsetVersion = objAgent.indexOf('Chrome')) !== -1) {
      objbrowserName = 'Chrome';
      objfullVersion = objAgent.substring(objOffsetVersion + 7);
    }
    // In Firefox
    else if ((objOffsetVersion = objAgent.indexOf('Firefox')) !== -1) {
      objbrowserName = 'Firefox';
      objfullVersion = objAgent.substring(objOffsetVersion + 8);
      if (tmpOSStr.indexOf('Mac OS') > 0) {
        objHostOS = navigator.oscpu;
      } else {
        objHostOS = detectWindowsOS(
          navigator.oscpu.substring(0, navigator.oscpu.indexOf(';'))
        );
      }
    }
    // In Safari
    else if ((objOffsetVersion = objAgent.indexOf('Safari')) !== -1) {
      objbrowserName = 'Safari';
      objfullVersion = objAgent.substring(objOffsetVersion + 7);
      if ((objOffsetVersion = objAgent.indexOf('Version')) !== -1)
        objfullVersion = objAgent.substring(objOffsetVersion + 8);
    }
    // For other browser "name/version" is at the end of userAgent
    else if (
      (objOffsetName = objAgent.lastIndexOf(' ') + 1) <
      (objOffsetVersion = objAgent.lastIndexOf('/'))
    ) {
      objbrowserName = objAgent.substring(objOffsetName, objOffsetVersion);
      objfullVersion = objAgent.substring(objOffsetVersion + 1);
      if (objbrowserName.toLowerCase() === objbrowserName.toUpperCase()) {
        objbrowserName = navigator.appName;
      }
    }
    // trimming the fullVersion string at semicolon/space if present
    if ((ix = objfullVersion.indexOf(';')) !== -1)
      objfullVersion = objfullVersion.substring(0, ix);
    if ((ix = objfullVersion.indexOf(' ')) !== -1)
      objfullVersion = objfullVersion.substring(0, ix);

    objBrMajorVersion = parseInt('' + objfullVersion, 10);
    if (isNaN(objBrMajorVersion)) {
      objfullVersion = '' + parseFloat(navigator.appVersion);
    }
  }

  return {
    hostOS: objHostOS,
    browserName: objbrowserName,
    browserVersion: objfullVersion,
  };
}

export function generateFilterRegex(filterStr) {
  const filterRegexStr = escapeRegExp(filterStr.trim());
  const filterArr = filterRegexStr.split(' ').filter((f) => f !== '');
  const filter = filterArr.reduce((f1, f2) => f1 + '|' + f2);
  const regexStr = new RegExp('(?:' + filter + ')', 'i');
  return regexStr;
}

function escapeRegExp(string) {
  return string.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

export function formatDateTime(time, dateOptions) {
  let date = new Date(time);
  let options = { hour12: false };
  if (dateOptions?.utc) {
    options.timeZone = 'UTC';
    options.timeZoneName = 'short';
  }
  if (dateOptions?.windowWidth && dateOptions?.windowWidth < 600) {
    if (!dateOptions?.utc) {
      options.dateStyle = 'short';
      options.timeStyle = 'short';
    }
    return date.toLocaleString(TextOnly('hl'), options);
  } else if (dateOptions?.windowWidth && dateOptions?.windowWidth < 1400) {
    if (!dateOptions?.utc) {
      options.dateStyle = 'medium';
      options.timeStyle = 'medium';
    }
    return date.toLocaleString(TextOnly('hl'), options);
  } else {
    if (!dateOptions?.utc) {
      options.dateStyle = 'long';
      options.timeStyle = 'long';
    }
    return date.toLocaleString(TextOnly('hl'), options);
  }
}

export function formatDateTime2Lines(time, dateOptions) {
  let date = new Date(time);
  let options = { hour12: false };
  if (dateOptions?.utc) {
    options.timeZone = 'UTC';
  }
  if (dateOptions?.windowWidth && dateOptions?.windowWidth < 600) {
    if (!dateOptions?.utc) {
      options.dateStyle = 'short';
      options.timeStyle = 'short';
    }
  } else if (dateOptions?.windowWidth && dateOptions?.windowWidth < 1400) {
    if (!dateOptions?.utc) {
      options.dateStyle = 'medium';
      options.timeStyle = 'medium';
    }
  }
  const dateStr = date.toLocaleDateString(TextOnly('hl'), options);
  options.timeZoneName = 'short';
  const timeStr = date.toLocaleTimeString(TextOnly('hl'), options);
  return { date: dateStr, time: timeStr };
}

export function sortUsers(a, b) {
  let aName = a.firstName + a.lastName;
  let bName = b.firstName + b.lastName;

  return aName.toLowerCase() < bName.toLowerCase() ? -1 : 1;
}

export function sortTools(a, b) {
  let aName = a.toolManufacturer + a.toolModel + a.toolSerial;
  let bName = b.toolManufacturer + b.toolModel + b.toolSerial;

  return aName.toLowerCase() < bName.toLowerCase() ? -1 : 1;
}

export function getDev() {
  const isDev = process.env.REACT_APP_IS_DEVELOPER;
  const isDevTodo = isDev === 'true' ? 'aa-todo' : null;
  return {
    isDev,
    isDevTodo,
  };
}

export const pipe =
  (...fns) =>
  (x) =>
    fns.reduce((v, f) => f(v), x);
