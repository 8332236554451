import React, { useState } from 'react';
import { Text, TextOnly } from '../components/Text';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import moment from 'moment';
import { Loading } from '../components/Loading';
import { Header } from '../components/Header';
import { BarWidget } from '../components/Reports/BarWidget';
import { StatsWidget } from '../components/Reports/StatsWidget';
import LoaderButton from '../components/LoaderButton';
import { useQuery } from 'react-query';
import { getStats } from '../libs/db-lib';
import { STAT_TYPE, TIME_GRANULARITY } from '../CONSTANTS';

interface Range {
  label: string;
  timeGranularity: TIME_GRANULARITY;
  timeSpecification: string;
}
const ranges = getSpeedDial(new Date());

export const StatsAndTrends = () => {
  const [timeGranularity, setTimeGranularity] = useState<TIME_GRANULARITY>(
    TIME_GRANULARITY.YEAR
  );
  const currentDate = new Date();
  const [timeSpecification, setTimeSpecification] = useState(
    currentDate.getFullYear().toString()
  );

  const {
    data: statsData,
    isLoading: isLoadingStats,
    refetch: refetchStats,
  } = useQuery(
    ['getStats', timeGranularity, timeSpecification],
    () =>
      getStats({
        timeGranularity,
        timeSpecification,
      }),
    {
      onSuccess: (data) => {},
    }
  );

  const handleChangeRange = (range: Range) => {
    setTimeGranularity(range.timeGranularity);
    setTimeSpecification(range.timeSpecification);
    refetchStats();
  };

  if (isLoadingStats) return <Loading />;

  return (
    <>
      <Header
        context={TextOnly('viewReports')}
        title={TextOnly('statsAndTrends')}
      />

      <div className="c-box--outline u-margin-bottom-large">
        <h2 className="c-box__title u-padding-bottom">
          <Text tid="dashboardControls" />
        </h2>
        <div className="l-flex">
          {ranges.map((range) => (
            <LoaderButton
              key={range.label}
              type="button"
              isLoading={
                isLoadingStats &&
                timeGranularity === range.timeGranularity &&
                timeSpecification === range.timeSpecification
              }
              onClick={() => handleChangeRange(range)}
              text={range.label}
              loadingText={range.label}
              className={`${
                timeGranularity === range.timeGranularity &&
                timeSpecification === range.timeSpecification
                  ? 'c-btn'
                  : 'c-btn-outline'
              } u-margin-right`}
            />
          ))}
        </div>
      </div>

      <div className="l-grid-row-stats-trends u-margin-bottom-large u-margin-top-xlarge">
        {(statsData?.length ?? 0) > 0
          ? statsData?.map((widget) => {
              if (widget.type === STAT_TYPE.BAR) {
                return (
                  <BarWidget
                    key={widget.title}
                    title={widget?.title || ''}
                    bars={widget?.bars || []}
                    topOfRange={widget?.topOfRange || 0}
                  />
                );
              } else if (widget.type === STAT_TYPE.NUMBER) {
                return (
                  <StatsWidget
                    key={widget.chartID}
                    stats={widget?.stats || []}
                  />
                );
              } else {
                return null;
              }
            })
          : null}
      </div>
    </>
  );
};

export function getSpeedDial(now: Date) {
  const today = moment(now);
  const yesterday = moment(now).subtract(1, 'day');
  const month = moment(now);
  const lastMonth = moment(now).subtract(1, 'month');
  const year = moment(now).year();
  const lastYear = moment(now).subtract(1, 'year').year();

  return [
    {
      label: TextOnly('yesterday'),
      timeGranularity: TIME_GRANULARITY.DAY,
      timeSpecification: `${yesterday.format('YYYY_MM_DD')}`,
    },
    {
      label: TextOnly('today'),
      timeGranularity: TIME_GRANULARITY.DAY,
      timeSpecification: `${today.format('YYYY_MM_DD')}`,
    },
    {
      label: TextOnly('lastMonth'),
      timeGranularity: TIME_GRANULARITY.MONTH,
      timeSpecification: `${lastMonth.format('YYYY_MM')}`,
    },
    {
      label: TextOnly('thisMonth'),
      timeGranularity: TIME_GRANULARITY.MONTH,
      timeSpecification: `${month.format('YYYY_MM')}`,
    },
    {
      label: TextOnly('lastYear'),
      timeGranularity: TIME_GRANULARITY.YEAR,
      timeSpecification: `${lastYear}`,
    },
    {
      label: TextOnly('thisYear'),
      timeGranularity: TIME_GRANULARITY.YEAR,
      timeSpecification: `${year}`,
    },
  ];
}
