import { Dialog } from '@reach/dialog';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { FilterPills } from '../components/FilterPills';
import { Header } from '../components/Header';
import LoaderButton from '../components/LoaderButton';
import { Loading } from '../components/Loading';
import MyReactTable from '../components/ReactTable';
import { TextOnly, Text, LangDictKey } from '../components/Text';
import ToggleButton from '../components/ToggleButton';
import { VENDOR_STATE, VENDOR_TYPE, VENDOR_TYPE_LIST } from '../CONSTANTS';
import { Typeahead } from 'react-bootstrap-typeahead';
import {
  CreateVendorParams,
  SetVendorStateParams,
  siteAdminCreateVendor,
  getVendors,
  getVendorsResponse,
  siteAdminSetVendorState,
  updateVendorInfo,
  UpdateVendorInfoParams,
} from '../libs/db-lib';
import { Vendor } from '../types';

export interface VendorTableView {
  id: string;
  vendorName: string;
  vendorType: string;
  location: string;
  vendorContactPhone: string;
  vendorState: React.Component | VENDOR_STATE;
  crud: React.Component | JSX.Element;
}

export enum MANAGE_VENDORS_DIALOGS {
  ADD_VENDOR = 'ADD_VENDOR',
  EDIT_VENDOR = 'EDIT_VENDOR',
  FILTER = 'FILTER',
}

export const ManageVendors = () => {
  const [search, setSearch] = useState<string>('');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const [currentDialog, setCurrentDialog] =
    useState<MANAGE_VENDORS_DIALOGS | null>(null);
  const [vendorTypeFilter, setVendorTypeFilter] = useState<VENDOR_TYPE[]>([]);
  const [statusFilter, setStatusFilter] = useState<VENDOR_STATE[]>([]);
  const [selectedVendor, setSelectedVendor] =
    useState<UpdateVendorInfoParams | null>(null);

  const siteAdminGetVendorList = (data: getVendorsResponse) => {
    const vendorList = data?.vendorList?.map((vendor: Vendor) => {
      return {
        id: vendor.vendorID,
        vendorName: vendor.vendorName,
        vendorType: vendor.vendorType,
        location: `${
          vendor.vendorContactCity ? `${vendor.vendorContactCity},` : ''
        } ${vendor.vendorContactState ?? ''}`,
        vendorContactPhone: vendor.vendorContactPhone,
        vendorState: vendor.vendorState,
        crud: (
          <div className="l-flex-end l-flex-auto">
            <button
              className="c-btn-icon"
              onClick={() => {
                setSelectedVendor(vendor);
                toggleEditVendorDialog();
              }}
              disabled={vendor.vendorState !== VENDOR_STATE.ACTIVE}
            >
              <div className="c-btn__inner">
                <i className="c-btn__icon fal fa-edit" />
              </div>
            </button>
          </div>
        ),
      };
    });
    return vendorList;
  };

  // #region 1 queries
  const {
    data: vendorsData,
    isLoading: isLoadingVendors,
    refetch: refetchVendors,
  } = useQuery('getVendors', getVendors);

  const vendors = siteAdminGetVendorList(vendorsData || { vendorList: [] });

  // #endregion 1

  // #region 2 mutations
  const siteAdminCreateVendorMutation = useMutation(
    (vendorFields: CreateVendorParams) => siteAdminCreateVendor(vendorFields),
    {
      onSuccess: (data: any) => {
        if (data?.error) {
          toast.error(data.error, {
            autoClose: false,
            containerId: 'standard',
          });
          return;
        }
        toast.success(TextOnly('createVendorSuccess'), {
          containerId: 'standard',
        });
        addVendorFormik.resetForm();
        setCurrentDialog(null);
        refetchVendors();
      },
      onError: (error: Error) => {
        toast.error(error.message, {
          autoClose: false,
          containerId: 'standard',
        });
      },
    }
  );

  const updateVendorInfoMutation = useMutation<
    { error?: string },
    Error,
    UpdateVendorInfoParams
  >((vendorFields) => updateVendorInfo(vendorFields), {
    onSuccess: (data) => {
      if (data?.error) {
        toast.error(data.error, {
          autoClose: false,
          containerId: 'standard',
        });
        return;
      }
      toast.success(TextOnly('updateSuccess'), {
        containerId: 'standard',
      });
      editVendorFormik.resetForm();
      setCurrentDialog(null);
      refetchVendors();
    },
    onError: (error) => {
      toast.error(error.message, {
        autoClose: false,
        containerId: 'standard',
      });
    },
  });

  const siteAdminSetVendorStateMutation = useMutation<
    { error?: string },
    Error,
    SetVendorStateParams
  >((vendorToUpdateFields) => siteAdminSetVendorState(vendorToUpdateFields), {
    onSuccess: (data) => {
      if (data?.error) {
        toast.error(data.error, {
          autoClose: false,
          containerId: 'standard',
        });
        return;
      }
      toast.success(TextOnly('updateSuccess'), {
        containerId: 'standard',
      });
      refetchVendors();
    },
    onError: (error) => {
      toast.error(error.message, {
        autoClose: false,
        containerId: 'standard',
      });
    },
  });

  // #endregion 2

  // #region 3 columns
  const columns = [
    {
      Header: 'Name',
      accessor: 'vendorName',
    },
    {
      Header: 'Type',
      accessor: 'vendorType',
    },
    {
      Header: 'Location',
      accessor: 'location',
    },
    {
      Header: 'Phone',
      accessor: 'vendorContactPhone',
    },
    {
      Header: 'Status',
      accessor: 'vendorState',
      filter: 'equals',
      minWidth: 150,
      Cell: ({ row: { original } }: any) => (
        <ToggleButton
          isActive={original.vendorState === VENDOR_STATE.ACTIVE}
          title="set vendor active/inactive"
          handleToggle={() => {
            siteAdminSetVendorStateMutation.mutate({
              vendorID: original.id,
              vendorState:
                original.vendorState === VENDOR_STATE.ACTIVE
                  ? VENDOR_STATE.INACTIVE
                  : VENDOR_STATE.ACTIVE,
            });
          }}
        />
      ),
    },
    {
      Header: '',
      accessor: 'crud',
      disableSortBy: true,
    },
  ];

  // #endregion 3

  // #region 4 formik
  const filterFormik = useFormik({
    initialValues: {
      vendorTypeFilter: vendorTypeFilter,
      statusFilter: statusFilter,
    },
    onSubmit: async (values) => {
      setVendorTypeFilter(values.vendorTypeFilter);
      setStatusFilter(values.statusFilter);
      toggleFilterDialog();
    },
    enableReinitialize: true,
  });

  const addVendorFormik = useFormik({
    initialValues: {
      vendorName: '',
      vendorType: VENDOR_TYPE.OEM,
    },
    onSubmit: async (values) => {
      siteAdminCreateVendorMutation.mutate({
        vendorName: values.vendorName,
        vendorType: values.vendorType,
      });
    },
  });

  const editVendorFormik = useFormik({
    initialValues: {
      vendorName: selectedVendor?.vendorName || '',
      vendorContactStreet: selectedVendor?.vendorContactStreet || '',
      vendorContactCity: selectedVendor?.vendorContactCity || '',
      vendorContactState: selectedVendor?.vendorContactState || '',
      vendorContactZip: selectedVendor?.vendorContactZip || '',
      vendorContactPhone: selectedVendor?.vendorContactPhone || '',
    },
    onSubmit: async (values) => {
      updateVendorInfoMutation.mutate({
        vendorID: selectedVendor?.vendorID,
        vendorName: values.vendorName,
        vendorContactStreet: values.vendorContactStreet,
        vendorContactCity: values.vendorContactCity,
        vendorContactState: values.vendorContactState,
        vendorContactZip: values.vendorContactZip,
        vendorContactPhone: values.vendorContactPhone,
      });
    },
    enableReinitialize: true,
  });

  // #endregion 4

  // #region 5 funcitons

  const toggleFilterDialog = () => {
    currentDialog === MANAGE_VENDORS_DIALOGS.FILTER
      ? setCurrentDialog(null)
      : setCurrentDialog(MANAGE_VENDORS_DIALOGS.FILTER);
  };

  const toggleAddVendorDialog = () => {
    currentDialog === MANAGE_VENDORS_DIALOGS.ADD_VENDOR
      ? setCurrentDialog(null)
      : setCurrentDialog(MANAGE_VENDORS_DIALOGS.ADD_VENDOR);
  };

  const toggleEditVendorDialog = () => {
    currentDialog === MANAGE_VENDORS_DIALOGS.EDIT_VENDOR
      ? setCurrentDialog(null)
      : setCurrentDialog(MANAGE_VENDORS_DIALOGS.EDIT_VENDOR);
  };

  const resetFilters = () => {
    setVendorTypeFilter([]);
    setStatusFilter([]);
    setCurrentDialog(null);
  };
  // #endregion 5
  function handleFilterDelete(field: string, value: string) {
    if (field === 'userType') {
      setVendorTypeFilter([]);
    } else if (field === 'userState') {
      setStatusFilter([]);
    }
  }
  const userTypeFilter =
    vendorTypeFilter.length > 0
      ? {
          userType: [
            {
              key: vendorTypeFilter[0],
              value: vendorTypeFilter[0],
              label: vendorTypeFilter[0],
              pillLabel: vendorTypeFilter[0],
            },
          ],
        }
      : null;

  const userStateFilter =
    statusFilter.length > 0
      ? {
          userState: [
            {
              key: statusFilter[0],
              value: statusFilter[0],
              label: statusFilter[0],
              pillLabel: statusFilter[0],
            },
          ],
        }
      : null;

  const filters = {
    ...userTypeFilter,
    ...userStateFilter,
  };

  if (isLoadingVendors) {
    return <Loading />;
  }

  return (
    <>
      <Header title={TextOnly('manageVendors')} />
      <div className="l-container">
        <div className="l-flex-wrap">
          <div className="l-flex-wrap">
            <div className="u-margin-right">
              <button className="c-btn" onClick={toggleAddVendorDialog}>
                <div className="c-btn__inner">
                  <i className="c-btn__icon fal fa-plus" />
                  <Text tid="addVendors" />
                </div>
              </button>
            </div>
          </div>
          <div className="l-flex-between">
            <button
              className="c-btn-outline u-margin-right"
              onClick={toggleFilterDialog}
            >
              <div className="c-btn__inner">
                <Text tid="filterBy" />
                <i className="c-btn__icon fas fa-caret-down" />
              </div>
            </button>
            <div className="c-field u-margin-bottom-none">
              <label htmlFor="search" className="c-field__label u-is-vishidden">
                <Text tid="search" />
              </label>
              <input
                type="text"
                id="search"
                maxLength={50}
                className="c-input"
                placeholder={TextOnly('search')}
                value={search}
                onChange={handleChange}
              />
              <i className="c-field__input-icon fal fa-search" />
            </div>
          </div>
        </div>

        {Object.keys(filters).length ? (
          <div className="l-flex-wrap u-margin-top-large">
            <div className="l-flex-wrap">
              <button
                className="c-btn-link-text u-margin-right"
                onClick={resetFilters}
              >
                <div className="c-btn__inner">
                  <Text tid="resetFilters" />
                </div>
              </button>
              <FilterPills
                filters={filters}
                onFilterClick={handleFilterDelete}
              />
            </div>
          </div>
        ) : null}

        <div className="u-margin-top-large">
          <MyReactTable
            columns={columns}
            data={vendors}
            globalFilter={search}
            filter={[
              { columnId: 'vendorType', filter: vendorTypeFilter[0] },
              { columnId: 'vendorState', filter: statusFilter[0] },
            ]}
            tableName="manageVendors"
          />
        </div>
      </div>
      <Dialog
        isOpen={currentDialog === MANAGE_VENDORS_DIALOGS.FILTER}
        onDismiss={toggleFilterDialog}
        className="c-modal-slider"
        aria-label={TextOnly('filters')}
      >
        <button
          className="c-btn-icon c-modal-slider__close"
          onClick={toggleFilterDialog}
        >
          <div className="c-btn__inner">
            <i className="c-btn__icon fa fa-times" />
          </div>
        </button>
        <h1 className="c-modal__heading">
          <Text tid="filters" />
        </h1>

        <div className="c-modal__body">
          <form onSubmit={filterFormik.handleSubmit}>
            <div className="l-container-sm">
              <div className="c-field">
                <label className="c-field__label">
                  <Text tid="vendorType" />
                </label>
                <Typeahead
                  id="vendorTypeFilter"
                  placeholder="Select Partner Type"
                  options={VENDOR_TYPE_LIST}
                  selected={filterFormik.values.vendorTypeFilter}
                  onChange={(selected) => {
                    filterFormik.setFieldValue('vendorTypeFilter', selected);
                  }}
                  positionFixed
                />
              </div>
              <div className="c-field">
                <label className="c-field__label">
                  <Text tid="status" />
                </label>
                <Typeahead
                  id="statusFilter"
                  placeholder="Select Partner Status"
                  options={[VENDOR_STATE.ACTIVE, VENDOR_STATE.INACTIVE]}
                  selected={filterFormik.values.statusFilter}
                  onChange={(selected) => {
                    filterFormik.setFieldValue('statusFilter', selected);
                  }}
                  positionFixed
                />
              </div>
              <div className="c-field l-flex-between">
                <button
                  className="c-btn-outline u-margin-right"
                  type="button"
                  onClick={resetFilters}
                >
                  <Text tid="resetFilters" />
                </button>
                <button className="c-btn" type="submit">
                  <Text tid="applyFilters" />
                </button>
              </div>
            </div>
          </form>
        </div>
      </Dialog>
      <Dialog
        isOpen={currentDialog === MANAGE_VENDORS_DIALOGS.ADD_VENDOR}
        onDismiss={toggleAddVendorDialog}
        className="c-modal-slider"
        aria-label={TextOnly('addVendor')}
      >
        <button
          className="c-btn-icon c-modal-slider__close"
          onClick={toggleAddVendorDialog}
        >
          <div className="c-btn__inner">
            <i className="c-btn__icon fa fa-times" />
          </div>
        </button>
        <h1 className="c-modal__heading">
          <Text tid="addVendor" />
        </h1>

        <div className="c-modal__body">
          <form onSubmit={addVendorFormik.handleSubmit}>
            <div className="l-container-sm">
              <div className="c-field">
                <label className="c-field__label">Partner Type</label>
                <div className="c-select">
                  <select
                    id="vendorType"
                    name="vendorType"
                    value={addVendorFormik.values.vendorType}
                    onChange={(e) => {
                      addVendorFormik.setFieldValue(
                        'vendorType',
                        e.target.value
                      );
                    }}
                  >
                    {VENDOR_TYPE_LIST.map((vendorType) => (
                      <option key={vendorType} value={vendorType}>
                        {TextOnly(vendorType as LangDictKey)}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="c-field">
                <label htmlFor="vendorName" className="c-field__label">
                  <Text tid="vendorName" />:
                </label>
                <input
                  id="vendorName"
                  name="vendorName"
                  type="text"
                  maxLength={50}
                  className={`c-input`}
                  placeholder={TextOnly('vendorName')}
                  value={addVendorFormik.values.vendorName}
                  onChange={addVendorFormik.handleChange}
                />
                {addVendorFormik.errors.vendorName ? (
                  <div className="c-field__error">
                    <Text tid="vendorNameHint" />
                  </div>
                ) : null}
              </div>

              <LoaderButton
                type="submit"
                id="submit-button"
                disabled={!addVendorFormik.isValid}
                isLoading={
                  addVendorFormik.isSubmitting ||
                  siteAdminCreateVendorMutation.isLoading
                }
                text={TextOnly('addVendor')}
                loadingText={TextOnly('adding')}
              />
            </div>
          </form>
        </div>
      </Dialog>
      <Dialog
        isOpen={currentDialog === MANAGE_VENDORS_DIALOGS.EDIT_VENDOR}
        onDismiss={toggleEditVendorDialog}
        className="c-modal-slider"
        aria-label={TextOnly('editVendor')}
      >
        <button
          className="c-btn-icon c-modal-slider__close"
          onClick={toggleEditVendorDialog}
        >
          <div className="c-btn__inner">
            <i className="c-btn__icon fa fa-times" />
          </div>
        </button>
        <h1 className="c-modal__heading">
          <Text tid="editVendor" />
        </h1>

        <div className="c-modal__body">
          <form onSubmit={editVendorFormik.handleSubmit}>
            <div className="l-container-sm">
              <div className="c-field">
                <label htmlFor="vendorName" className="c-field__label">
                  <Text tid="vendorName" />:
                </label>
                <input
                  id="vendorName"
                  name="vendorName"
                  type="text"
                  maxLength={50}
                  className={`c-input`}
                  placeholder={TextOnly('vendorName')}
                  value={editVendorFormik.values.vendorName}
                  onChange={editVendorFormik.handleChange}
                />
                {editVendorFormik.errors.vendorName ? (
                  <div className="c-field__error">
                    <Text tid="vendorNameHint" />
                  </div>
                ) : null}
              </div>

              <div className="c-field">
                <label htmlFor="vendorContactStreet" className="c-field__label">
                  <Text tid="vendorContactStreet" />:
                </label>
                <input
                  id="vendorContactStreet"
                  name="vendorContactStreet"
                  type="text"
                  maxLength={50}
                  className={`c-input`}
                  placeholder={TextOnly('vendorContactStreet')}
                  value={editVendorFormik.values.vendorContactStreet}
                  onChange={editVendorFormik.handleChange}
                />
                {editVendorFormik.errors.vendorContactStreet ? (
                  <div className="c-field__error">
                    <Text tid="vendorContactStreetHint" />
                  </div>
                ) : null}
              </div>

              <div className="c-field">
                <label htmlFor="vendorContactCity" className="c-field__label">
                  <Text tid="vendorContactCity" />:
                </label>
                <input
                  id="vendorContactCity"
                  name="vendorContactCity"
                  type="text"
                  maxLength={50}
                  className={`c-input`}
                  placeholder={TextOnly('vendorContactCity')}
                  value={editVendorFormik.values.vendorContactCity}
                  onChange={editVendorFormik.handleChange}
                />
                {editVendorFormik.errors.vendorContactCity ? (
                  <div className="c-field__error">
                    <Text tid="vendorContactCityHint" />
                  </div>
                ) : null}
              </div>

              <div className="l-flex-wrap l-flex-gap">
                <div className={`c-field l-container-xs`}>
                  <label
                    htmlFor="vendorContactState"
                    className="c-field__label"
                  >
                    <Text tid="vendorContactState" />:
                  </label>
                  <input
                    id="vendorContactState"
                    name="vendorContactState"
                    type="text"
                    maxLength={50}
                    className={`u-input-side-by-side c-input`}
                    placeholder={TextOnly('vendorContactState')}
                    value={editVendorFormik.values.vendorContactState}
                    onChange={editVendorFormik.handleChange}
                  />
                  {editVendorFormik.errors.vendorContactState ? (
                    <div className="c-field__error">
                      <Text tid="vendorContactStateHint" />
                    </div>
                  ) : null}
                </div>

                <div className={`c-field l-container-xs`}>
                  <label htmlFor="vendorContactZip" className="c-field__label">
                    <Text tid="vendorContactZip" />:
                  </label>
                  <input
                    id="vendorContactZip"
                    name="vendorContactZip"
                    type="text"
                    maxLength={50}
                    className={`u-input-side-by-side c-input`}
                    placeholder={TextOnly('vendorContactZip')}
                    value={editVendorFormik.values.vendorContactZip}
                    onChange={editVendorFormik.handleChange}
                  />
                  {editVendorFormik.errors.vendorContactZip ? (
                    <div className="c-field__error">
                      <Text tid="vendorContactZipHint" />
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="c-field">
                <label htmlFor="vendorContactPhone" className="c-field__label">
                  <Text tid="vendorContactPhone" />:
                </label>
                <input
                  id="vendorContactPhone"
                  name="vendorContactPhone"
                  type="text"
                  maxLength={50}
                  className={`c-input`}
                  placeholder={TextOnly('vendorContactPhone')}
                  value={editVendorFormik.values.vendorContactPhone}
                  onChange={editVendorFormik.handleChange}
                />
                {editVendorFormik.errors.vendorContactPhone ? (
                  <div className="c-field__error">
                    <Text tid="vendorContactPhoneHint" />
                  </div>
                ) : null}
              </div>
              <LoaderButton
                type="submit"
                id="submit-button"
                disabled={!editVendorFormik.isValid}
                isLoading={
                  editVendorFormik.isSubmitting ||
                  updateVendorInfoMutation.isLoading
                }
                text={TextOnly('update')}
                loadingText={TextOnly('updating')}
              />
            </div>
          </form>
        </div>
      </Dialog>
    </>
  );
};
