import { API } from 'aws-amplify';
import { authStorage, trimObjFields } from './utils-ts';
import config from '../config';
import {
  USER_TYPE,
  VENDOR_STATE,
  VENDOR_TYPE,
  TIME_GRANULARITY,
  CORS_ERROR_MESSAGE,
  DOC_TYPE,
} from '../CONSTANTS';
import {
  Agreement,
  User,
  Vendor,
  Tool,
  Stats,
  InsuranceDocs,
  SupportDocs,
  LicenseAgreement,
  ComprehensiveFees,
  OtherFees,
  AnnualFees,
  AgreementDocument,
} from '../types';

const APIWrap = {
  ...API,
  /**
   * Make a GET request
   * @param {string} apiName  - The api name of the request
   * @param {string} path - The path of the request
   * @param {json} [init] - Request extra params
   * @return {Promise} - A promise that resolves to an object with response status and JSON data, if successful.
   */
  get: (apiName: string, path: string, init: object) =>
    API.get(apiName, path, trimObjFields(init)),
  /**
   * Make a POST request
   * @param {string} apiName  - The api name of the request
   * @param {string} path - The path of the request
   * @param {json} [init] - Request extra params
   * @return {Promise} - A promise that resolves to an object with response status and JSON data, if successful.
   */
  post: (apiName: string, path: string, init: object) =>
    API.post(apiName, path, trimObjFields(init)),
};

export interface HttpResponse<T> extends Response {
  error?: T;
  message?: string;
  errorDesc?: string;
}

export interface HttpError extends Error {
  errorDesc?: string;
}

export async function authFetch(endpoint: string, options?: any) {
  const loginTokens = authStorage.getLoginTokens();
  if (!loginTokens.IdToken) {
    throw new Error('Unauthorized');
  }

  return await fetch(endpoint, {
    ...options,
    headers: {
      ...options?.headers,
      Authorization: loginTokens.IdToken,
      'Content-Type': 'text/plain',
    },
  })
    .then((response: HttpResponse<string>) => {
      if (response.status === 401) {
        throw CORS_ERROR_MESSAGE;
      } else if (!response.ok && response?.errorDesc) {
        throw response.errorDesc;
      } else if (!response.ok && response?.message) {
        throw response.message;
      } else if (!response.ok) {
        throw new Error('Network Error');
      } else {
        return response.json();
      }
    })
    .catch((error: HttpError) => {
      if (error?.errorDesc) {
        throw new Error(error.errorDesc);
      } else if (error?.message) {
        throw new Error(error.message);
      } else {
        throw new Error(error.toString());
      }
    });
}

// const errorRegex = /error/i;

// SITE_ADMIN function IDs
const SITE_ADM_FUNCTION_CREATE_USER = 1;
const SITE_ADM_FUNCTION_SET_USER_STATE = 2;
const SITE_ADM_FUNCTION_GET_USER_LIST = 3;
// const SITE_ADM_FUNCTION_GET_VENDOR_LIST = 4;
const SITE_ADM_FUNCTION_CREATE_VENDOR = 5;
const SITE_ADM_FUNCTION_ADD_AGREEMENT = 6;
const SITE_ADM_FUNCTION_UPDATE_AGREEMENT = 7;
const SITE_ADM_FUNCTION_SET_USER_TYPE = 8;
const SITE_ADM_FUNCTION_SET_VENDOR_STATE = 9;
const SITE_ADM_FUNCTION_UPLOAD_DOCUMENT = 10;
const SITE_ADM_FUNCTION_REMOVE_DOCUMENT = 11;
const SITE_ADM_FUNCTION_UPLOAD_DOCUMENT_WITH_URL = 12;

// OEM_ADMIN
const OEM_ADM_FUNCTION_CREATE_USER = 1;
const OEM_ADM_FUNCTION_SET_USER_STATE = 2;
const OEM_ADM_FUNCTION_GET_USER_LIST = 3;
const OEM_ADM_FUNCTION_SET_USER_TYPE = 4;

// TOOL_ADMIN
const TOOL_ADM_FUNCTION_CREATE_USER = 1;
const TOOL_ADM_FUNCTION_SET_USER_STATE = 2;
const TOOL_ADM_FUNCTION_GET_USER_LIST = 3;
const TOOL_ADM_FUNCTION_SET_USER_TYPE = 4;
const TOOL_ADM_FUNCTION_UPLOAD_INS_DOC_WITH_URL = 5;
const TOOL_ADM_FUNCTION_UPDATE_AGREEMENT = 6;

export async function getUIStringTableForLanguage(
  language: string,
  forFrontEnd: boolean
) {
  return await APIWrap.get(
    'authdiag-dev',
    '/ui/getUIStringTableForLanguage?languageID=' +
      language +
      '&forFrontEnd=' +
      forFrontEnd,
    { response: true }
  )
    .then((result) => {
      return result['data'];
    })
    .catch((error) => {
      if (error.response) {
        return { error: error.response.data.errorDesc };
      } else {
        return { error: error };
      }
    });
}

interface LoginParams {
  username: string;
  password: string;
}

export async function login({ username, password }: LoginParams) {
  return await fetch(config.apiGateway.URL + '/vendorui/login', {
    body: JSON.stringify({ username, password }),
    method: 'POST',
  })
    .then((result) => {
      return result.json();
    })
    .then((response) => {
      if (response.errorDesc) {
        return { error: response.errorDesc };
      } else {
        return response;
      }
    })
    .catch((error) => {
      if (error.errorDesc) {
        return { error: error.errorDesc };
      } else {
        return { error: error };
      }
    });
}

export async function refreshToken() {
  const RefreshToken = authStorage.getLoginTokens()?.RefreshToken;

  return await fetch(config.apiGateway.URL + '/vendorui/refresh', {
    body: JSON.stringify({
      RefreshToken: RefreshToken,
    }),
    method: 'POST',
  })
    .then((result) => {
      return result.json();
    })
    // .then((response) => {
    //   if (response.errorDesc) {
    //     return { error: response.errorDesc };
    //   } else {
    //     return response;
    //   }
    // })
    .catch((error) => {
      if (error.errorDesc) {
        return { error: error.errorDesc };
      } else {
        return { error: error };
      }
    });
}

export async function getUserInfo() {
  return await authFetch(config.apiGateway.URL + '/vendorui/getUserInfo', {
    method: 'POST',
  });
}

export interface GetVendorInfoParams {
  vendorID: string;
}

export async function getVendorInfo({
  vendorID,
}: GetVendorInfoParams): Promise<Vendor> {
  return await authFetch(config.apiGateway.URL + '/vendorui/getVendorInfo', {
    method: 'POST',
    body: JSON.stringify({
      vendorID,
    }),
  });
}

export interface GetAgreementsParams {
  oemVendorID?: string;
  toolVendorID?: string;
}

export interface GetAgreementsResponse {
  agreementList: Agreement[];
}

export async function getAgreements({
  oemVendorID,
  toolVendorID,
}: GetAgreementsParams): Promise<GetAgreementsResponse> {
  return await authFetch(config.apiGateway.URL + '/vendorui/getAgreements', {
    method: 'POST',
    body: JSON.stringify({
      oemVendorID,
      toolVendorID,
    }),
  });
}

export interface UpdateVendorInfoParams {
  vendorID?: string;
  vendorName: string;
  vendorContactStreet: string;
  vendorContactCity: string;
  vendorContactState: string;
  vendorContactZip: string;
  vendorContactPhone: string;
}

export async function updateVendorInfo({
  vendorID,
  vendorName,
  vendorContactStreet,
  vendorContactCity,
  vendorContactState,
  vendorContactZip,
  vendorContactPhone,
}: UpdateVendorInfoParams): Promise<HttpResponse<string>> {
  return await authFetch(config.apiGateway.URL + '/vendorui/updateVendorInfo', {
    method: 'POST',
    body: JSON.stringify({
      vendorID,
      vendorName,
      vendorContactStreet,
      vendorContactCity,
      vendorContactState,
      vendorContactZip,
      vendorContactPhone,
    }),
  });
}

export interface getVendorsResponse {
  vendorList: Vendor[];
}

export async function getVendors(): Promise<getVendorsResponse> {
  return await authFetch(config.apiGateway.URL + '/vendorui/getVendorList', {
    method: 'POST',
  });
}
export interface getToolsResponse {
  toolList: Tool[];
}

export async function getTools(): Promise<getToolsResponse> {
  return await authFetch(config.apiGateway.URL + '/vendorui/getToolDetails', {
    method: 'POST',
  });
}

export interface getStatsParams {
  timeSpecification: string;
  timeGranularity: TIME_GRANULARITY;
}
// export interface getStatsResponse {
//   statsList: Stats[];
// }

export async function getStats({
  timeSpecification,
  timeGranularity,
}: getStatsParams): Promise<Stats[]> {
  return await authFetch(config.apiGateway.URL + '/vendorui/getStats', {
    method: 'POST',
    body: JSON.stringify({
      timeSpecification,
      timeGranularity,
    }),
  });
}

export async function getSystemConfig() {
  return await fetch(config.apiGateway.URL + '/ui/getUIConfigs', {
    // @ts-ignore - TODO fix fetch type for system config
    response: true,
  })
    .then((result) => {
      return result.json();
    })
    .then((response) => {
      if (response.errorDesc) {
        return response.errorDesc;
      } else {
        return response;
      }
    })
    .catch((err) => {
      return err;
    });
}
export interface UpdateUserParams {
  firstName: string;
  lastName: string;
  email: string;
  userID?: string;
}

export async function updateUser({
  firstName,
  lastName,
  email,
  userID,
}: UpdateUserParams): Promise<HttpResponse<string>> {
  return await authFetch(config.apiGateway.URL + '/vendorui/updateUser', {
    method: 'POST',
    body: JSON.stringify({
      userID,
      firstName,
      lastName,
      email,
    }),
  });
}

export interface GetUsersResponse {
  userList: User[];
}

export async function siteAdminGetUsers(): Promise<GetUsersResponse> {
  return await authFetch(
    config.apiGateway.URL + '/vendorui/execSiteAdminFunction',
    {
      method: 'POST',
      body: JSON.stringify({
        functionID: SITE_ADM_FUNCTION_GET_USER_LIST,
      }),
    }
  );
}

export interface CreateUserStateParams {
  userName: string;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  userType: USER_TYPE;
  vendorID?: string;
}

export async function siteAdminCreateUser({
  userName,
  firstName,
  lastName,
  email,
  password,
  userType,
  vendorID,
}: CreateUserStateParams): Promise<string> {
  return await authFetch(
    config.apiGateway.URL + '/vendorui/execSiteAdminFunction',
    {
      method: 'POST',
      body: JSON.stringify({
        functionID: SITE_ADM_FUNCTION_CREATE_USER,
        userName,
        firstName,
        lastName,
        email,
        password,
        userType: userType,
        vendorID,
      }),
    }
  );
}

export interface SetUserStateParams {
  userID: string;
  userState: string;
}

export async function siteAdminSetUserState({
  userID,
  userState,
}: SetUserStateParams): Promise<HttpResponse<string>> {
  return await authFetch(
    config.apiGateway.URL + '/vendorui/execSiteAdminFunction',
    {
      method: 'POST',
      body: JSON.stringify({
        functionID: SITE_ADM_FUNCTION_SET_USER_STATE,
        userID,
        userState,
      }),
    }
  );
}

export interface SetVendorStateParams {
  vendorID: string;
  vendorState: string;
}

export async function siteAdminSetVendorState({
  vendorID,
  vendorState,
}: SetVendorStateParams): Promise<HttpResponse<string>> {
  return await authFetch(
    config.apiGateway.URL + '/vendorui/execSiteAdminFunction',
    {
      method: 'POST',
      body: JSON.stringify({
        functionID: SITE_ADM_FUNCTION_SET_VENDOR_STATE,
        vendorID,
        vendorState,
      }),
    }
  );
}
export interface UploadAgreementDocumentParams {
  agreementID: string;
  documentType: DOC_TYPE;
  documentName: string;
  documentData: string;
  modelYear?: string;
}

export async function siteAdminUploadDocument({
  agreementID,
  documentType,
  documentName,
  documentData,
  modelYear,
}: UploadAgreementDocumentParams): Promise<HttpResponse<string>> {
  return await authFetch(
    config.apiGateway.URL + '/vendorui/execSiteAdminFunction',
    {
      method: 'POST',
      body: JSON.stringify({
        functionID: SITE_ADM_FUNCTION_UPLOAD_DOCUMENT,
        agreementID,
        documentType,
        documentName,
        documentData,
        modelYear,
      }),
    }
  );
}

export interface UploadAgreementDocumentUrlParams {
  roleType: string;
  agreementID: string;
  documentType: DOC_TYPE;
  documentName: string;
  file: any;
  modelYear?: string;
}

export async function adminUploadDocumentUrl({
  roleType,
  agreementID,
  documentType,
  documentName,
  file,
  modelYear,
}: UploadAgreementDocumentUrlParams): Promise<HttpResponse<string>> {
  const commandUrl =
    roleType === 'SITE_ADMIN'
      ? '/vendorui/execSiteAdminFunction'
      : '/vendorui/execToolAdminFunction';
  const commandCode =
    roleType === 'SITE_ADMIN'
      ? SITE_ADM_FUNCTION_UPLOAD_DOCUMENT_WITH_URL
      : TOOL_ADM_FUNCTION_UPLOAD_INS_DOC_WITH_URL;
  return await authFetch(config.apiGateway.URL + commandUrl, {
    method: 'POST',
    body: JSON.stringify({
      functionID: commandCode,
      agreementID,
      documentType,
      documentName,
      modelYear,
    }),
  })
    .then(async (response) => {
      const formData = new FormData();
      formData.append('file', file);
      return await fetch(response.uploadUrl, {
        method: 'PUT',
        body: formData,
      });
    })
    .then((response: HttpResponse<string>) => {
      if (response.status === 401) {
        throw CORS_ERROR_MESSAGE;
      } else if (!response.ok && response?.errorDesc) {
        throw new Error(response.errorDesc);
      } else if (!response.ok && response?.message) {
        throw new Error(response.message);
      } else if (!response.ok) {
        throw new Error('Network Error');
      } else {
        return response;
      }
    })
    .catch((error: HttpError) => {
      if (error?.errorDesc) {
        throw new Error(error.errorDesc);
      } else if (error?.message) {
        throw new Error(error.message);
      } else {
        throw new Error(error.toString());
      }
    });
}

export interface DownloadAgreementDocumentParams {
  agreementID: string;
  documentType: DOC_TYPE;
  modelYear?: string;
}

export async function getDocument({
  agreementID,
  documentType,
  modelYear,
}: DownloadAgreementDocumentParams): Promise<AgreementDocument> {
  return await authFetch(
    config.apiGateway.URL + '/vendorui/downloadAgreementDocument',
    {
      method: 'POST',
      body: JSON.stringify({
        agreementID,
        documentType,
        modelYear,
      }),
    }
  );
}

export interface DownloadAgreementDocumentUrlParams {
  agreementID: string;
  documentType: DOC_TYPE;
  modelYear?: string;
}

export async function getDocumentUrl({
  agreementID,
  documentType,
  modelYear,
}: DownloadAgreementDocumentUrlParams): Promise<AgreementDocument> {
  return await authFetch(
    config.apiGateway.URL + '/vendorui/getAgreementDocumentDownloadURL',
    {
      method: 'POST',
      body: JSON.stringify({
        agreementID,
        documentType,
        modelYear,
      }),
    }
  );
}

export interface RemoveAgreementDocumentParams {
  agreementID: string;
  documentType: DOC_TYPE;
  modelYear?: string;
}

export async function siteAdminRemoveDocument({
  agreementID,
  documentType,
  modelYear,
}: RemoveAgreementDocumentParams): Promise<HttpResponse<string>> {
  return await authFetch(
    config.apiGateway.URL + '/vendorui/execSiteAdminFunction',
    {
      method: 'POST',
      body: JSON.stringify({
        functionID: SITE_ADM_FUNCTION_REMOVE_DOCUMENT,
        agreementID,
        documentType,
        modelYear,
      }),
    }
  );
}

export interface SetUserTypeParams {
  userID: string;
  userType: string;
}

export async function siteAdminSetUserType({
  userID,
  userType,
}: SetUserTypeParams): Promise<HttpResponse<string>> {
  return await authFetch(
    config.apiGateway.URL + '/vendorui/execSiteAdminFunction',
    {
      method: 'POST',
      body: JSON.stringify({
        functionID: SITE_ADM_FUNCTION_SET_USER_TYPE,
        userID,
        userType,
      }),
    }
  );
}

export interface CreateVendorParams {
  vendorName: string;
  vendorType: VENDOR_TYPE;
}

export interface CreateVendorResponse {
  vendorID: string;
  vendorName: string;
  vendorType: VENDOR_TYPE;
  vendorState: VENDOR_STATE;
  createdOn: string;
}

export async function siteAdminCreateVendor({
  vendorName,
  vendorType,
}: CreateVendorParams): Promise<CreateVendorResponse> {
  return await authFetch(
    config.apiGateway.URL + '/vendorui/execSiteAdminFunction',
    {
      method: 'POST',
      body: JSON.stringify({
        functionID: SITE_ADM_FUNCTION_CREATE_VENDOR,
        vendorName,
        vendorType,
      }),
    }
  );
}

export interface CreateAgreementStateParams {
  oemVendorID: string;
  toolVendorID: string;
  agreementName: string;
  agreementNotes: string;
  agreementRegion: string;
  licenseAgreement: LicenseAgreement;
  comprehensiveFees: ComprehensiveFees;
  otherFees: OtherFees;
  annualFees: AnnualFees;
  insuranceDocs: InsuranceDocs;
  supportDocs: SupportDocs;
}

export async function siteAdminCreateAgreement({
  oemVendorID,
  toolVendorID,
  agreementName,
  agreementNotes,
  agreementRegion,
  licenseAgreement,
  comprehensiveFees,
  otherFees,
  annualFees,
  insuranceDocs,
  supportDocs,
}: CreateAgreementStateParams): Promise<Agreement> {
  return await authFetch(
    config.apiGateway.URL + '/vendorui/execSiteAdminFunction',
    {
      method: 'POST',
      body: JSON.stringify({
        functionID: SITE_ADM_FUNCTION_ADD_AGREEMENT,
        oemVendorID,
        toolVendorID,
        agreementName,
        agreementNotes,
        agreementRegion,
        licenseAgreement,
        comprehensiveFees,
        otherFees,
        annualFees,
        insuranceDocs,
        supportDocs,
      }),
    }
  );
}

export interface UpdateAgreementStateParams {
  roleType: string;
  agreementID: string;
  oemVendorID: string;
  agreementName?: string;
  agreementNotes?: string;
  agreementRegion?: string;
  licenseAgreement?: LicenseAgreement;
  comprehensiveFees?: ComprehensiveFees;
  otherFees?: OtherFees;
  annualFees?: AnnualFees;
  insuranceDocs: InsuranceDocs;
  supportDocs?: SupportDocs;
}

export async function adminUpdateAgreement({
  roleType,
  agreementID,
  oemVendorID,
  agreementName,
  agreementNotes,
  agreementRegion,
  licenseAgreement,
  comprehensiveFees,
  otherFees,
  annualFees,
  insuranceDocs,
  supportDocs,
}: UpdateAgreementStateParams): Promise<string> {
  const endpointUrl =
    roleType === 'SITE_ADMIN'
      ? '/vendorui/execSiteAdminFunction'
      : '/vendorui/execToolAdminFunction';
  const actionCode =
    roleType === 'SITE_ADMIN'
      ? SITE_ADM_FUNCTION_UPDATE_AGREEMENT
      : TOOL_ADM_FUNCTION_UPDATE_AGREEMENT;
  return await authFetch(config.apiGateway.URL + endpointUrl, {
    method: 'POST',
    body: JSON.stringify({
      functionID: actionCode,
      oemVendorID,
      agreementID,
      agreementName,
      agreementNotes,
      agreementRegion,
      licenseAgreement,
      comprehensiveFees,
      otherFees,
      annualFees,
      insuranceDocs,
      supportDocs,
    }),
  });
}

export interface CreateOEMUserStateParams {
  userName: string;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  userType: USER_TYPE.OEM_ADMIN | USER_TYPE.OEM_USER;
  vendorID: string;
}
export interface CreateToolUserStateParams {
  userName: string;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  userType: USER_TYPE.TOOL_ADMIN | USER_TYPE.TOOL_USER;
  vendorID: string;
}

export async function oemAdminGetUsers(): Promise<GetUsersResponse> {
  return await authFetch(
    config.apiGateway.URL + '/vendorui/execOemAdminFunction',
    {
      method: 'POST',
      body: JSON.stringify({
        functionID: OEM_ADM_FUNCTION_GET_USER_LIST,
      }),
    }
  );
}

export async function oemAdminCreateUser({
  userName,
  firstName,
  lastName,
  email,
  password,
  userType,
  vendorID,
}: CreateOEMUserStateParams): Promise<string> {
  return await authFetch(
    config.apiGateway.URL + '/vendorui/execOemAdminFunction',
    {
      method: 'POST',
      body: JSON.stringify({
        functionID: OEM_ADM_FUNCTION_CREATE_USER,
        userName,
        firstName,
        lastName,
        email,
        password,
        userType: userType,
        vendorID,
      }),
    }
  );
}

export async function oemAdminSetUserState({
  userID,
  userState,
}: SetUserStateParams): Promise<HttpResponse<string>> {
  return await authFetch(
    config.apiGateway.URL + '/vendorui/execOemAdminFunction',
    {
      method: 'POST',
      body: JSON.stringify({
        functionID: OEM_ADM_FUNCTION_SET_USER_STATE,
        userID,
        userState,
      }),
    }
  );
}

export async function oemAdminSetUserType({
  userID,
  userType,
}: SetUserTypeParams): Promise<HttpResponse<string>> {
  return await authFetch(
    config.apiGateway.URL + '/vendorui/execOemAdminFunction',
    {
      method: 'POST',
      body: JSON.stringify({
        functionID: OEM_ADM_FUNCTION_SET_USER_TYPE,
        userID,
        userType,
      }),
    }
  );
}

export async function toolAdminGetUsers(): Promise<GetUsersResponse> {
  return await authFetch(
    config.apiGateway.URL + '/vendorui/execToolAdminFunction',
    {
      method: 'POST',
      body: JSON.stringify({
        functionID: TOOL_ADM_FUNCTION_GET_USER_LIST,
      }),
    }
  );
}

export async function toolAdminCreateUser({
  userName,
  firstName,
  lastName,
  email,
  password,
  userType,
  vendorID,
}: CreateToolUserStateParams): Promise<string> {
  return await authFetch(
    config.apiGateway.URL + '/vendorui/execToolAdminFunction',
    {
      method: 'POST',
      body: JSON.stringify({
        functionID: TOOL_ADM_FUNCTION_CREATE_USER,
        userName,
        firstName,
        lastName,
        email,
        password,
        userType: userType,
        vendorID,
      }),
    }
  );
}

export async function toolAdminSetUserState({
  userID,
  userState,
}: SetUserStateParams): Promise<HttpResponse<string>> {
  return await authFetch(
    config.apiGateway.URL + '/vendorui/execToolAdminFunction',
    {
      method: 'POST',
      body: JSON.stringify({
        functionID: TOOL_ADM_FUNCTION_SET_USER_STATE,
        userID,
        userState,
      }),
    }
  );
}

export async function toolAdminSetUserType({
  userID,
  userType,
}: SetUserTypeParams): Promise<HttpResponse<string>> {
  return await authFetch(
    config.apiGateway.URL + '/vendorui/execToolAdminFunction',
    {
      method: 'POST',
      body: JSON.stringify({
        functionID: TOOL_ADM_FUNCTION_SET_USER_TYPE,
        userID,
        userType,
      }),
    }
  );
}
