import { useFormik } from 'formik';
import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { Loading } from '../../components/Loading';
import { TextOnly } from '../../components/Text';
import ToggleButton from '../../components/ToggleButton';
import {
  USER_STATE,
  USER_TYPE,
  USER_TYPE_LIST,
  VENDOR_TYPE,
} from '../../CONSTANTS';
import { useConfirm } from '../../context/Confirm';
import { useUser } from '../../context/User';
import {
  CreateOEMUserStateParams,
  CreateToolUserStateParams,
  CreateUserStateParams,
  GetUsersResponse,
  oemAdminCreateUser,
  oemAdminGetUsers,
  oemAdminSetUserState,
  oemAdminSetUserType,
  SetUserStateParams,
  SetUserTypeParams,
  siteAdminCreateUser,
  siteAdminGetUsers,
  getVendors,
  siteAdminSetUserState,
  siteAdminSetUserType,
  toolAdminCreateUser,
  toolAdminGetUsers,
  toolAdminSetUserState,
  toolAdminSetUserType,
  updateUser,
  UpdateUserParams,
} from '../../libs/db-lib';
import { UpdateUser, User } from '../../types';
import { ManageUsers } from './ManageUsers';
import * as Yup from 'yup';
import moment from 'moment';

export interface UserTableView {
  userName: string;
  name: string;
  addedOn: string;
  addedBy: string;
  vendorName?: string;
  userType: USER_TYPE;
  userState: React.Component | USER_STATE;
  crud: React.Component | JSX.Element;
}

export enum MANAGE_USERS_DIALOGS {
  ADD_USER = 'ADD_USER',
  EDIT_USER = 'EDIT_USER',
  FILTER = 'FILTER',
}

export const ManageUsersContainer = () => {
  // TODO: userError will log out user, might move error to local state or a new global error - Adam Curl 2022-02-01
  const { user, isSite, isOEM, isTool, vendorID } = useUser();
  const { handleSetConfirmProps } = useConfirm();

  const [currentDialog, setCurrentDialog] =
    useState<MANAGE_USERS_DIALOGS | null>(null);
  const [users, setUsers] = useState<Array<UserTableView>>([]);
  const [userTypeFilter, setUserTypeFilter] = useState<USER_TYPE[]>([]);
  const [userStateFilter, setUserStateFilter] = useState<USER_STATE[]>([]);
  const [vendorFilter, setVendorFilter] = useState<string[]>([]);
  const [selectedUser, setSelectedUser] = useState<UpdateUser | null>(null);

  const siteAdminUserTypeOptions = USER_TYPE_LIST;
  const oemUserTypeOptions = [USER_TYPE.OEM_ADMIN, USER_TYPE.OEM_USER];
  const toolUserTypeOptions = [USER_TYPE.TOOL_ADMIN, USER_TYPE.TOOL_USER];

  const siteAdminGetUserList = (data: GetUsersResponse) => {
    const userList = data?.userList?.map((user: User) => {
      return {
        id: user.userID,
        userName: user.userName,
        name: `${user.firstName} ${user.lastName}`,
        addedOn: moment(user.createdOn).format('MM-DD-YYYY'),
        addedBy: user.addedBy,
        vendorName: oemUserTypeOptions.includes(user.userType)
          ? oemVendors?.find((vendor) => vendor.vendorID === user.vendorID)
              ?.vendorName
          : toolUserTypeOptions.includes(user.userType)
          ? toolVendors?.find((vendor) => vendor.vendorID === user.vendorID)
              ?.vendorName
          : '',
        userType: user.userType,
        userState: user.userState,
        crud: (
          <div className="l-flex-end l-flex-auto">
            <button
              className="c-btn-icon"
              onClick={() => {
                setSelectedUser(user);
                toggleEditUserDialog();
              }}
              disabled={user.userState !== USER_STATE.ACTIVE}
            >
              <div className="c-btn__inner">
                <i className="c-btn__icon fal fa-edit" />
              </div>
            </button>
          </div>
        ),
      };
    });
    setUsers(userList);
  };

  const oemAdminGetUserList = (data: GetUsersResponse) => {
    const userList = data?.userList?.map((user: User) => {
      return {
        id: user.userID,
        userName: user.userName,
        name: `${user.firstName} ${user.lastName}`,
        addedOn: moment(user.createdOn).format('MM-DD-YYYY'),
        addedBy: user.addedBy,
        userType: user.userType,
        userState: user.userState,
        crud: (
          <div className="l-flex-end l-flex-auto">
            <button
              className="c-btn-icon"
              onClick={() => {
                setSelectedUser(user);
                toggleEditUserDialog();
              }}
              disabled={user.userState !== USER_STATE.ACTIVE}
            >
              <div className="c-btn__inner">
                <i className="c-btn__icon fal fa-edit" />
              </div>
            </button>
          </div>
        ),
      };
    });
    setUsers(userList);
  };

  const toolAdminGetUserList = (data: GetUsersResponse) => {
    const userList = data?.userList?.map((user: User) => {
      return {
        id: user.userID,
        userName: user.userName,
        name: `${user.firstName} ${user.lastName}`,
        addedOn: moment(user.createdOn).format('MM-DD-YYYY'),
        addedBy: user.addedBy,
        userType: user.userType,
        userState: user.userState,
        crud: (
          <div className="l-flex-end l-flex-auto">
            <button
              className="c-btn-icon"
              onClick={() => {
                setSelectedUser(user);
                toggleEditUserDialog();
              }}
              disabled={user.userState !== USER_STATE.ACTIVE}
            >
              <div className="c-btn__inner">
                <i className="c-btn__icon fal fa-edit" />
              </div>
            </button>
          </div>
        ),
      };
    });
    setUsers(userList);
  };

  // #region 1 queries
  const { data: vendors, isLoading: isLoadingVendors } = useQuery(
    'getVendors',
    getVendors,
    {
      enabled: !!isSite,
    }
  );
  const oemVendors = vendors?.vendorList?.filter(
    (vendor) => vendor.vendorType === VENDOR_TYPE.OEM
  );
  const toolVendors = vendors?.vendorList?.filter(
    (vendor) => vendor.vendorType === VENDOR_TYPE.TOOL
  );

  const {
    isLoading: siteAdminGetUsersIsLoading,
    refetch: siteAdminGetUsersRefetch,
  } = useQuery('siteAdminGetUsers', siteAdminGetUsers, {
    enabled: !!isSite && !isLoadingVendors,
    onSuccess: (data) => {
      siteAdminGetUserList(data);
    },
  });

  const { isLoading: oemGetUsersIsLoading, refetch: oemGetUsersRefetch } =
    useQuery('oemGetUsers', oemAdminGetUsers, {
      enabled: !!isOEM,
      onSuccess: (data) => {
        oemAdminGetUserList(data);
      },
    });

  const { isLoading: toolGetUsersIsLoading, refetch: toolGetUsersRefetch } =
    useQuery('toolGetUsers', toolAdminGetUsers, {
      enabled: !!isTool,
      onSuccess: (data) => {
        toolAdminGetUserList(data);
      },
    });

  const getUsersRefetch = () => {
    if (isSite) {
      siteAdminGetUsersRefetch();
    } else if (isOEM) {
      oemGetUsersRefetch();
    } else if (isTool) {
      toolGetUsersRefetch();
    }
  };
  // #endregion 1

  // #region 2 mutations
  const siteAdminCreateUserMutation = useMutation(
    (userFields: CreateUserStateParams) => siteAdminCreateUser(userFields),
    {
      onSuccess: (data: any) => {
        if (data?.error) {
          toast.error(data.error, {
            autoClose: false,
            containerId: 'standard',
          });
          return;
        }
        toast.success(TextOnly('createUserSuccess'), {
          containerId: 'standard',
        });
        siteAdminAddUserFormik.resetForm();
        setCurrentDialog(null);
        getUsersRefetch();
      },
      onError: (error: Error) => {
        toast.error(error.message, {
          autoClose: false,
          containerId: 'standard',
        });
      },
    }
  );

  const oemAdminCreateUserMutation = useMutation(
    (userFields: CreateOEMUserStateParams) => oemAdminCreateUser(userFields),
    {
      onSuccess: (data: any) => {
        if (data?.error) {
          toast.error(data.error, {
            autoClose: false,
            containerId: 'standard',
          });
          return;
        }
        toast.success(TextOnly('createUserSuccess'), {
          containerId: 'standard',
        });
        oemAddUserFormik.resetForm();
        setCurrentDialog(null);
        getUsersRefetch();
      },
      onError: (error: Error) => {
        toast.error(error.message, {
          autoClose: false,
          containerId: 'standard',
        });
      },
    }
  );

  const toolAdminCreateUserMutation = useMutation(
    (userFields: CreateToolUserStateParams) => toolAdminCreateUser(userFields),
    {
      onSuccess: (data: any) => {
        if (data?.error) {
          toast.error(data.error, {
            autoClose: false,
            containerId: 'standard',
          });
          return;
        }
        toast.success(TextOnly('createUserSuccess'), {
          containerId: 'standard',
        });
        toolAddUserFormik.resetForm();
        setCurrentDialog(null);
        getUsersRefetch();
      },
      onError: (error: Error) => {
        toast.error(error.message, {
          autoClose: false,
          containerId: 'standard',
        });
      },
    }
  );

  const updateUserMutation = useMutation<
    { error?: string },
    Error,
    UpdateUserParams
  >((userFields) => updateUser(userFields), {
    onSuccess: (data: any) => {
      if (data?.error) {
        toast.error(data.error, {
          autoClose: false,
          containerId: 'standard',
        });
        return;
      }
      toast.success(TextOnly('updateSuccess'), {
        containerId: 'standard',
      });
      editUserFormik.resetForm();
      setSelectedUser(null);
      setCurrentDialog(null);
      getUsersRefetch();
    },
    onError: (error) => {
      toast.error(error.message, {
        autoClose: false,
        containerId: 'standard',
      });
    },
  });

  const siteAdminSetUserStateMutation = useMutation<
    { error?: string },
    Error,
    SetUserStateParams
  >((userToUpdateFields) => siteAdminSetUserState(userToUpdateFields), {
    onSuccess: (data) => {
      if (data?.error) {
        toast.error(data.error, {
          autoClose: false,
          containerId: 'standard',
        });
        return;
      }
      toast.success(TextOnly('updateSuccess'), {
        containerId: 'standard',
      });
      getUsersRefetch();
    },
    onError: (error) => {
      toast.error(error.message, {
        autoClose: false,
        containerId: 'standard',
      });
    },
  });

  const oemSetUserStateMutation = useMutation<
    { error?: string },
    Error,
    SetUserStateParams
  >((userToUpdateFields) => oemAdminSetUserState(userToUpdateFields), {
    onSuccess: (data) => {
      if (data?.error) {
        toast.error(data.error, {
          autoClose: false,
          containerId: 'standard',
        });
        return;
      }
      toast.success(TextOnly('updateSuccess'), {
        containerId: 'standard',
      });
      getUsersRefetch();
    },
    onError: (error) => {
      toast.error(error.message, {
        autoClose: false,
        containerId: 'standard',
      });
    },
  });

  const toolSetUserStateMutation = useMutation<
    { error?: string },
    Error,
    SetUserStateParams
  >((userToUpdateFields) => toolAdminSetUserState(userToUpdateFields), {
    onSuccess: (data) => {
      if (data?.error) {
        toast.error(data.error, {
          autoClose: false,
          containerId: 'standard',
        });
        return;
      }
      toast.success(TextOnly('updateSuccess'), {
        containerId: 'standard',
      });
      getUsersRefetch();
    },
    onError: (error) => {
      toast.error(error.message, {
        autoClose: false,
        containerId: 'standard',
      });
    },
  });

  const siteAdminSetUserTypeMutation = useMutation<
    { error?: string },
    Error,
    SetUserTypeParams
  >((userToUpdateFields) => siteAdminSetUserType(userToUpdateFields), {
    onSuccess: (data) => {
      if (data?.error) {
        toast.error(data.error, {
          autoClose: false,
          containerId: 'standard',
        });
        return;
      }
      toast.success(TextOnly('updateSuccess'), {
        containerId: 'standard',
      });
      getUsersRefetch();
    },
    onError: (error) => {
      toast.error(error.message, {
        autoClose: false,
        containerId: 'standard',
      });
    },
  });

  const oemAdminSetUserTypeMutation = useMutation<
    { error?: string },
    Error,
    SetUserTypeParams
  >((userToUpdateFields) => oemAdminSetUserType(userToUpdateFields), {
    onSuccess: (data) => {
      if (data?.error) {
        toast.error(data.error, {
          autoClose: false,
          containerId: 'standard',
        });
        return;
      }
      toast.success(TextOnly('updateSuccess'), {
        containerId: 'standard',
      });
      getUsersRefetch();
    },
    onError: (error) => {
      toast.error(error.message, {
        autoClose: false,
        containerId: 'standard',
      });
    },
  });

  const toolAdminSetUserTypeMutation = useMutation<
    { error?: string },
    Error,
    SetUserTypeParams
  >((userToUpdateFields) => toolAdminSetUserType(userToUpdateFields), {
    onSuccess: (data) => {
      if (data?.error) {
        toast.error(data.error, {
          autoClose: false,
          containerId: 'standard',
        });
        return;
      }
      toast.success(TextOnly('updateSuccess'), {
        containerId: 'standard',
      });
      getUsersRefetch();
    },
    onError: (error) => {
      toast.error(error.message, {
        autoClose: false,
        containerId: 'standard',
      });
    },
  });
  // #endregion 2

  // #region 3 columns
  const siteAdmincolumns = [
    {
      Header: 'User Name',
      accessor: 'userName',
    },
    {
      Header: 'Name',
      accessor: 'name',
    },
    {
      Header: 'Added On',
      accessor: 'addedOn',
    },
    {
      Header: 'Added By',
      accessor: 'addedBy',
    },
    {
      Header: 'Partner',
      accessor: 'vendorName',
    },
    {
      Header: 'Role',
      accessor: 'userType',
      filter: 'equals',
      Cell: ({ row: { original } }: any) => (
        <div
          className={`c-select c-select--no-border c-select--wide u-padding-none ${
            original.userType === USER_TYPE.SITE_ADMIN ||
            original.userState !== USER_STATE.ACTIVE ||
            user?.userID === original.id
              ? 'c-select--disabled'
              : ''
          }`}
        >
          {original.userType !== USER_TYPE.SITE_ADMIN ? (
            <select
              id={`role-${original.id}`}
              name={`role-${original.id}`}
              value={original.userType}
              disabled={
                original.userType === USER_TYPE.SITE_ADMIN ||
                original.userState !== USER_STATE.ACTIVE ||
                user?.userID === original.id
              }
              onChange={(e) => {
                const value = e.target.value;
                handleSetConfirmProps({
                  title: TextOnly('confirmChangeRole'),
                  message: TextOnly('confirmChangeRoleMessage', {
                    userName: original.userName,
                    userType: value,
                  }),
                  handleConfirm: () => {
                    siteAdminSetUserTypeMutation.mutate({
                      userID: original.id,
                      userType: value,
                    });
                  },
                });
              }}
            >
              {oemUserTypeOptions.includes(original.userType)
                ? oemUserTypeOptions.map((userType) => (
                    <option key={userType} value={userType}>
                      {TextOnly(userType)}
                    </option>
                  ))
                : toolUserTypeOptions.map((userType) => (
                    <option key={userType} value={userType}>
                      {TextOnly(userType)}
                    </option>
                  ))}
            </select>
          ) : (
            <select
              id={`role-${original.id}`}
              name={`role-${original.id}`}
              value={original.userType}
              disabled={true}
            >
              <option>{TextOnly(original.userType)}</option>
            </select>
          )}
        </div>
      ),
    },
    {
      Header: 'Status',
      accessor: 'userState',
      filter: 'equals',
      minWidth: 150,
      Cell: ({ row: { original } }: any) => (
        <ToggleButton
          isActive={original.userState === USER_STATE.ACTIVE}
          title="set user active/inactive"
          handleToggle={() => {
            siteAdminSetUserStateMutation.mutate({
              userID: original.id,
              userState:
                original.userState === USER_STATE.ACTIVE
                  ? USER_STATE.INACTIVE
                  : USER_STATE.ACTIVE,
            });
          }}
        />
      ),
    },
    {
      Header: '',
      accessor: 'crud',
      disableSortBy: true,
    },
  ];

  const oemColumns = [
    {
      Header: 'User Name',
      accessor: 'userName',
    },
    {
      Header: 'Name',
      accessor: 'name',
    },
    {
      Header: 'Added On',
      accessor: 'addedOn',
    },
    {
      Header: 'Added By',
      accessor: 'addedBy',
    },
    {
      Header: 'Role',
      accessor: 'userType',
      filter: 'equals',
      Cell: ({ row: { original } }: any) => (
        <div
          className={`c-select c-select--no-border c-select--wide u-padding-none ${
            original.userType === USER_TYPE.SITE_ADMIN ||
            original.userState !== USER_STATE.ACTIVE ||
            user?.userID === original.id
              ? 'c-select--disabled'
              : ''
          }`}
        >
          <select
            id={`role-${original.id}`}
            name={`role-${original.id}`}
            value={original.userType}
            disabled={
              original.userState !== USER_STATE.ACTIVE ||
              user?.userID === original.id
            }
            onChange={(e) => {
              const value = e.target.value;
              handleSetConfirmProps({
                title: TextOnly('confirmChangeRole'),
                message: TextOnly('confirmChangeRoleMessage', {
                  userName: original.userName,
                  userType: value,
                }),
                handleConfirm: () => {
                  oemAdminSetUserTypeMutation.mutate({
                    userID: original.id,
                    userType: value,
                  });
                },
              });
            }}
          >
            {oemUserTypeOptions.map((userType) => (
              <option key={userType} value={userType}>
                {TextOnly(userType)}
              </option>
            ))}
          </select>
        </div>
      ),
    },
    {
      Header: 'Status',
      accessor: 'userState',
      filter: 'equals',
      minWidth: 150,
      Cell: ({ row: { original } }: any) => (
        <ToggleButton
          isActive={original.userState === USER_STATE.ACTIVE}
          title="set user active/inactive"
          handleToggle={() => {
            oemSetUserStateMutation.mutate({
              userID: original.id,
              userState:
                original.userState === USER_STATE.ACTIVE
                  ? USER_STATE.INACTIVE
                  : USER_STATE.ACTIVE,
            });
          }}
        />
      ),
    },
    {
      Header: '',
      accessor: 'crud',
      disableSortBy: true,
    },
  ];

  const toolColumns = [
    {
      Header: 'User Name',
      accessor: 'userName',
    },
    {
      Header: 'Name',
      accessor: 'name',
    },
    {
      Header: 'Added On',
      accessor: 'addedOn',
    },
    {
      Header: 'Added By',
      accessor: 'addedBy',
    },
    {
      Header: 'Role',
      accessor: 'userType',
      filter: 'equals',
      Cell: ({ row: { original } }: any) => (
        <div
          className={`c-select c-select--no-border c-select--wide u-padding-none ${
            original.userType === USER_TYPE.SITE_ADMIN ||
            original.userState !== USER_STATE.ACTIVE ||
            user?.userID === original.id
              ? 'c-select--disabled'
              : ''
          }`}
        >
          <select
            id={`role-${original.id}`}
            name={`role-${original.id}`}
            value={original.userType}
            disabled={
              original.userState !== USER_STATE.ACTIVE ||
              user?.userID === original.id
            }
            onChange={(e) => {
              const value = e.target.value;
              handleSetConfirmProps({
                title: TextOnly('confirmChangeRole'),
                message: TextOnly('confirmChangeRoleMessage', {
                  userName: original.userName,
                  userType: value,
                }),
                handleConfirm: () => {
                  toolAdminSetUserTypeMutation.mutate({
                    userID: original.id,
                    userType: value,
                  });
                },
              });
            }}
          >
            {toolUserTypeOptions.map((userType) => (
              <option key={userType} value={userType}>
                {TextOnly(userType)}
              </option>
            ))}
          </select>
        </div>
      ),
    },
    {
      Header: 'Status',
      accessor: 'userState',
      filter: 'equals',
      minWidth: 150,
      Cell: ({ row: { original } }: any) => (
        <ToggleButton
          isActive={original.userState === USER_STATE.ACTIVE}
          title="set user active/inactive"
          handleToggle={() => {
            toolSetUserStateMutation.mutate({
              userID: original.id,
              userState:
                original.userState === USER_STATE.ACTIVE
                  ? USER_STATE.INACTIVE
                  : USER_STATE.ACTIVE,
            });
          }}
        />
      ),
    },
    {
      Header: '',
      accessor: 'crud',
      disableSortBy: true,
    },
  ];
  // #endregion 3

  // #region 4 formik
  const formik = useFormik({
    initialValues: {
      userTypeFilter: userTypeFilter,
      userStateFilter: userStateFilter,
      vendorFilter: vendorFilter,
    },
    onSubmit: async (values) => {
      setUserTypeFilter(values.userTypeFilter);
      setUserStateFilter(values.userStateFilter);
      setVendorFilter(values.vendorFilter);
      toggleFilterDialog();
    },
    enableReinitialize: true,
  });

  const siteAdminAddUserValidation = Yup.object().shape({
    userName: Yup.string()
      .max(50)
      .matches(
        /^([a-zA-Z]{1})([a-zA-Z0-9.]{7,})$/,
        TextOnly('userNameFormatRequirements')
      )
      .required(TextOnly('requiredField', { field: TextOnly('username') })),
    firstName: Yup.string().required(
      TextOnly('requiredField', { field: TextOnly('firstName') })
    ),
    lastName: Yup.string().required(
      TextOnly('requiredField', { field: TextOnly('lastName') })
    ),
    email: Yup.string()
      .max(50)
      .email(TextOnly('invalidField', { field: TextOnly('email') }))
      .required(TextOnly('requiredField', { field: TextOnly('email') })),
    password: Yup.string()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[-~_`|!@#$%^&*+.,?(){}=:;"'<>[\\\]\\\\]).{8,}$/
      )
      .required(TextOnly('requiredField', { field: TextOnly('password') })),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password')], TextOnly('passwordMismatch'))
      .required(TextOnly('requiredField', { field: 'Password Confimation' })),
    userType: Yup.string().required(
      TextOnly('requiredField', { field: 'role' })
    ),
    vendorID: Yup.string().when('userType', {
      is: (value: USER_TYPE) => value !== USER_TYPE.SITE_ADMIN,
      then: Yup.string().required(
        TextOnly('requiredField', { field: TextOnly('vendor') })
      ),
    }),
  });

  const siteAdminAddUserFormik = useFormik({
    initialValues: {
      userName: '',
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      confirmPassword: '',
      userType: '',
      vendorID: '',
    },
    validationSchema: siteAdminAddUserValidation,
    onSubmit: async (values) => {
      siteAdminCreateUserMutation.mutate({
        userName: values.userName,
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        password: values.password,
        userType: values.userType as USER_TYPE,
        vendorID: values.vendorID !== '' ? values.vendorID : undefined,
      });
    },
  });

  const oemAddUserValidation = Yup.object().shape({
    userName: Yup.string()
      .max(50)
      .matches(
        /^([a-zA-Z]{1})([a-zA-Z0-9.]{7,})$/,
        TextOnly('userNameFormatRequirements')
      )
      .required(TextOnly('requiredField', { field: TextOnly('username') })),
    firstName: Yup.string().required(
      TextOnly('requiredField', { field: TextOnly('firstName') })
    ),
    lastName: Yup.string().required(
      TextOnly('requiredField', { field: TextOnly('lastName') })
    ),
    email: Yup.string()
      .max(50)
      .email(TextOnly('invalidField', { field: TextOnly('email') }))
      .required(TextOnly('requiredField', { field: TextOnly('email') })),
    password: Yup.string()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[-~_`|!@#$%^&*+.,?(){}=:;"'<>[\\\]\\\\]).{8,}$/
      )
      .required(TextOnly('requiredField', { field: TextOnly('password') })),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password')], TextOnly('passwordMismatch'))
      .required(TextOnly('requiredField', { field: 'Password Confimation' })),
    userType: Yup.string().required(
      TextOnly('requiredField', { field: 'role' })
    ),
  });

  const oemAddUserFormik = useFormik({
    initialValues: {
      userName: '',
      firstName: '',
      lastName: '',
      email: '',
      confirmPassword: '',
      password: '',
      userType: USER_TYPE.OEM_USER,
    },
    validationSchema: oemAddUserValidation,
    onSubmit: async (values) => {
      oemAdminCreateUserMutation.mutate({
        userName: values.userName,
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        password: values.password,
        userType: values.userType as USER_TYPE.OEM_ADMIN | USER_TYPE.OEM_USER,
        vendorID: vendorID,
      });
    },
  });

  const toolAddUserValidation = Yup.object().shape({
    userName: Yup.string()
      .max(50)
      .matches(
        /^([a-zA-Z]{1})([a-zA-Z0-9.]{7,})$/,
        TextOnly('userNameFormatRequirements')
      )
      .required(TextOnly('requiredField', { field: TextOnly('username') })),
    firstName: Yup.string().required(
      TextOnly('requiredField', { field: TextOnly('firstName') })
    ),
    lastName: Yup.string().required(
      TextOnly('requiredField', { field: TextOnly('lastName') })
    ),
    email: Yup.string()
      .max(50)
      .email(TextOnly('invalidField', { field: TextOnly('email') }))
      .required(TextOnly('requiredField', { field: TextOnly('email') })),
    password: Yup.string()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[-~_`|!@#$%^&*+.,?(){}=:;"'<>[\\\]\\\\]).{8,}$/
      )
      .required(TextOnly('requiredField', { field: TextOnly('password') })),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password')], TextOnly('passwordMismatch'))
      .required(TextOnly('requiredField', { field: 'Password Confimation' })),
    userType: Yup.string().required(
      TextOnly('requiredField', { field: 'role' })
    ),
  });

  const toolAddUserFormik = useFormik({
    initialValues: {
      userName: '',
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      confirmPassword: '',
      userType: USER_TYPE.TOOL_USER,
    },
    validationSchema: toolAddUserValidation,
    onSubmit: async (values) => {
      toolAdminCreateUserMutation.mutate({
        userName: values.userName,
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        password: values.password,
        userType: values.userType as USER_TYPE.TOOL_ADMIN | USER_TYPE.TOOL_USER,
        vendorID: vendorID,
      });
    },
  });

  const editUserValidation = Yup.object().shape({
    firstName: Yup.string().required(
      TextOnly('requiredField', { field: TextOnly('firstName') })
    ),
    lastName: Yup.string().required(
      TextOnly('requiredField', { field: TextOnly('lastName') })
    ),
    email: Yup.string()
      .max(50)
      .email(TextOnly('invalidField', { field: TextOnly('email') }))
      .required(TextOnly('requiredField', { field: TextOnly('email') })),
  });

  const editUserFormik = useFormik({
    initialValues: {
      userID: selectedUser?.userID || '',
      firstName: selectedUser?.firstName || '',
      lastName: selectedUser?.lastName || '',
      email: selectedUser?.email || '',
    },
    validationSchema: editUserValidation,
    onSubmit: async (values) => {
      updateUserMutation.mutate({
        userID: values.userID,
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
      });
    },
    enableReinitialize: true,
  });

  // #endregion 4

  // #region 5 funcitons

  const toggleFilterDialog = () => {
    currentDialog === MANAGE_USERS_DIALOGS.FILTER
      ? setCurrentDialog(null)
      : setCurrentDialog(MANAGE_USERS_DIALOGS.FILTER);
  };

  const toggleAddUserDialog = () => {
    currentDialog === MANAGE_USERS_DIALOGS.ADD_USER
      ? setCurrentDialog(null)
      : setCurrentDialog(MANAGE_USERS_DIALOGS.ADD_USER);
  };

  const toggleEditUserDialog = () => {
    currentDialog === MANAGE_USERS_DIALOGS.EDIT_USER
      ? setCurrentDialog(null)
      : setCurrentDialog(MANAGE_USERS_DIALOGS.EDIT_USER);
  };

  const resetFilters = () => {
    setUserTypeFilter([]);
    setUserStateFilter([]);
    setVendorFilter([]);
    setCurrentDialog(null);
  };

  function handleFilterDelete(field: string, value: string) {
    if (field === 'userType') {
      setUserTypeFilter([]);
    } else if (field === 'userState') {
      setUserStateFilter([]);
    } else if (field === 'vendor') {
      setVendorFilter([]);
    }
  }

  const userTypeFilterSelections =
    userTypeFilter.length > 0
      ? {
          userType: [
            {
              key: userTypeFilter[0],
              value: userTypeFilter[0],
              label: userTypeFilter[0],
              pillLabel: userTypeFilter[0],
            },
          ],
        }
      : null;

  const userStateFilterSelections =
    userStateFilter.length > 0
      ? {
          userState: [
            {
              key: userStateFilter[0],
              value: userStateFilter[0],
              label: userStateFilter[0],
              pillLabel: userStateFilter[0],
            },
          ],
        }
      : null;

  const vendorFilterSelections =
    vendorFilter.length > 0
      ? {
          vendor: [
            {
              key: vendorFilter[0],
              value: vendorFilter[0],
              label: vendorFilter[0],
              pillLabel: vendorFilter[0],
            },
          ],
        }
      : null;

  const filters = {
    ...userTypeFilterSelections,
    ...userStateFilterSelections,
    ...vendorFilterSelections,
  };
  // #endregion 5

  if (
    siteAdminGetUsersIsLoading ||
    isLoadingVendors ||
    oemGetUsersIsLoading ||
    toolGetUsersIsLoading
  ) {
    return <Loading />;
  }

  if (isOEM) {
    return (
      <>
        <ManageUsers
          isSite={isSite}
          users={users}
          columns={oemColumns}
          userTypeOptions={oemUserTypeOptions}
          oemVendors={oemVendors}
          toolVendors={toolVendors}
          vendorFilter={vendorFilter}
          vendors={vendors?.vendorList || []}
          formik={formik}
          addUserFormik={oemAddUserFormik}
          editUserFormik={editUserFormik}
          userTypeFilter={userTypeFilter}
          userStateFilter={userStateFilter}
          resetFilters={resetFilters}
          filters={filters}
          onFilterClick={handleFilterDelete}
          addUserMutationIsLoading={oemAdminCreateUserMutation.isLoading}
          editUserMutationIsLoading={updateUserMutation.isLoading}
          currentDialog={currentDialog}
          toggleFilterDialog={toggleFilterDialog}
          toggleAddUserDialog={toggleAddUserDialog}
          toggleEditUserDialog={toggleEditUserDialog}
        />
      </>
    );
  }

  if (isTool) {
    return (
      <>
        <ManageUsers
          isSite={isSite}
          users={users}
          columns={toolColumns}
          userTypeOptions={toolUserTypeOptions}
          oemVendors={oemVendors}
          toolVendors={toolVendors}
          vendorFilter={vendorFilter}
          vendors={vendors?.vendorList || []}
          formik={formik}
          addUserFormik={toolAddUserFormik}
          editUserFormik={editUserFormik}
          userTypeFilter={userTypeFilter}
          userStateFilter={userStateFilter}
          resetFilters={resetFilters}
          filters={filters}
          onFilterClick={handleFilterDelete}
          addUserMutationIsLoading={toolAdminCreateUserMutation.isLoading}
          editUserMutationIsLoading={updateUserMutation.isLoading}
          currentDialog={currentDialog}
          toggleFilterDialog={toggleFilterDialog}
          toggleAddUserDialog={toggleAddUserDialog}
          toggleEditUserDialog={toggleEditUserDialog}
        />
      </>
    );
  }

  return (
    <>
      <ManageUsers
        isSite={isSite}
        users={users}
        columns={siteAdmincolumns}
        userTypeOptions={siteAdminUserTypeOptions as USER_TYPE[]}
        oemVendors={oemVendors}
        toolVendors={toolVendors}
        formik={formik}
        vendorFilter={vendorFilter}
        vendors={vendors?.vendorList || []}
        addUserFormik={siteAdminAddUserFormik}
        editUserFormik={editUserFormik}
        userTypeFilter={userTypeFilter}
        userStateFilter={userStateFilter}
        resetFilters={resetFilters}
        filters={filters}
        onFilterClick={handleFilterDelete}
        addUserMutationIsLoading={siteAdminCreateUserMutation.isLoading}
        editUserMutationIsLoading={updateUserMutation.isLoading}
        currentDialog={currentDialog}
        toggleFilterDialog={toggleFilterDialog}
        toggleAddUserDialog={toggleAddUserDialog}
        toggleEditUserDialog={toggleEditUserDialog}
      />
    </>
  );
};
