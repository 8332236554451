import { Dialog } from '@reach/dialog';
import React, { useState } from 'react';
import { FilterPills, FiltersList } from '../../components/FilterPills';
import { Header } from '../../components/Header';
import MyReactTable from '../../components/ReactTable';
import { TableColumnsType } from '../../components/ReactTable/ReactTable';
import { TextOnly, Text, LangDictKey } from '../../components/Text';
import {
  AGREEMENT_STATUS_LIST,
  FEE_STATUS,
  INSURANCE_STATUS_LIST,
  REGIONS,
  REGIONS_LIST,
  SUPPORT_STATUS_LIST,
} from '../../CONSTANTS';
import { UpdateAgreement, Vendor } from '../../types';
import { AGREEMENTS_DIALOGS, AgreementsTableView } from './AgreementsContainer';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { VendorViewAgreementSidebar } from './VendorViewAgreementSidebar';

interface ManagerAgreementsProps {
  agreements: AgreementsTableView[];
  columns: TableColumnsType[];
  formik: any;
  filters: FiltersList;
  feeStatusDropdown: FEE_STATUS[];
  tableFilters: { columnId: string; filter: string | undefined }[];
  onFilterClick: (field: string, value: string) => void;
  resetFilters: () => void;
  currentDialog: AGREEMENTS_DIALOGS | null;
  toggleFilterDialog: () => void;
  toggleEditAgreementDialog: () => void;
  oemVendors: Vendor[];
  toolVendors: Vendor[];
  selectedAgreement: UpdateAgreement | null;
  setSelectedAgreement: (agreement: UpdateAgreement) => void;
}

export const VendorAgreements = ({
  agreements,
  columns,
  formik,
  filters,
  feeStatusDropdown,
  tableFilters,
  resetFilters,
  onFilterClick,
  currentDialog,
  toggleFilterDialog,
  toggleEditAgreementDialog,
  oemVendors,
  toolVendors,
  selectedAgreement,
  setSelectedAgreement,
}: ManagerAgreementsProps) => {
  const [search, setSearch] = useState<string>('');

  const year = new Date().getFullYear();
  const yearsList = Array.from(new Array(year - 2019 + 2), (v, i) =>
    (2019 + i).toString()
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  return (
    <>
      <Header title={TextOnly('viewAgreements')} />
      <div className="l-container">
        <div className="l-flex-wrap">
          <div className="l-flex-wrap">
            <div className="u-margin-right">
              <a
                className="c-btn"
                href="https://info.autoauth.com/contact/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="c-btn__inner">
                  <Text tid="contactAA" />
                </div>
              </a>
            </div>
          </div>
          <div className="l-flex-between">
            <button
              className="c-btn-outline u-margin-right"
              onClick={toggleFilterDialog}
            >
              <div className="c-btn__inner">
                <Text tid="filterBy" />
                <i className="c-btn__icon fas fa-caret-down" />
              </div>
            </button>
            <div className="c-field u-margin-bottom-none">
              <label htmlFor="search" className="c-field__label u-is-vishidden">
                <Text tid="search" />
              </label>
              <input
                type="text"
                id="search"
                maxLength={50}
                className="c-input"
                placeholder={TextOnly('search')}
                value={search}
                onChange={handleChange}
              />
              <i className="c-field__input-icon fal fa-search" />
            </div>
          </div>
        </div>
        {Object.keys(filters).length ? (
          <div className="l-flex-wrap u-margin-top-large">
            <div className="l-flex-wrap">
              <button
                className="c-btn-link-text u-margin-right"
                onClick={resetFilters}
              >
                <div className="c-btn__inner">
                  <Text tid="resetFilters" />
                </div>
              </button>
              <FilterPills filters={filters} onFilterClick={onFilterClick} />
            </div>
          </div>
        ) : null}
        <div className="u-margin-top-xlarge">
          <MyReactTable
            columns={columns}
            data={agreements}
            globalFilter={search}
            filter={tableFilters}
            tableName="agreements"
          />
        </div>
      </div>
      <Dialog
        isOpen={currentDialog === AGREEMENTS_DIALOGS.FILTER}
        onDismiss={toggleFilterDialog}
        className="c-modal-slider"
        aria-label={TextOnly('filters')}
      >
        <button
          className="c-btn-icon c-modal-slider__close"
          onClick={toggleFilterDialog}
        >
          <div className="c-btn__inner">
            <i className="c-btn__icon fa fa-times" />
          </div>
        </button>
        <h1 className="c-modal__heading">
          <Text tid="filters" />
        </h1>

        <div className="c-modal__body">
          <form onSubmit={formik.handleSubmit}>
            <div className="l-container-sm">
              <div className="c-field">
                <label className="c-field__label">
                  <Text tid="selectOEMVendor" />
                </label>
                <Typeahead
                  id="oemFilter"
                  placeholder="Select OEM"
                  className="c-agreement-box__filter"
                  options={oemVendors.map((vendor) => vendor.vendorName)}
                  selected={formik.values.oemFilter}
                  onChange={(selected) => {
                    formik.setFieldValue('oemFilter', selected);
                  }}
                  multiple
                  positionFixed
                />
              </div>
              <div className="c-field">
                <label className="c-field__label">
                  <Text tid="selectToolVendor" />
                </label>
                <Typeahead
                  id="toolFilter"
                  placeholder="Select Tool"
                  className="c-agreement-box__filter"
                  options={toolVendors.map((vendor) => vendor.vendorName)}
                  selected={formik.values.toolFilter}
                  onChange={(selected) => {
                    formik.setFieldValue('toolFilter', selected);
                  }}
                  multiple
                  positionFixed
                />
              </div>
              <div className="c-field">
                <label className="c-field__label">
                  <Text tid="selectRegion" />
                </label>
                <Typeahead
                  id="regionFilter"
                  placeholder="Select Region"
                  className="c-agreement-box__filter"
                  options={REGIONS_LIST.map((region) => ({
                    label: TextOnly(region as LangDictKey),
                    value: region,
                  }))}
                  selected={
                    formik.values.regionFilter
                      ? formik.values.regionFilter.map((region: REGIONS) => ({
                          label: TextOnly(region),
                          value: region,
                        }))
                      : []
                  }
                  onChange={(selected) => {
                    if (selected.length > 0) {
                      formik.setFieldValue(
                        'regionFilter',
                        selected?.map((option) => option.value)
                      );
                    }
                  }}
                  multiple
                  positionFixed
                />
              </div>
              <div className="c-field">
                <label className="c-field__label">
                  <Text tid="selectYear" />
                </label>
                <Typeahead
                  id="yearFilter"
                  placeholder="Select Year"
                  className="c-agreement-box__filter"
                  options={yearsList}
                  selected={formik.values.yearFilter}
                  onChange={(selected) => {
                    formik.setFieldValue('yearFilter', selected);
                  }}
                  multiple
                  positionFixed
                />
              </div>
              <div className="c-field">
                <label className="c-field__label">
                  <Text tid="selectFeeFilter" />
                </label>
                <Typeahead
                  id="feeStatusFilter"
                  placeholder="Select Fee Status"
                  className="c-agreement-box__filter"
                  options={feeStatusDropdown.map((status) => ({
                    id: status,
                    label: TextOnly(status),
                  }))}
                  selected={formik.values.feeStatusFilter}
                  onChange={(selected) => {
                    formik.setFieldValue('feeStatusFilter', selected);
                  }}
                  multiple
                  positionFixed
                />
              </div>
              <div className="c-field">
                <label className="c-field__label">
                  <Text tid="selectInsuranceFilter" />
                </label>
                <Typeahead
                  id="insuranceStatusFilter"
                  placeholder="Select Insurance Status"
                  className="c-agreement-box__filter"
                  options={INSURANCE_STATUS_LIST.map((status) => ({
                    id: status,
                    label: TextOnly(status as LangDictKey),
                  }))}
                  selected={formik.values.insuranceStatusFilter}
                  onChange={(selected) => {
                    formik.setFieldValue('insuranceStatusFilter', selected);
                  }}
                  multiple
                  positionFixed
                />
              </div>
              <div className="c-field">
                <label className="c-field__label">
                  <Text tid="selectAgreementFilter" />
                </label>
                <Typeahead
                  id="agreementStatusFilter"
                  placeholder="Select Agreement Status"
                  className="c-agreement-box__filter"
                  options={AGREEMENT_STATUS_LIST.map((status) => ({
                    id: status,
                    label: TextOnly(status as LangDictKey),
                  }))}
                  selected={formik.values.agreementStatusFilter}
                  onChange={(selected) => {
                    formik.setFieldValue('agreementStatusFilter', selected);
                  }}
                  multiple
                  positionFixed
                />
              </div>
              <div className="c-field">
                <label className="c-field__label">
                  <Text tid="selectSupportFilter" />
                </label>
                <Typeahead
                  id="supportStatusFilter"
                  placeholder="Select Support Status"
                  className="c-agreement-box__filter"
                  options={SUPPORT_STATUS_LIST.map((status) => ({
                    id: status,
                    label: TextOnly(status as LangDictKey),
                  }))}
                  selected={formik.values.supportStatusFilter}
                  onChange={(selected) => {
                    formik.setFieldValue('supportStatusFilter', selected);
                  }}
                  multiple
                  positionFixed
                />
              </div>
              <div className="c-field l-flex-between">
                <button
                  className="c-btn-outline u-margin-right"
                  type="button"
                  onClick={resetFilters}
                >
                  <Text tid="resetFilters" />
                </button>
                <button className="c-btn" type="submit">
                  <Text tid="applyFilters" />
                </button>
              </div>
            </div>
          </form>
        </div>
      </Dialog>
      {currentDialog === AGREEMENTS_DIALOGS.EDIT_AGREEMENT ? (
        <VendorViewAgreementSidebar
          toggleDialog={toggleEditAgreementDialog}
          oemVendors={oemVendors}
          toolVendors={toolVendors}
          selectedAgreement={selectedAgreement as UpdateAgreement}
          setSelectedAgreement={setSelectedAgreement}
        />
      ) : null}
    </>
  );
};
